import styles from '../../annotation_contracts/invoice.module.css';

export function TextHighlight({ text, searchString }) {
	// Function to split text into parts based on search string
	text = String(text)
	const splitText = (text, searchString) => {
		const parts = text.split(new RegExp(`(${searchString})`, 'gi'));
		return parts;
	};

	// Render text with only the first match highlighted
	const renderText = (textParts) => {
		let isFirstMatch = true;
		return textParts.map((part, index) => {
			if (part.toLowerCase() === searchString.toLowerCase() && isFirstMatch) {
				isFirstMatch = false;
				return <strong key={index}>{part}</strong>;
			} else {
				return part;
			}
		});
	};
	if (typeof text == 'string'){

		const textParts = splitText(text, searchString);
	
		return <div title={text} className={`${styles.oneLineText}  text-sm outline-none`}>{renderText(textParts)}</div>;
	}
	else{
		return <div></div>;
	}
	// Call splitText to get parts
}
