import React, { useState, useEffect } from 'react';

import { useClientsList_Uploadpage } from './hooks';
//import { toast } from 'react-toastify'
import { Autocomplete, TextField } from '@mui/material';

function FltrClientName({ CategoryId,setClientName, ClientName, setClientId, ClientId,VendorNameId,AnalystId }) {
	
	const [options, setoptions] = useState([]);
	const client_list_hook = useClientsList_Uploadpage();
	const [Focus, setFocus] = useState(false);
	const [inputValue, setInputValue] = useState('');
	const [open, setOpen] = useState(false);
	const [firstTime, setfirstTime] = useState(true);

	useEffect(() => {
		client_list_hook.mutate({CategoryId,VendorNameId,AnalystId});
	}, [CategoryId,VendorNameId,AnalystId]);
	
	useEffect(() => {
		if (client_list_hook.data) {
			setfirstTime(false);

			//setoptions(client_list_hook.hook.data.data);
			setoptions(client_list_hook.data.data.map((itm) => itm.client_name));
		}
	}, [client_list_hook.data]);

	function changeCategory(val) {
		//console.log(val);
		
		let obj = client_list_hook.data.data.find((o) => o.client_name === val);
		//console.log(obj);
		if (obj) {
			setClientId(obj.client_id);
			setClientName(obj.client_name);
			//console.log(CategoryId);
		}
		else {
			setClientId(null);
			setClientName(null);
		}

	}

	//let stop = false;

	return (
		<>
			<div className="grow missDD">
				
				<div className="lightT">
					{client_list_hook.isPending ? (
						<p className="px-4 py-2 border border-1 border-slate-300 rounded-md">
							<span className="text-sm text-slate-400 ps-4 inline-block">
								Loading...
							</span>
						</p>
					) : (
						<Autocomplete
							isOptionEqualToValue={(option, value) =>
								option === value || value === '' || value == null
							}
							disablePortal
							id="combo-box-demo"
							className='expCat'
							options={options}
							value={(options.includes(ClientName)?ClientName: null) || null }
							renderInput={(params) => (
								<TextField {...params} label="Client Name" />
							)}
							onChange={(event, value) => changeCategory(value)}
							onFocus={() => setFocus(true)}
							onBlur={() => setFocus(false)}
						/>
					)}
				</div>
			</div>
		</>
	);
}

export default FltrClientName;
