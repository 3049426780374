import React from 'react';
/* import Highcharts from 'highcharts'
import HighchartsReact from 'highcharts-react-official' */
import { Doughnut } from 'react-chartjs-2';
import { useNavigate } from "react-router-dom";

import { Chart as ChartJS, ArcElement, Tooltip, Legend } from 'chart.js';
//import { Pie } from 'react-chartjs-2';
ChartJS.register(ArcElement, Tooltip, Legend);
function UnmappedPricedDonuts({UnmappedPriceData,dataFromChild3}) {
  let navigate = useNavigate(); 
    //console.log(dataFromChild);
    
    const percentage_completed = ((UnmappedPriceData?.total_false_count/UnmappedPriceData?.total_count)*100).toFixed(2);
    const percentage_pending = (100-percentage_completed).toFixed(2);
    const data = {
        labels: ['% Unmapped','% Mapped' ],
        datasets: [
          {
            data: [(percentage_completed),(percentage_pending)],
            backgroundColor: [
              'rgb(237, 126, 38)',
              'rgb(217, 217, 217)',
              

            ],
            borderColor: [
              'rgb(237, 126, 38)',
              'rgb(217, 217, 217)',

            ],
            borderWidth: 1,
          },
        ],
      };

    const options = {
      cutout: '65%',
      responsive: true,
      maintainAspectRatio: false,
      plugins: {
          legend: {
              display: false
          },
          
      },
      onClick: (e, activeEls) => {
        let dataIndex = activeEls[0]?.index;
        let label = e.chart.data.labels[dataIndex];
        navigateMe(label)
      },
      onHover: (event, chartElement) => {
        let dataIndex = chartElement[0]?.index;
        if(event.chart.data.labels[dataIndex] === '% Unmapped'){
          event.native.target.title = 'Click to view'
          event.native.target.style.cursor = 'pointer'
        }
        else{
          event.native.target.title = ''
          event.native.target.style.cursor = 'default'
        }
        
      }
    };

    function navigateMe(label){
      //console.log(label);
      if(label === '% Unmapped'){
        navigate('/unmapped-price-item-listing?analyst='+dataFromChild3.analyst+'&batch='+dataFromChild3.batch+'&category='+dataFromChild3.category+'&client='+dataFromChild3.client+'&datefrom='+dataFromChild3.datefrom+'&dateto='+dataFromChild3.dateto+'&field='+dataFromChild3.field+'&invoice='+dataFromChild3.invoice+'&invoice_status='+dataFromChild3.invoice_status+'&vendor='+dataFromChild3.vendor+'');
      }
      
    }

    let plugin = [];

    if(percentage_completed >= 0){
        plugin = [{
          afterDraw: function(chart) {
            //chart.destroy();
            var width = chart.width,
            height = chart.height,
            ctx = chart.ctx;
            ctx.restore();
            var fontSize = (height / 300).toFixed(2);
            ctx.font = fontSize + "em sans-serif";
            ctx.textBaseline = "top";
            var text = '',
            textX = Math.round((width - ctx.measureText(text).width) / 2),
            textY = height / 2;
            ctx.fillStyle = 'rgb(26, 156, 244)';
            ctx.fillText(text, textX, textY);
            ctx.save();
          } 
      }]
    }

	return (
		<>
			<h1 className="text-lg font-black text-primary">Unmapped Price Items</h1>
			<p className="text-xs text-gray-500 mb-2">Total pricing fields not mapped to a contract price</p>
			<div className="pt-4">
      {UnmappedPriceData?.total_count > 0 ? (
        <Doughnut width={300} height={300} options={options} data={data} plugins={plugin} redraw={true}/>
      ) : (
        <div  className='min-h-[150px]'>
            No Data Found
        </div> 
      )}
        
			</div>
		</>
	);
}

export default UnmappedPricedDonuts;