import { configureStore } from '@reduxjs/toolkit';
import storage from 'redux-persist/lib/storage';
import { combineReducers } from 'redux';
import { persistReducer } from 'redux-persist';
import thunk from 'redux-thunk';

import authReducer from './components/auth/authSlice';
import navigateSlice from './components/navigateSlice';
import privilageSlice from './components/auth/privilegesSlice';
import dashSlice  from './components/dashboard/dashboard-slice';

// @ authInfo is an object
const reducers = combineReducers({
	auth: authReducer,
	navigateSlice: navigateSlice,
	privilageSlice: privilageSlice,
	dashSlice: dashSlice
});

const persistConfig = {
	key: 'root',
	storage,
};

const persistedReducer = persistReducer(persistConfig, reducers);

const store = configureStore({
	reducer: persistedReducer,
	middleware: [thunk],
});

export default store;
