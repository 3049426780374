import { Link, Outlet, useSearchParams } from 'react-router-dom';
import React, { useState, useCallback } from 'react';
import {  useInvoiceslistPagewise } from './hooks';
import Pagination from '@mui/material/Pagination';
import Stack from '@mui/material/Stack';
import { RECORDS_PER_PAGE } from '../../config';
import LoaderFull from '../shared/loader-full-screen';
import { useDebouncedState } from '@mantine/hooks';
import { decodeName, encodeName, handleSearchKeys, handleSortUtil ,  } from '../utils';
import { useEffect, useRef } from 'react';
import { DateTime } from "luxon"
const SORT_KEY_CONST = 'invoice_list_sort_key'
const SORT_ORDER_CONST = 'invoice_list_sort_order'
const PAGE_NO_CONST = 'invoice_list_page_no'
const CLIENT = 'client'
const VENDOR = 'vendor'
const ANALYST = 'analyst'
const BATCH = 'batch'
const FIELD = 'field'
const DATEFROM = 'datefrom'
const DATETO = 'dateto'
const INVOICE = 'invoice'
const INVOICE_STATUS = 'invoice_status'
const CATEGORY = 'category'

function ListDataFieldIndex() {
	const [searchParams, setSearchParams] = useSearchParams();

	//const delete_inv_hook = useDeleteInvoice()

	const [selectedInvoices, setselectedInvoices] = useState(new Set([]))

	const [DebounceSearch, setDebounceSearch] = useDebouncedState('', 500);
	const [uatHeader, setuatHeader] = useState(false)
	
	//const isFirstRender = useRef(true)

	//console.log(searchParams);
	
	const [Sort, setSort] = useState(handleSearchKeys(searchParams,SORT_KEY_CONST,SORT_ORDER_CONST,PAGE_NO_CONST,CLIENT, VENDOR ,CATEGORY, ANALYST,BATCH,FIELD,DATEFROM,DATETO,INVOICE,INVOICE_STATUS ));	
	
	const {hook:invoices_list_hook ,setArgs} = useInvoiceslistPagewise(Sort)
	//const catname_hook = useCategoryNameById(searchParams.get(CATEGORY_ID))

	function handleSort(typer) {
		let { sort_key, sort_order } = handleSortUtil(typer, Sort);
		const newSearchParams = new URLSearchParams(searchParams); // Create a new instance
		newSearchParams.set(SORT_KEY_CONST, sort_key);
		newSearchParams.set(SORT_ORDER_CONST, sort_order);
		setSearchParams(newSearchParams);

	}

	const isFirstRender = useRef(true)

	useEffect(() => {
		if (!isFirstRender.current) {
			let newSort = handleSearchKeys(searchParams, SORT_KEY_CONST, SORT_ORDER_CONST, PAGE_NO_CONST);
	
			// Only update if the new sort is different
			if (JSON.stringify(newSort) !== JSON.stringify(Sort)) {
				setSort(newSort);
				setArgs((prev) => ({ ...prev, ...newSort }));
			}
		}
		isFirstRender.current = false;
	}, [searchParams]);

	const renderList = useCallback(() => {
		//console.log(invoices_list_hook);
		
		if (invoices_list_hook.isFetching ) {
			return <LoaderFull />;
		} 
		else if (!invoices_list_hook.data) {
			return <p>Loading invoices...</p>;
		}else if (invoices_list_hook.data?.data?.length === 0) {
			return <p className="text-center">No data found</p>;
		} else {
			//console.log("Invoices list data:", invoices_list_hook.data);

			return (
				<Outlet
					context={{
						invoiceList: invoices_list_hook.data,
						Search: DebounceSearch,
						setuatHeader: setuatHeader
					}}
				/>
			);
		}
	}, [invoices_list_hook.isFetching,invoices_list_hook.data, DebounceSearch]);
	
	
	return (
		<>
			<div className="grow h-screen overflow-x-hidden overflow-y-auto fontInter">
				<>
					<div className="lg:flex gap-4 justify-between items-center vLBG py-4 px-6">
						<div className="flex-none">
							<h1 className="text-lg font-normal text-primary">
								List of <strong className="font-black">Invoices</strong>
							</h1>
							<p className="text-xs text-gray-500 mb-2">
								List of all Files missing the data field -
							</p>
						</div>
						
					</div>
				</>

				<>
					<div className="p-6">
						<div className="listDHeight larger overflow-auto">
							<div className="flex gap-2 mb-2 items-center pr-4">
								
								<div className="flex-none w-[55px]">
									<div className="flex-none text-center relative w-[55px]">
									<span title='default sort' onClick={() => handleSort('')} className={`${Sort.type === '' ? 'text-primary' : 'cursor-pointer'}   material-icons w-4 text-sm mt-1`}>											swap_vert
										</span>
									</div>
								</div>
								<div className="grow px-2 py-1 flex items-center justify-between text-xs">
									<div className="grow pr-4">
										<label
											onClick={() => handleSort('fname')}
											className={`${Sort.type === 'fname' ? 'text-primary' : ''} block font-black relative w-[120px] cursor-pointer`}
										>
											Name of Invoice
											<span className="material-icons absolute w-4 top-0 right-0 text-sm ml-1 mt-[-2px]">
												swap_vert
											</span>
										</label>
									</div>
									<div className="flex-none px-4 text-center w-[150px]">
										<label
											className={`font-black block relative w-[160px] cursor-default`}
										>
											Uploaded By
										</label>
									</div>
									<div className="flex-none px-4 text-center w-[140px]">
										<label
											className={`font-black block relative w-[160px] cursor-default`}
										>
											Status
										</label>
									</div>
									<div className="flex-none px-4 text-center w-[150px]">
										<label
											onClick={() => handleSort('uploaded_at')}
											className={`${Sort.type === 'uploaded_at' ? 'text-primary' : ''} font-black block relative w-[150px] cursor-pointer`}
										>
											Date Uploaded {DateTime.now().toFormat("ZZZZ")}
											<span className="material-icons absolute w-4 top-0 right-2 text-sm ml-1 mt-[-2px]">
												swap_vert
											</span>
										</label>
									</div>
									<div className="flex-none px-4 text-center w-[190px]">
										<label
											onClick={() => handleSort('updated_at')}
											className={`${Sort.type === 'updated_at' ? 'text-primary' : ''} font-black block relative w-[170px] cursor-pointer`}
										>
											Last Updated Date {DateTime.now().toFormat("ZZZZ")}
											<span className="material-icons absolute w-4 top-0 right-2 text-sm ml-1 mt-[-2px]">
												swap_vert
											</span>
										</label>
									</div>
									<div className="flex-none px-4 text-center w-[190px]">
										<label
											onClick={() => handleSort('published_on')}
											className={`${Sort.type === 'published_on' ? 'text-primary' : ''} font-black block relative w-[170px] cursor-pointer`}
										>
											Invoice Generated Date {DateTime.now().toFormat("ZZZZ")}
											<span className="material-icons absolute w-4 top-0 right-0 text-sm ml-1 mt-[-2px]">
												swap_vert
											</span>
										</label>
									</div>
									
									<div className="flex-none px-4 text-center w-[150px]">
									<label
											onClick={() => handleSort('percentage_complete')}
											className={`${Sort.type === 'percentage_complete' ? 'text-primary' : ''} font-black block relative w-[138px] cursor-pointer`}
										>
											% Processing Complete
											<span className="material-icons absolute w-4 top-0 right-0 text-sm ml-1 mt-[5px]">
												swap_vert
											</span>
										</label>
									</div>
									


								</div>
								<div className="flex-none w-[106px] pr-2">&nbsp;</div>
							</div>
							{/* Table Body Row - Loop */}

							{renderList()}
						</div>
						<div className="flex justify-center gap-3 pt-2">
							{!invoices_list_hook.isFetching && (
								<Stack spacing={2}>
									<Pagination
										page={Sort.page}
										onChange={(e, value) => {
											searchParams.set(PAGE_NO_CONST, value)
											setSearchParams(searchParams)
										}}
										count={Math.ceil(
											invoices_list_hook.data?.total_invoices /
												RECORDS_PER_PAGE
										)}
										color="primary"
										variant="outlined"
										shape="rounded"
									/>
								</Stack>
							)}
						</div>
					</div>
				</>
			</div>
		</>
	);
}

export default ListDataFieldIndex;
