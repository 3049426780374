import { redux_logout } from "./auth/authSlice";
import { buildAxiosInstance } from "./axiosInstance";
//import { useMsal } from '@azure/msal-react';

export const decodeName = function(name) {
    try {
        return decodeURIComponent(name);
    } catch (error) {
        return name;
    }
}

export const encodeName = function(name) {
    try {
        return encodeURIComponent(name);
    } catch (error) {
        return name;
    }
}


export const LogoutCheck = function(error, dispatch,instance) {
    
    if (error.response) {
    
}
}

export const handleSearchKeys = function(searchParams,  sort_key , sort_order , page_no,...args) { 

    let obj = {
		type: searchParams.get(sort_key) ,
		order: (()=>{
			if (searchParams.get(sort_order) ==='desc'){
				return 'arrow_downward_alt'
			}
			else if (searchParams.get(sort_order) ==='asc') {
				return 'arrow_upward_alt'
			}
			else {
				return ''
			}
		})(),
		sort: (() => {
			if (['',null,'null'].includes(searchParams.get(sort_key))) {
				return ''
			}
			else {
				return [searchParams.get(sort_key), searchParams.get(sort_order)].join(' ').trim()
			}
		})(),
		page: parseInt(searchParams.get(page_no)) ? parseInt(searchParams.get(page_no)) : 1,

	}
    for (let key of args) { 
        obj[key] = decodeName(searchParams.get(key))
    }
    return obj
}

export const handleSortUtil = function(typer, Sort ) {
    let order = ''
    if (typer !== '') {
        if (Sort.type === typer) {
            if (Sort.order === 'arrow_upward_alt') {
                order = 'arrow_downward_alt'
            } else {
                order = 'arrow_upward_alt'
            }
        }
        else {
            order = 'arrow_downward_alt'
        }
    }

    let urlOrd = ''
    if (order === 'arrow_downward_alt') {
        urlOrd = 'desc'
    }
    else if (order === 'arrow_upward_alt') {
        urlOrd = 'asc'
    }
    else {
        urlOrd = ''
    }
    return {sort_key : typer ,sort_order:urlOrd }

}

export const handleLock = async function(dispatch, doc_key,instance) {
    
	if (sessionStorage.getItem(doc_key)) {
		try {
            const TOAST_ID = 'release_lock'
			const axiosInstance = buildAxiosInstance(TOAST_ID, true)
			let body = { "document_id": sessionStorage.getItem(doc_key) }
			const responseData = await axiosInstance.post('/release_lock', body)
            sessionStorage.removeItem(doc_key)
			return responseData
		}
		catch (error) {
			LogoutCheck(error, dispatch,instance)
		}
	}
}