import React, { useState, useEffect, useRef, useMemo } from 'react';
import FilePlaceholder from './file-placeholder';
import RightSideInvAcc from './rightInvAccordion';
import { useSearchParams, useNavigate } from 'react-router-dom';
import { toast } from 'react-toastify';
import LoaderFull from '../shared/loader-full-screen';
import { useGetInvDesc, useUploadAsDraftContractDesc, useUploadContractDesc, useExtractTable } from './hooks';
import {useOnlineStatus, useUpdateLockWindow} from '../annotation/hooks'
import { FormatInsideView, getCurrentDateTime, prepareFlatList , BoxToCoordinate } from './utilFuntions';
import SplitPane from 'split-pane-react';
import 'split-pane-react/esm/themes/default.css';
import { decodeName, encodeName } from '../utils';
import swal from 'sweetalert';
import { useDispatch } from 'react-redux';
import { put_lock } from '../auth/authSlice';
import { useCategoryNameById } from '../list_invoices/hooks';
function ContractIndex() {
	//const urlParms = useParams()
	const [searchParams] = useSearchParams();
	const [OriginalSnap, setOriginalSnap] = useState();
	const [PageNo, setPageNo] = useState(0);
	const [type, settype] = useState('extracted_entities');
	const get_boxes_hook = useGetInvDesc(searchParams.get('contract_id'));
	const upload_inv_desc = useUploadContractDesc();
	const contract_save_as_draft = useUploadAsDraftContractDesc()
	const [imgUrls, setimgUrls] = useState(null);
	const [imgData, setimgData] = useState(null);
	const [fieldsExtracted, setfieldsExtracted] = useState(null);
	const [totalFieldsExtracted, settotalFieldsExtracted] = useState({});
	const [totalTablesExtracted, settotalTablesExtracted] = useState({});
	const [tablesExtracted, settablesExtracted] = useState(null);
	const [errorPages, seterrorPages] = useState([]);
	const [errorSelected, seterrorSelected] = useState(null);
	let navigate = useNavigate()
	// const [VersionStatus, setVersionStatus] = useState(0)
	let versionTrack = useRef(null)
	const isOnline = useOnlineStatus()
	const [changeDetect, setchangeDetect] = useState(false)
	const [DocLockedForMe, setDocLockedForMe] = useState(null)
	const [Freez, setFreez] = useState(false)
	const update_lock_window = useUpdateLockWindow()
	const [changeAdd, setchangeAdd] = useState(0)
	const prevchangeVal = useRef(0)
	const diffChange = useRef(false)
	const intractVal = useRef(0)
	const [tableChanged , settableChanged] = useState({})
	const [tableBoxes , settableBoxes] = useState({})
	const [ExtractUrl , setExtractUrl] = useState(null)
	const extract_table_hook = useExtractTable()
	const [ tableTitle , settableTitle] =  useState(null)
	let dispatch = useDispatch();
	const [movePage , setmovePage] = useState(null)
	const [ExtractionError, setExtractionError] = useState({})
	const catname_hook = useCategoryNameById(searchParams.get('category_id'))
	const [IsCSV, setIsCSV] = useState(false)
	const [fileName, setfileName] = useState('...')

	useEffect(() => {
		if (get_boxes_hook.hook.isSuccess && get_boxes_hook.hook.data && !get_boxes_hook.hook.isFetching) {
			setOriginalSnap(get_boxes_hook.hook.data[0]);
			setfileName(get_boxes_hook.hook.data[0].file_name)
			let {
				fieldsExtracted_,
				tablesExtracted_,
				imgUrls_,
				imgData_,
				errorPages_,
				parsable,
				locked_for_me_,
				freez,
				tableBoxes_,
				tableTitles_,
				is_csv
			} = FormatInsideView(get_boxes_hook.hook.data[0]);
			if (!parsable) {
				toast.error("Data is not JSON parsable")
				return
			}
			settotalFieldsExtracted(fieldsExtracted_);
			if (0 in fieldsExtracted_) {

				setfieldsExtracted(fieldsExtracted_[0]);
			}
			else {
				setfieldsExtracted([]);
			}
			settotalTablesExtracted(tablesExtracted_);
			if (0 in tablesExtracted_) {
				settablesExtracted(tablesExtracted_[0]);
			}
			else {
				settablesExtracted([]);
			}


			setimgUrls(imgUrls_);
			setimgData(imgData_);
			seterrorPages(errorPages_);
			if (versionTrack.current == null) {
				versionTrack.current = get_boxes_hook.hook.data[0]?.version + 1
			}
			setDocLockedForMe(locked_for_me_)
			setFreez(freez)
			settableBoxes(tableBoxes_)
			settableTitle(tableTitles_)
			if (locked_for_me_){
				dispatch(put_lock())
			}
			if (is_csv) {
				setSizes(['0%', '100%'])
			}
			setIsCSV(is_csv)

			// setVersionStatus(get_boxes_hook.hook.data[0]?.version+1)
		}
	}, [get_boxes_hook.hook.isSuccess, get_boxes_hook.hook.data, get_boxes_hook.hook.isFetching, dispatch]);


	function clearVariablesAndRefetch() {
		setOriginalSnap('')
		setPageNo(0)
		settype('extracted_entities')
		setimgUrls(null)
		setimgData(null)
		setfieldsExtracted(null)
		settotalFieldsExtracted({})
		settotalTablesExtracted({})
		settablesExtracted(null)
		seterrorPages([])
		seterrorSelected(null)
		sessionStorage.setItem("contract_change", false)
		showAlert.current = 0
		setchangeDetect(false)
		navigate(`/my-contracts?client_id=${searchParams.get('client_id')}&client_name=${encodeName(decodeName(searchParams.get('client_name')))}&client_list_page_no=${searchParams.get('client_list_page_no')}&client_list_sort_key=${searchParams.get('client_list_sort_key')}&client_list_sort_order=${searchParams.get('client_list_sort_order')}&vendor_id=${encodeName(decodeName(searchParams.get('vendor_id')))}&vendor_name=${encodeName(decodeName(searchParams.get('vendor_name')))}&category_id=${encodeName(decodeName(searchParams.get('category_id')))}&category_name=${encodeName(decodeName(searchParams.get('category_name')))}&vendor_list_page_no=${searchParams.get('vendor_list_page_no')}&vendor_list_sort_key=${searchParams.get('vendor_list_sort_key')}&vendor_list_sort_order=${searchParams.get('vendor_list_sort_order')}&contract_list_page_no=${searchParams.get('contract_list_page_no')}&contract_list_sort_key=${searchParams.get('contract_list_sort_key')}&contract_list_sort_order=${searchParams.get('contract_list_sort_order')}`);
		

	}


	function UploadInvDesc() {
		if (!isOnline) {
			toast.error("You are offline, Please connect to internet")
			return
		}

		setchangeAdd(p => p + 1)

		let totalFlds = { ...totalFieldsExtracted }
		let tpFlds = [...fieldsExtracted]
		totalFlds[PageNo] = tpFlds

		let totalTbls = { ...totalTablesExtracted }
		let tpTbls = { ...tablesExtracted }
		totalTbls[PageNo] = tpTbls
		let cpPages = Object.keys(OriginalSnap.pages).reduce((acc, key) => {
			acc[key] = {
				image_path: OriginalSnap.pages[key].image_path,
				name: OriginalSnap.pages[key].name,
				image_height: OriginalSnap.pages[key].image_height,
				image_width: OriginalSnap.pages[key].image_width

			}
			return acc;
		}, {});

		let body_nest = {
			_id: OriginalSnap._id,
			files_document_id: OriginalSnap.files_document_id,
			version: versionTrack.current,
			document_annotated: false,
			created_at: OriginalSnap.created_at,
			updated: getCurrentDateTime(),
			pages: cpPages
		}
		let totalPages = 0
		let tableStatus = false 
		if (Object.values(tableChanged).includes(true)) {
			tableStatus = true
		}

		for (let page in imgUrls){
			let {extracted_entities , tables , status, start_date_r,end_date_r,  promisee_r, tenure_r, coords, titles} = prepareFlatList(tpFlds , totalTbls[page] , tableBoxes[page] , tableTitle[page] )
			if (status ===1) {
				if (start_date_r){
					body_nest.start_date = start_date_r
				}
				if (end_date_r) { 
					body_nest.end_date = end_date_r
				}
				if (promisee_r) { 
					body_nest.promisee = promisee_r
				}
				if (tenure_r) { 
					body_nest.tenure = tenure_r
				}

				body_nest.pages[page].extracted_entities = extracted_entities
				body_nest.pages[page].tables = tables
				body_nest.pages[page].page = parseInt(page)
				if (tableChanged[page]) { 
					body_nest.pages[page].table_changed = tableChanged[page]
				}
				else{
					body_nest.pages[page].table_changed = false
				}
				body_nest.table_changed = tableStatus
				body_nest.pages[page].coordinates = coords 
				body_nest.pages[page].table_titles = titles 



			}
			else{
				toast.error(`Page ${parseInt(page)+1}: ${status}`)
				return 
			}
			totalPages+=1
		}
		body_nest.ingestion_status = true

		
		let body = {
			document_id: OriginalSnap.files_document_id,
			pages: body_nest
		}



		// settotalFieldsExtracted((prev) => {
		// 	let fe = JSON.parse(JSON.stringify(fieldsExtracted))
		// 	let pgbx = { ...prev };
		// 	pgbx[PageNo] = fe;
		// 	setfieldsExtracted(pgbx[PageNo]);
		// 	return pgbx;
		// });

		settotalTablesExtracted((prev) => {
			let te = JSON.parse(JSON.stringify(tablesExtracted))
			let pgbx = { ...prev };
			pgbx[PageNo] = te;
			return pgbx;
		});

		// settablesExtracted(totalTablesExtracted[newP]);



		let chunkSize = 50

		upload_inv_desc.mutate({ body: body, chunkSize: chunkSize, totalPages: totalPages })








		//let body = ReadyToUpload(OriginalSnap, TotalBx, fieldsExtracted, tablesExtracted)
		// upload_inv_desc.mutate(body)



	}
	useEffect(() => {
		if (upload_inv_desc.isSuccess) {
			toast.success("Successfully updated");
			sessionStorage.setItem('contract_disableSaveBtn', false)
			// navigate(`/my-annotations?vendor_id=${searchParams.get('vendor_id')}&client_id=${searchParams.get('client_id')}&contract_id=${searchParams.get('contract_id')}&client_name=${encodeName(decodeName(searchParams.get('client_name')))}&vendor_name=${encodeName(decodeName(searchParams.get('vendor_name')))}&contract_name=${encodeName(decodeName(searchParams.get('contract_name')))}&category_name=${encodeName(decodeName(searchParams.get('category_name')))}`);
			clearVariablesAndRefetch()
		}
		else if (upload_inv_desc.isError) {
			let split = upload_inv_desc.error?.message?.split('|')
			if (split[0] === '409') {
				toast.error(`${split[1]}`)
				sessionStorage.setItem('contract_disableSaveBtn', true)
			}
			else {
				toast.error(`${split[1]}`)
			}

		}
	}, [upload_inv_desc.isSuccess, upload_inv_desc.isError, upload_inv_desc.error?.message]);



	function clearVariablesAfterDraftSave() {
		sessionStorage.setItem("contract_change" , false)
		showAlert.current = 0
		setchangeDetect(false)
		
		
	}



	function SaveAsDraft() {
		if (!isOnline) {
			toast.error("You are offline, Please connect to internet")
			return
		}

		setchangeAdd(p => p + 1)

		let totalFlds = { ...totalFieldsExtracted }
		let tpFlds = [...fieldsExtracted]
		totalFlds[PageNo] = tpFlds

		let totalTbls = { ...totalTablesExtracted }
		let tpTbls = { ...tablesExtracted }
		totalTbls[PageNo] = tpTbls
		let cpPages = Object.keys(OriginalSnap.pages).reduce((acc, key) => {
			acc[key] = {
				image_path: OriginalSnap.pages[key].image_path,
				name: OriginalSnap.pages[key].name,
				image_height: OriginalSnap.pages[key].image_height,
				image_width: OriginalSnap.pages[key].image_width

			}
			return acc;
		}, {});

		let body_nest = {
			_id: OriginalSnap._id,
			files_document_id: OriginalSnap.files_document_id,
			version: versionTrack.current,
			document_annotated: false,
			created_at: OriginalSnap.created_at,
			updated: getCurrentDateTime(),
			pages: cpPages
		}
		let totalPages = 0
		let tableStatus = false 
		if (Object.values(tableChanged).includes(true)) {
			tableStatus = true
		}

		for (let page in imgUrls){
			let {extracted_entities , tables , status,  start_date_r,end_date_r, promisee_r, tenure_r, coords, titles} = prepareFlatList(tpFlds , totalTbls[page], tableBoxes[page] , tableTitle[page] )
			if (status ===1) {
				if (start_date_r){
					body_nest.start_date = start_date_r
				}
				if (end_date_r) { 
					body_nest.end_date = end_date_r
				}
				if (promisee_r) { 
					body_nest.promisee = promisee_r
				}
				if (tenure_r) { 
					body_nest.tenure = tenure_r
				}
				body_nest.pages[page].extracted_entities = extracted_entities
				body_nest.pages[page].tables = tables
				body_nest.pages[page].page = parseInt(page)
				if (tableChanged[page]) { 
					body_nest.pages[page].table_changed = tableChanged[page]
				}
				else{
					body_nest.pages[page].table_changed = false
				}
				body_nest.table_changed = tableStatus
				body_nest.pages[page].coordinates = coords
				body_nest.pages[page].table_titles = titles 


			}
			else{
				toast.error(`Page ${parseInt(page)+1}: ${status}`)
				return 
			}
			totalPages+=1
		}

		

		let body = {
			document_id: OriginalSnap.files_document_id,
			pages: body_nest
		}



		// settotalFieldsExtracted((prev) => {
		// 	let fe = JSON.parse(JSON.stringify(fieldsExtracted))
		// 	let pgbx = { ...prev };
		// 	pgbx[PageNo] = fe;
		// 	setfieldsExtracted(pgbx[PageNo]);
		// 	return pgbx;
		// });

		settotalTablesExtracted((prev) => {
			let te = JSON.parse(JSON.stringify(tablesExtracted))
			let pgbx = { ...prev };
			pgbx[PageNo] = te;
			return pgbx;
		});

		// settablesExtracted(totalTablesExtracted[newP]);



		let chunkSize = 50

		contract_save_as_draft.mutate({ body: body, chunkSize: chunkSize, totalPages: totalPages })








		//let body = ReadyToUpload(OriginalSnap, TotalBx, fieldsExtracted, tablesExtracted)
		// upload_inv_desc.mutate(body)



	}
	useEffect(() => {
		if (contract_save_as_draft.isSuccess) {
			toast.success("Successfully Saved as Draft");
			sessionStorage.setItem('contract_disableSaveBtn', false)
			// navigate(`/my-annotations?vendor_id=${searchParams.get('vendor_id')}&client_id=${searchParams.get('client_id')}&contract_id=${searchParams.get('contract_id')}&client_name=${encodeName(decodeName(searchParams.get('client_name')))}&vendor_name=${encodeName(decodeName(searchParams.get('vendor_name')))}&contract_name=${encodeName(decodeName(searchParams.get('contract_name')))}&category_name=${encodeName(decodeName(searchParams.get('category_name')))}`);
			clearVariablesAfterDraftSave()
		}
		else if (contract_save_as_draft.isError) {
			let split = contract_save_as_draft.error?.message?.split('|')
			if (split[0] === '409') {
				toast.error(`${split[1]}`)
				sessionStorage.setItem('contract_disableSaveBtn', true)
			}
			else {
				toast.error(`${split[1]}`)
			}

		}
	}, [contract_save_as_draft.isSuccess, contract_save_as_draft.isError, contract_save_as_draft.error?.message]);





	const [sizes, setSizes] = useState(['70%', '30%']);

	const layoutCSS = {
		height: '100%',
		display: 'block',
	};

	function navToAlert(toLocation) {
		if (showAlert.current % 2 === 1) {
			swal("You have unsaved changes that will be lost if you leave this page.", {
				buttons: ['Cancel', 'Continue'],
			}).then((value) => {
				if (value) {

					navigate(toLocation)
					// Add your logout logic here
				} else {

				}
			})
		} else {
			navigate(toLocation)
		}

	}

	// const [, render] = useReducer((p) => !p, false);

	// Step 2: Define a function to handle user interaction


	const eventAttached = useRef(0)
	const showAlert = useRef(0)
	useEffect(() => {
		const handlebackBtn = () => {
			if (showAlert.current % 2 === 1) {

				swal("You have unsaved changes that will be lost if you leave this page.", {
					buttons: ['Cancel', 'Continue'],
				}).then((value) => {
					if (value) {

						showAlert.current = 0
						window.history.back()
						// Add your logout logic here
					} else {
						window.history.pushState(null, null, window.location.href)
					}
				})
			}
			else {
				// window.history.go(-1)
			}

		}

		const handleBeforeUnload = (e) => {

			if (showAlert.current % 2 === 1) {
				// sessionStorage.removeItem("history")
				e.preventDefault()
				return (e.returnValue = '')

			}

		}

		if (eventAttached.current === 0) {
			window.addEventListener('popstate', handlebackBtn);
			window.addEventListener('beforeunload', handleBeforeUnload);
			eventAttached.current += 1
		}


		return () => {
			get_boxes_hook.controller.abort();
			eventAttached.current -= 1
			window.removeEventListener('popstate', handlebackBtn);
			window.removeEventListener('beforeunload', handleBeforeUnload);



			// sessionStorage.removeItem("initalState")

			// window.removeEventListener('popstate', handleBeforeUnload);
		};
	}, [])


	useEffect(() => {
		if (sessionStorage.getItem("contract_history") === null) {
			sessionStorage.setItem("contract_history", 1)
			sessionStorage.setItem("contract_change", false)
		}
		else if (sessionStorage.getItem("contract_change") === 'true') {
			window.history.back()
		}
		sessionStorage.setItem('contract_disableSaveBtn', false)

	}, [])

	useEffect(() => {
		if (changeDetect) {
			if (showAlert.current % 2 === 0) {
				showAlert.current = 1
				sessionStorage.setItem("contract_change", true)
				window.history.pushState(null, null, window.location.href)
			}
		}
	}, [changeDetect])




	function showSessionOutpopup() {
		const timerDuration = 10000; // 10 seconds
		let timeLeft = timerDuration / 1000; // Convert to seconds
		const autoSelectNo = setTimeout(() => {
			swal.close();
			navigate(`/my-contracts?client_id=${searchParams.get('client_id')}&client_name=${encodeName(decodeName(searchParams.get('client_name')))}&client_list_page_no=${searchParams.get('client_list_page_no')}&client_list_sort_key=${searchParams.get('client_list_sort_key')}&client_list_sort_order=${searchParams.get('client_list_sort_order')}&vendor_id=${encodeName(decodeName(searchParams.get('vendor_id')))}&vendor_name=${encodeName(decodeName(searchParams.get('vendor_name')))}&category_id=${encodeName(decodeName(searchParams.get('category_id')))}&category_name=${encodeName(decodeName(searchParams.get('category_name')))}&vendor_list_page_no=${searchParams.get('vendor_list_page_no')}&vendor_list_sort_key=${searchParams.get('vendor_list_sort_key')}&vendor_list_sort_order=${searchParams.get('vendor_list_sort_order')}&contract_list_page_no=${searchParams.get('contract_list_page_no')}&contract_list_sort_key=${searchParams.get('contract_list_sort_key')}&contract_list_sort_order=${searchParams.get('contract_list_sort_order')}`);
		}, timerDuration);
		const timerDiv = document.createElement('div');
		timerDiv.innerText = `Time left: ${timeLeft} seconds`;

		const timerInterval = setInterval(() => {
			timeLeft -= 1;
			timerDiv.innerText = `Time left: ${timeLeft} seconds`;
			if (timeLeft <= 0) {
				clearInterval(timerInterval);
			}
		}, 1000);



		swal('Are you still working', {
			content: timerDiv,
			buttons: ['No', 'Yes'],
		}).then((value) => {
			clearTimeout(autoSelectNo)

			if (value) {
				diffChange.current = true;
				intractVal.current = 0;
			} else {
				navigate(`/my-contracts?client_id=${searchParams.get('client_id')}&client_name=${encodeName(decodeName(searchParams.get('client_name')))}&client_list_page_no=${searchParams.get('client_list_page_no')}&client_list_sort_key=${searchParams.get('client_list_sort_key')}&client_list_sort_order=${searchParams.get('client_list_sort_order')}&vendor_id=${encodeName(decodeName(searchParams.get('vendor_id')))}&vendor_name=${encodeName(decodeName(searchParams.get('vendor_name')))}&category_id=${encodeName(decodeName(searchParams.get('category_id')))}&category_name=${encodeName(decodeName(searchParams.get('category_name')))}&vendor_list_page_no=${searchParams.get('vendor_list_page_no')}&vendor_list_sort_key=${searchParams.get('vendor_list_sort_key')}&vendor_list_sort_order=${searchParams.get('vendor_list_sort_order')}&contract_list_page_no=${searchParams.get('contract_list_page_no')}&contract_list_sort_key=${searchParams.get('contract_list_sort_key')}&contract_list_sort_order=${searchParams.get('contract_list_sort_order')}`);
			}
		});
	}

	async function doesIntract() {
		if (diffChange.current) {
			let res = await update_lock_window.mutateAsync({ document_id: searchParams.get('contract_id') })
			if (res?.message === "updated") {
				diffChange.current = false
				intractVal.current = 0
				return true
			}
			else {
				return false
			}
		}
		else {
			intractVal.current += 1
			return false
		}
	}

	useEffect(() => {
		let intervalId
		if (DocLockedForMe) {
			clearInterval(intervalId)


			intervalId = setInterval(() => {
				doesIntract()
				if (intractVal.current === 10) {
					showSessionOutpopup()
				}

			}, 60000);


		}
		else {

		}

		return () => {
			if (DocLockedForMe) {
				clearInterval(intervalId)
			}
		}
	}, [DocLockedForMe])




	useEffect(() => {
		if (prevchangeVal.current !== changeAdd) {
			prevchangeVal.current = changeAdd
			diffChange.current = true
		}
		else {
			diffChange.current = false
			intractVal.current = 0
		}
	}, [changeAdd])

	useEffect(() => {
		setchangeAdd(p => p + 1)

	}, [PageNo])


	const Lables = useMemo(() => {
		if (tablesExtracted!==null && tableBoxes[PageNo]!==null){
			

			let entList = new Set()
			Object.keys(tablesExtracted)?.map(table_name=> {
				entList.add(`table_${table_name}`)
			return null
			})
			let entitiesHavingBox = tableBoxes[PageNo]?.map(itm => itm?.entity?.toLowerCase())
			let filteredFeilds = Array.from(entList)?.filter(itm => ! entitiesHavingBox.includes(itm))
			return filteredFeilds
		}
		else{
			return []
		}
	  
	}, [tableBoxes,tablesExtracted,PageNo,])


	async function ExtractTable(tableInd, page){
		setExtractionError(p=> {
			let tp = {...p}
			delete tp[`${page}_${tableInd}`]
			return tp
		})
		let et  = tableBoxes[page].filter(itm => itm?.entity?.toLowerCase() === `table_${tableInd}`)
		let cord = null
		if (et.length>0){
			cord = BoxToCoordinate(et[0])
		}
		if (ExtractUrl && cord) { 
			let res = await extract_table_hook.mutateAsync(
				{data:{
					image_url:ExtractUrl,
					coordinates:cord
				}})
			if (res && 'table' in res) { 
				settotalTablesExtracted((prev) => {
					let pgbx = { ...prev };
					pgbx[page][tableInd] = res['table'];
					// settablesExtracted(pgbx[page]);
					return pgbx;
				});

			}
			if (res && 'title' in res){ 

				settableTitle(p => {
					let tp = {...p}
					tp[page][tableInd] = res['title']
					return tp
				})
			}
			if (! res) { 
				setExtractionError(p=> {
					let tp = {...p}
					tp[`${page}_${tableInd}`] = "Extraction Failed, please try again"
					return tp
				})
			}
			

			
		}
		
	}

	useEffect(() => {
		if (totalFieldsExtracted && PageNo in totalTablesExtracted){
			settablesExtracted(totalTablesExtracted[PageNo])
		}
	}, [totalTablesExtracted, PageNo])
	
	














	return (
		<>
			<div className="grow h-screen overflow-x-hidden overflow-y-auto fontInter">
				{/* Right Side - White workspaces */}
				{upload_inv_desc.isPending ? <LoaderFull /> :
					<div className="lg:flex lg:h-full">
					<div className='lg:flex-col lg:h-full lg:w-full'>
						{fieldsExtracted == null ? (
							<p>...</p>
							) :
								<div className="px-6 pt-6 pb-3">
										<div className="flex">
											<div className="grow">
												<div className='flex justify-between '>

													<div className="grow text-xs text-gray-900 text-center mb-2">
														{/* {![0, undefined].includes(sessionStorage.getItem('disableSaveBtnTitle')?.length) && <p><b>STATUS-</b> {sessionStorage.getItem('disableSaveBtnTitle')}</p>} */}
													</div>
													<div className="grow text-xs text-gray-900 text-center mb-2">
														{![0, undefined].includes(sessionStorage.getItem('disableSaveBtnTitle')?.length) && <p><b>STATUS-</b> {sessionStorage.getItem('disableSaveBtnTitle')}</p> }										
													</div>
													<div className="text-xs text-gray-900 text-center mb-2">
														{DocLockedForMe === false && <span title='The contract is currently edited by another user.' className="bg-orange-600 inline-flex px-4 py-2 rounded-md text-white  text-sm items-center font-black">
															<span className="material-icons text-sm mr-2">lock</span>Locked</span>}
													</div>

												</div>


												<div>
													<h1 className="text-lg font-normal text-primary">
														Description of{' '}
														<strong className="font-black">Contracts</strong>
													</h1>
													<p className="text-xs text-gray-500 mt-2 h-9">
														<button onClick={() => navToAlert(`/my-clients?client_list_page_no=${searchParams.get('client_list_page_no')}&client_list_sort_key=${searchParams.get('client_list_sort_key')}&client_list_sort_order=${searchParams.get('client_list_sort_order')}`)} >My Clients</button> &nbsp;{' '}
														{'>'} &nbsp;
														{/* Clients - 'TechSolutions Inc.' &nbsp; {'>'} &nbsp;  */}
														<button
															onClick={() => navToAlert(`/my-vendors?client_id=${searchParams.get('client_id')}&client_name=${encodeName(decodeName((searchParams.get('client_name'))))}&client_list_page_no=${searchParams.get('client_list_page_no')}&client_list_sort_key=${searchParams.get('client_list_sort_key')}&client_list_sort_order=${searchParams.get('client_list_sort_order')}&vendor_list_page_no=${searchParams.get('vendor_list_page_no')}&vendor_list_sort_key=${searchParams.get('vendor_list_sort_key')}&vendor_list_sort_order=${searchParams.get('vendor_list_sort_order')}`)}
														>
															Client -{' '}
															{decodeName(
																searchParams.get('client_name')
															)}
														</button>{' '}
														&nbsp; {'>'} &nbsp;
														<button
															onClick={() => navToAlert(`/my-contracts?client_id=${searchParams.get('client_id')}&client_name=${encodeName(decodeName((searchParams.get('client_name'))))}&client_list_page_no=${searchParams.get('client_list_page_no')}&client_list_sort_key=${searchParams.get('client_list_sort_key')}&client_list_sort_order=${searchParams.get('client_list_sort_order')}&vendor_id=${encodeName(decodeName(searchParams.get('vendor_id')))}&vendor_name=${encodeName(decodeName(searchParams.get('vendor_name')))}&category_id=${encodeName(decodeName(searchParams.get('category_id')))}&category_name=${encodeName(decodeName(searchParams.get('category_name')))}&vendor_list_page_no=${searchParams.get('vendor_list_page_no')}&vendor_list_sort_key=${searchParams.get('vendor_list_sort_key')}&vendor_list_sort_order=${searchParams.get('vendor_list_sort_order')}&contract_list_page_no=${searchParams.get('contract_list_page_no')}&contract_list_sort_key=${searchParams.get('contract_list_sort_key')}&contract_list_sort_order=${searchParams.get('contract_list_sort_order')} `)}

														// to={`/my-contracts?vendor_id=${searchParams.get('vendor_id')}&client_id=${searchParams.get('client_id')}&client_name=${encodeName(decodeName(searchParams.get('client_name')))}&vendor_name=${encodeName(decodeName(searchParams.get('vendor_name')))}&category_name=${encodeName(decodeName(searchParams.get('category_name')))}`}
														>
															Vendor -{' '}
															{decodeName(
																searchParams.get('vendor_name')
															)} ( { 
																 catname_hook.data ? catname_hook.data?.name: '..' 

																} )
														</button>{' '}
														&nbsp; {'>'} &nbsp; Contract -{' '}
														{fileName}
													</p>
												</div>
											</div>
										</div>
									</div> }
						<SplitPane sizes={sizes} onChange={(sizes) => setSizes(sizes)} className='annotationsContWithTopBox'>
							<div style={{ ...layoutCSS, background: '#fafafa' }}>
								<div className="flex flex-col h-screen">
									

									{
										(function gp1() {
											if (get_boxes_hook.hook.isPending || get_boxes_hook.hook.isFetching) {
												return <LoaderFull />
											}
											else if (Object.keys(totalFieldsExtracted).length !== 0 &&
												Object.keys(totalTablesExtracted).length !== 0 && tableBoxes[PageNo] && !IsCSV) {
												return <FilePlaceholder
													fieldsExtracted={fieldsExtracted}
													setfieldsExtracted={setfieldsExtracted}
													tablesExtracted={tablesExtracted}
													settablesExtracted={settablesExtracted}

													totalFieldsExtracted={totalFieldsExtracted}
													settotalFieldsExtracted={settotalFieldsExtracted}
													totalTablesExtracted={totalTablesExtracted}
													settotalTablesExtracted={settotalTablesExtracted}
													setPageNo={setPageNo}
													PageNo={PageNo}
													type={type}
													imgUrls={imgUrls}
													imgData={imgData}
													errorSelected={errorSelected}

													setchangeDetect={setchangeDetect}
													setchangeAdd={setchangeAdd}
													DocLockedForMe={DocLockedForMe}
													tableBoxes={tableBoxes}
													settableBoxes={settableBoxes}
													Lables={Lables}
													Freez={Freez}
													setExtractUrl={setExtractUrl}
													movePage={movePage}
													setmovePage={setmovePage}
												/>
											}
											else {
												return null
											}

										})()
									}
								</div>
							</div>
							<div
								style={{
									...layoutCSS,
									background: '#ffffff',
									borderLeft: 'solid 2px #c2c2c2',
								}}
							>
								<div className="flex flex-col w-full h-full">
									{
										(function gp2() {
											if (get_boxes_hook.hook.isPending || get_boxes_hook.hook.isFetching) {
												return <LoaderFull />
											}
											else if (Object.keys(totalFieldsExtracted).length !== 0 &&
												Object.keys(totalTablesExtracted).length !== 0) {
												return <RightSideInvAcc
													fieldsExtracted={fieldsExtracted}
													setfieldsExtracted={setfieldsExtracted}
													tablesExtracted={tablesExtracted}
													settablesExtracted={settablesExtracted}
													totalFieldsExtracted={totalFieldsExtracted}
													settotalFieldsExtracted={settotalFieldsExtracted}
													totalTablesExtracted={totalTablesExtracted}
													settotalTablesExtracted={settotalTablesExtracted}
													setPageNo={setPageNo}
													PageNo={PageNo}
													type={type}
													settype={settype}
													UploadInvDesc={UploadInvDesc}
													upload_inv_desc={upload_inv_desc}
													SaveAsDraft={SaveAsDraft}
													contract_save_as_draft={contract_save_as_draft}
													errorPages={errorPages}
													seterrorSelected={seterrorSelected}
													errorSelected={errorSelected}
													settableChanged={settableChanged}
													setchangeDetect={setchangeDetect}
													DocLockedForMe={DocLockedForMe}
													Freez={Freez}
													setchangeAdd={setchangeAdd}
													parentContractId={OriginalSnap.parent_contract_file_id}
													tableBoxes={tableBoxes}
													settableBoxes={settableBoxes}
													Lables={Lables}
													ExtractTable={ExtractTable}
													extract_table_hook={extract_table_hook}
													settableTitle={settableTitle}
													contractName={decodeName(searchParams.get('contract_name'))}
													setmovePage={setmovePage}
													ExtractionError={ExtractionError}
													setExtractionError={setExtractionError}
													IsCSV={IsCSV}
												/>
											}
											else {
												return null
											}


										})()
									}

								</div>
							</div>
						</SplitPane>
					</div>
					</div>}
			</div>
		</>
	);
}
export default ContractIndex;
