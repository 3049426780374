import { useMutation, useQuery } from '@tanstack/react-query';
import axios from 'axios';
import { BACKEND_URL } from '../../config';
import { useDispatch } from 'react-redux';
import { useDebouncedState } from '@mantine/hooks';
import { toast } from 'react-toastify';
import { RECORDS_PER_PAGE, BLOB_CONTAINER_NAME, BLOB_URL } from '../../config';
import { saveAs } from 'file-saver';
import { buildAxiosInstance, getAccessToken } from '../axiosInstance';
import { LogoutCheck } from '../utils';
import { useMsal } from '@azure/msal-react';
import { BlobServiceClient } from '@azure/storage-blob';
var ObjectID = require('bson-objectid');

const fetchMismatchList = async (Args,signal, dispatch,instance) => {
	try {
		const TOAST_ID = 'mismatch_list'
		const axiosInstance = buildAxiosInstance(TOAST_ID)
		let config = {
			params: {...Args, limit: RECORDS_PER_PAGE},
			signal: signal,
		};
		const responseData = await axiosInstance.get('/mismatch_list',config)
		return responseData
	} catch (error) {
		LogoutCheck(error, dispatch,instance)	
		throw error
		
	};
};

export const useMismatchListPagewise = () => {
	let dispatch = useDispatch();
	const { instance } = useMsal();
	const controller = new AbortController();
    const signal = controller.signal;

	const hook = useMutation({
		mutationKey: ['mismatch_list'],
		mutationFn: async (body) => fetchMismatchList(body,signal, dispatch,instance),
		retry: false,
	});
	return {
		hook: hook,
		controller: controller
	};
};

export const GetVendors = (clientId) => {
	let dispatch = useDispatch();
	const { instance } = useMsal();
	return useQuery({
		queryKey: ['vendors_by_client', clientId],
		retry: false,
		queryFn: async ({signal}) => {
			try {
				const TOAST_ID = 'vendors_by_client'
				const axiosInstance = buildAxiosInstance(TOAST_ID)
				let config = {
					params: {client_id: clientId},
					signal: signal
				};
				const responseData = await axiosInstance.get('/vendors_by_client',config)
				return responseData
			} catch (error) {
				LogoutCheck(error, dispatch,instance)
			};
		},
	});
};

const fetchClientList = async (Args, dispatch, signal,instance) => {
	try {
		const TOAST_ID = 'client_list'
		const axiosInstance = buildAxiosInstance(TOAST_ID)
		let config = {
			params: {search: Args.search},
			signal: signal
		};
		const responseData = await axiosInstance.get('/client_list',config)
		return responseData
	} catch (error) {
		LogoutCheck(error, dispatch,instance)	
		
	};
};

export const useClientsListMismatch = () => {
	let dispatch = useDispatch();
	const { instance } = useMsal();
	const [Args, setArgs] = useDebouncedState(
		{
			search: '',
		},
		1000
	);
	const hook = useQuery({
		queryKey: ['clientlist_mismatch', Args],
		queryFn: async ({ signal }) => fetchClientList(Args, dispatch, signal,instance),
		retry: false,
	});
	return {
		hook: hook,
		Args: Args,
		setArgs: setArgs,
	};
};


/** API to fetch batches */
export const useGetBatches = () => {
	let dispatch = useDispatch();
	const { instance } = useMsal();
	return useQuery({
		queryKey: ['get_batches'],
		retry: false,
		queryFn: async ({signal}) => {
			try {
				const TOAST_ID = 'batch'
				const axiosInstance = buildAxiosInstance(TOAST_ID)
				let config = {
					
					signal: signal
				};
				const responseData = await axiosInstance.get('/batch',config)
				return responseData
			} catch (error) {
				LogoutCheck(error, dispatch,instance)	
				
			};
		},
	});
};


function formatDateToCustomString() {
	// Create a new Date object from the input date string
	const date = new Date();
  
	const year = date.getFullYear();
	const month = String(date.getMonth() + 1).padStart(2, '0'); // Months are 0-based
	const day = String(date.getDate()).padStart(2, '0');
	const hours = String(date.getHours()).padStart(2, '0'); // 24-hour format
	const minutes = String(date.getMinutes()).padStart(2, '0');
	const seconds = String(date.getSeconds()).padStart(2, '0');
  
	// Format the date as YYYYMMDDHHMMSS
	const formattedDate = `${year}${month}${day}${hours}${minutes}${seconds}`;
  
	return formattedDate;
}


export const useExportMismatch = () => {
	let dispatch = useDispatch();
	const { instance } = useMsal();
	return useMutation({
		mutationKey: 'exp_csv',
		retry: false,
		mutationFn: async (body) => {
			try {
				let reqToken = await getAccessToken()
				const config = {
					method: 'post',
					url: BACKEND_URL + '/export_mismatches',
					maxBodyLength: Infinity,
					responseType: 'blob', // Important for handling binary data
					headers: {
					  'Content-Type': 'application/json',
					  Authorization: 'Bearer ' + reqToken,
					},
					data: JSON.stringify(body)
				  };
				let response = await axios(config)
				if (response.status === 200){
					const blob = new Blob([response.data], { type: 'text/csv' });
        
					const sanitizedClientName = body.client_name
					const timestamp = formatDateToCustomString();
					const filename = `${sanitizedClientName}_${timestamp}.csv`;
			
					// Use FileSaver.js to save the file
					saveAs(blob, filename);
					
					return "completed"
				}

				
			} catch (error) {
				if (error.response) {
					if (error.response.status ===400) { 
						const errorMessage = "No data found"
						toast.error(errorMessage, {toastId: 'export_mismatches' });
					}					
				}
				LogoutCheck(error, dispatch,instance)	
			}
		},
	});
};

export const useExportFinalMismatch = () => {

	const dispatch = useDispatch();
	const { instance } = useMsal();

	return useMutation({
		mutationFn: async (body) => {
			try {
				const TOAST_ID = "save_snapshot"
				const axiosInstance = buildAxiosInstance(TOAST_ID)
				const responseData = await axiosInstance.post('/save_snapshot', JSON.stringify(body))
				let message = responseData?.message
				message && toast.success(message , {toastId: TOAST_ID})
				return responseData
			}
			catch (error) {
				LogoutCheck(error, dispatch,instance)
				
			};
		},
	});
}

export const useGetReportlist = () => {
	let dispatch = useDispatch();
	const { instance } = useMsal();
	return useQuery({
		queryKey: ['get_reports'],
		retry: false,
		queryFn: async ({signal}) => {
			try {
				const TOAST_ID = 'get_report_mismatches'
				const axiosInstance = buildAxiosInstance(TOAST_ID)
				let config = {
					signal: signal
				};
				const responseData = await axiosInstance.get('/report_mismatches',config)
				return responseData
			} catch (error) {
				LogoutCheck(error, dispatch,instance)	
				
			};
		},
	});
};

export const useGetFinalReportlist = () => {
	let dispatch = useDispatch();
	const { instance } = useMsal();
	return useQuery({
		queryKey: ['get_final_reports'],
		retry: false,
		queryFn: async ({signal}) => {
			try {
				const TOAST_ID = 'get_final_report_mismatches'
				const axiosInstance = buildAxiosInstance(TOAST_ID)
				let config = {
					signal: signal
				};
				const responseData = await axiosInstance.get('/final_report_mismatches',config)
				return responseData
			} catch (error) {
				LogoutCheck(error, dispatch,instance)	
				
			};
		},
	});
};

export const useSaveMismatchReport = () => {
	const dispatch = useDispatch();
	const { instance } = useMsal();

	return useMutation({
		mutationFn: async (body) => {
			try {
				const TOAST_ID = "post_report_mismatches"
				const axiosInstance = buildAxiosInstance(TOAST_ID)
				const responseData = await axiosInstance.post('/report_mismatches', JSON.stringify(body))
				let message = responseData?.message
				message && toast.success(message , {toastId: TOAST_ID})
				return responseData
			}
			catch (error) {
				LogoutCheck(error, dispatch,instance)
				
			};
		},
	});
};

export const useDiscardFiles = () => {
	const dispatch = useDispatch();
	const { instance } = useMsal();
	return useMutation({
		retry: false,
		mutationFn: async (body) => {
			try {
				const TOAST_ID = "bulk_discard_mismatch_status"
				const axiosInstance = buildAxiosInstance(TOAST_ID)
				const responseData = await axiosInstance.post('/bulk_discard_mismatch_status', JSON.stringify(body))
				let message = responseData?.message
				message && toast.success(message , {toastId: TOAST_ID})
				return responseData
			}
			catch (error) {
				LogoutCheck(error, dispatch,instance)
				
			};
		},
	});
};

export const useReprocessFiles = () => {
	const dispatch = useDispatch();
	const { instance } = useMsal();
	return useMutation({
		retry: false,
		mutationFn: async (body) => {
			try {
				const TOAST_ID = "bulk_mismatch_reprocess"
				const axiosInstance = buildAxiosInstance(TOAST_ID)
				const responseData = await axiosInstance.post('/bulk_mismatch_reprocess', JSON.stringify(body))
				let message = responseData?.message
				message && toast.success(message , {toastId: TOAST_ID})
				return responseData
			}
			catch (error) {
				LogoutCheck(error, dispatch,instance)
				
			};
		},
	});
};