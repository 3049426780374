import React, { useEffect, useState } from 'react';
import styles from '../shared/common.module.css';

import { useFormik } from 'formik';

import { useGetProfile } from './hooks';
import {TimeZonePopup} from "../shared/timezone"
import { useSelector } from 'react-redux';

function ProfileIndex() {
	const formik = useFormik({
		initialValues: {
			name: localStorage.getItem('name'),
			email: localStorage.getItem('username'),
			role: '',
			expense_catagory: '',
		},
	});
	const Data = useSelector(p=> p.auth)

	const get_profile_hook = useGetProfile();

	useEffect(() => {
		if (get_profile_hook.isSuccess) {
			formik.setFieldValue('role', get_profile_hook.data?.role);
			formik.setFieldValue('name', get_profile_hook.data?.name);
			formik.setFieldValue('email', get_profile_hook.data?.email);
			formik.setFieldValue('expense_catagory',get_profile_hook.data?.expense_catagory);
		}
	}, [get_profile_hook.isSuccess]);
	const [tz, settz] = useState(false)

	return (
		<>
			<div className="grow h-screen overflow-x-hidden overflow-y-auto fontInter">
				<div className="mb-5 px-6 pt-8">
					<div className={`${styles.profBox} p-5 text-center`}>
						<div className="mb-3">
							<span className="proIco m-auto">{formik.values.name?.[0]}</span>
						</div>
						<div className="mb-4">
							<h1 className="text-lg font-black text-primary">My Profile</h1>
							{/* <p className='text-xs text-gray-500'>Given below are the personal details -</p> */}
						</div>
					</div>
				</div>

				<div className="max-w-[500px] mx-auto">
					<div className="mb-3">
						<label className="block text-md text-black-600 mb-1">Name</label>
						{get_profile_hook.isPending ? (
							<p className="px-4 py-2 border border-1 border-slate-300 rounded-md">
								<span className="text-sm text-slate-400 ps-4 inline-block">
									Loading...
								</span>
							</p>
						) : (
							<input
								type="text"
								className="border relative border-slate-300 rounded-md w-full h-10 py-2 px-3 focus:outline-blue-400 text-sm"
								// placeholder={Profile?.name}
								value={formik.values.name}
								onChange={formik.handleChange}
								// readOnly
								name="name"
								id="name"
								disabled={true}
							/>
						)}
					</div>

					<div className="mb-3">
						<label className="block text-md text-black-600 mb-1">
							Email address
						</label>
						{get_profile_hook.isPending ? (
							<p className="px-4 py-2 border border-1 border-slate-300 rounded-md">
								<span className="text-sm text-slate-400 ps-4 inline-block">
									Loading...
								</span>
							</p>
						) : (
							<input
								type="email"
								className="border relative border-slate-300 rounded-md w-full h-10 py-2 px-3 focus:outline-blue-400 text-sm"
								// placeholder={Profile?.email}
								value={formik.values.email}
								onChange={formik.handleChange}
								// readOnly
								name="email"
								id="email"
								disabled={true}
							/>
						)}
					</div>

					<div className="mb-3">
						<label className="block text-md text-black-600 mb-1">Role</label>
						{get_profile_hook.isPending ? (
							<p className="px-4 py-2 border border-1 border-slate-300 rounded-md">
								<span className="text-sm text-slate-400 ps-4 inline-block">
									Loading...
								</span>
							</p>
						) : (
							<input
								type="text"
								className="border relative border-slate-300 rounded-md w-full h-10 py-2 px-3 focus:outline-blue-400 text-sm"
								// placeholder={Profile?.name}
								value={formik.values.role}
								onChange={formik.handleChange}
								// readOnly
								name="role"
								id="role"
								disabled={true}
							/>
						)}
					</div>

					<div className="mb-3">
						<label className="block text-md text-black-600 mb-1">
							Expense Category
						</label>
						{get_profile_hook.isPending ? (
							<p className="px-4 py-2 border border-1 border-slate-300 rounded-md">
								<span className="text-sm text-slate-400 ps-4 inline-block">
									Loading...
								</span>
							</p>
						) : (
							<input
								type="text"
								className="border relative border-slate-300 rounded-md w-full h-10 py-2 px-3 focus:outline-blue-400 text-sm"
								// placeholder={Profile?.name}
								value={formik.values.expense_catagory}
								onChange={formik.handleChange}
								// readOnly
								name="expense_catagory"
								id="expense_catagory"
								disabled={true}
							/>
						)}
					</div>
					{/* <div className='mb-3'>
							<input
								type="text"
								className="border relative border-slate-300 rounded-md w-full h-10 py-2 px-3 focus:outline-blue-400 text-sm"
								// placeholder={Profile?.name}
								value={Data?.timezone}
								// readOnly
								name="timezone"
								id="tz"
								disabled={true}
							/>
						<button className="bg-blue-800 hover:bg-indigo-700 px-3 py-2 rounded-md text-white disabled:bg-gray-200 disabled:text-gray-500 disabled:hover:text-gray-500 text-sm flex items-center font-medium whitespace-nowrap overflow-hidden text-ellipsis mt-2" onClick={()=> settz(true)}>Change Timezone</button>
						
					</div> */}
					{/* {tz && <TimeZonePopup settz={settz}/> } */}
				</div>
			</div>
		</>
	);
}

export default ProfileIndex;
