//import React from 'react';
import React, {useEffect} from 'react';
import {
	useOutletContext,
} from 'react-router-dom';
import Checkbox from '@mui/material/Checkbox';
import { useGetSasToken } from './hooks';
import TimeZoneConverter from '../shared/timezone';

export const InvoicesListingDataByStatus = () => {
	const get_sas_token = useGetSasToken()
	const { invoiceList, selectedInvoices , setselectedInvoices, setuatHeader } = useOutletContext();
	async function handleNavigate(obj,ind) {
		let body = { 
			path:obj.path
		}
		get_sas_token.mutate(body)
			
	}

	useEffect(() => {
		if (get_sas_token.isSuccess) { 
			const data = get_sas_token.data;
			window.open(data.path+'?'+ data.sas_token, '_blank');

		}
	  }, [get_sas_token.isSuccess])

	function handleCheckBoxRowwise(checked, doc_id){
		if (checked) { 
			let tp = new Set(selectedInvoices)
			tp.add(doc_id)
			setselectedInvoices(tp)
		}
		else { 
			let tp = new Set(selectedInvoices)
			tp.delete(doc_id)
			setselectedInvoices(tp)
		}
	}

	return (
		<> 
			{invoiceList?.data?.map((itm, ind) => {
				return (
					<div key={`vendor` + ind} className="flex gap-2 mb-2 tblLoop">
						<div className="flex-none w-[40px] flex items-center justify-center">
							<Checkbox size="small" onChange={(e)=> handleCheckBoxRowwise(e.target.checked, itm._id)} checked={selectedInvoices.has(itm._id)} />
						</div>
						<div className="flex-none w-[50px]">
							<label className="vLBG flex items-center px-3 h-[53px] rounded-md text-center text-white relative text-sm">
								<img className="h-8" src="/svg/ri_building-fill.svg" alt="" />
							</label>
						</div>
						<div className="grow border rounded-md px-5 py-3 text-sm flex items-center justify-between">
							<div className="min-w-[120px] relative flex grow border-r pr-4 items-center">
								<span
									title={itm.fname}
									className="absolute w-full block text-ellipsis whitespace-nowrap overflow-hidden"
								>
									{itm.fname}
								</span>
							</div>
							<div className="flex-none border-r px-0 text-center w-[200px] relative flex items-center">
								<span
									title={itm?.type}
									className="absolute w-full block text-ellipsis whitespace-nowrap overflow-hidden"
								>
									{itm?.type}
								</span>
							</div>
							<div className="flex-none border-r px-0 text-center w-[200px] relative flex items-center">
								<span
									title={itm.uploaded_by}
									className="absolute w-full block text-ellipsis whitespace-nowrap overflow-hidden"
								>
									{itm?.uploaded_by}
								</span>
							</div>
							<div className="flex-none border-r px-4 text-center w-[200px]">
								{/* {formatDate(itm.uploaded_at)} */}
								<TimeZoneConverter setuatHeader={setuatHeader} utcTimestamp={itm.uploaded_at} />
							</div>
							
							<div className="flex-none border-r px-4 text-center w-[160px]">
								{(itm?.error_category)?itm?.error_category:'-'}
							</div>
						</div>
						<div className="flex-none flex w-[150px] pr-2">
							<button
								onClick={() => handleNavigate(itm,ind)}
								type="button"
								className="w-[140px] vLBG block px-4 py-3 text-center text-primary text-sm rounded-md font-black mx-1 disabled:bg-gray-200 disabled:text-gray-500"
							>
								{(itm?.status?itm?.status:'Download')}
							</button>
							
						</div>
					</div>
				);
			})}
		</>
	);
};

function formatDate(inputDateString) {
	if (inputDateString === undefined || inputDateString === null ) return '-'
	// Create a Date object from the input date string
	var date = new Date(inputDateString);

	// Extract day, month, and year
	var day = date.getDate();
	var month = date.getMonth() + 1; // Note: getMonth() returns 0-indexed month, so we add 1
	var year = date.getFullYear();

	// Format day and month to have leading zeros if necessary
	day = day < 10 ? '0' + day : day;
	month = month < 10 ? '0' + month : month;

	// Construct the formatted date string
	var formattedDateString = month + '/' + day + '/' + year;

	// Return the formatted date string
	return formattedDateString;
}
