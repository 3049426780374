import { createSlice } from '@reduxjs/toolkit';

const initialState = {
    analyst : null,
    analystName : null,
    category : null,
    categoryName : null,
    client : null,
    clientName : null,
    vendor : null,
    vendorName : null,
    invoice : null,
    invoiceName : null,
    fieldNameId : null,
    batch : null,
    batchName : null,
    invoice_status : null,
    datefrom : null,
    dateto : null,

};

export const dashSlice = createSlice({
    name: 'dashboard',
    initialState,
    reducers: {
        save_filter: (state, action) => {
            state.analyst = action.payload.analyst;
            state.analystName = action.payload.analystName;
            state.category = action.payload.category;
            state.categoryName = action.payload.categoryName;
            state.client = action.payload.client;
            state.clientName = action.payload.clientName;
            state.vendor = action.payload.vendor;
            state.vendorName = action.payload.vendorName;
            state.invoice = action.payload.invoice;
            state.invoiceName = action.payload.invoiceName;
            state.fieldNameId = action.payload.fieldNameId;
            state.batch = action.payload.batch;
            state.batchName = action.payload.batchName;
            state.invoice_status = action.payload.invoice_status;
            state.datefrom = action.payload.datefrom;
            state.dateto = action.payload.dateto;

        },
        reset_filter: (state, action)=> {
            state.analyst = null;
            state.analystName = null;
            state.category = null;
            state.categoryName = null;
            state.client = null;
            state.clientName = null;
            state.vendor = null;
            state.vendorName = null;
            state.invoice = null;
            state.invoiceName = null;
            state.fieldNameId = null;
            state.batch = null;
            state.batchName = null;
            state.invoice_status = null;
            state.datefrom = null;
            state.dateto = null;
            
        }
        
        
        
    },
});

// Action creators are generated for each case reducer function
export const { save_filter , reset_filter } =
    dashSlice.actions;

export default dashSlice.reducer;
