import React, { useCallback, useEffect, useMemo, useRef, useState } from 'react';
import Checkbox from '@mui/material/Checkbox';
import styles from '../shared/common.module.css';

import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import Select from '@mui/material/Select';

const label = { inputProps: { 'aria-label': 'Checkbox demo' } };



const FieldsNTabularFields = ({GlobalRules,  selectedSet, settryTodelete, tryTodelete, setselectedSet, setRules, Rules }) => {

    const [AddField, setAddField] = useState(false)
    const [AddFieldT, setAddFieldT] = useState(false)
    const [newEnt, setnewEnt] = useState({
        entity_name: "",
        entity_rule: "",
        entity_type: "single",
        checked : false,
        error: ""
    })

    const [newEntTable, setnewEntTable] = useState({
        entity_name: "",
        entity_rule: "",
        entity_type: "multivalued",
        checked : false,
    })

    const t1ref = useRef(null)
    const t2ref = useRef(null)
    const secRef = useRef(null)
    const [load, setload] = useState(false)




    function saveField(type) {
        setload(true);
        const entity = type === 'single' ? { ...newEnt } : { ...newEntTable };

        if (entity.entity_name === "") {
            setload(false);
            return setError(entity, "Invalid entity name");
        }

        if (entity.entity_rule === "") {
            setload(false);
            return setError(entity, "Invalid entity rule");
        }

        let ind = Rules.findIndex(obj => obj.entity_name.toLocaleLowerCase() === entity.entity_name.toLocaleLowerCase());
        if (ind !== -1) {
            setload(false);
            return setError(entity, "Entity name already exists");
        }

        setRules(prevRules => [...prevRules, entity]);
        setEntityForm(type);
        if (type === 'single') {
            if (t1ref.current) {
                setTimeout(() => {
                    t1ref.current.scrollIntoView({ behavior: 'auto', block: 'end' });
                }, 0);
            }
            setAddField(false)
        }
        else {
            if (t2ref.current) {
                setTimeout(() => {
                    t2ref.current.scrollIntoView({ behavior: 'auto', block: 'end' });
                    if (secRef.current) {
                        secRef.current.scrollIntoView({ behavior: 'auto', block: 'end' });
                    }
                }, 0);
            }
            setAddFieldT(false)
        }

        setload(false);

    }

    function setError(entity, message) {
        const setter = entity.entity_type === 'single' ? setnewEnt : setnewEntTable;
        setter(prev => ({ ...prev, error: message }));
    }

    function setEntityForm(type) {
        if (type === 'single') {
            setnewEnt({
                entity_name: "",
                entity_rule: "",
                entity_type: "single",
                error: ""
            });
        } else {
            setnewEntTable({
                entity_name: "",
                entity_rule: "",
                entity_type: "multivalued",
                error: ""
            });
        }
    }
    const [fieldsCheck, setfieldsCheck] = useState(false)
    const [tablesCheck, settablesCheck] = useState(false)

    const memoRiseList = useMemo(() => {
        return (
            <>
                {Rules.filter(itm => itm.entity_type === 'single').map((rule, ind) => {

                    return (
                        <tr key={`${rule.entity_name}_${rule.entity_rule}`}>
                            <MemoizedRow GlobalRules={GlobalRules} settablesCheck={settablesCheck} checked={fieldsCheck} tryTodelete={tryTodelete} setselectedSet={setselectedSet} entity={rule} setRules={setRules} />
                        </tr>
                    )
                })}
            </>
        )


    }, [Rules, tryTodelete, setselectedSet, fieldsCheck,GlobalRules]);

    const memoRiseTList = useMemo(() => {
        return Rules.filter(itm => itm.entity_type === 'multivalued').map((rule, ind) => (
            <tr key={`${rule.entity_name}_${rule.entity_rule}`}>
                <MemoizedRow GlobalRules={GlobalRules} checked={tablesCheck} tryTodelete={tryTodelete} setselectedSet={setselectedSet} entity={rule} setRules={setRules} />
            </tr>
        ));
    }, [Rules, tryTodelete, setselectedSet, tablesCheck,GlobalRules]);



    useEffect(() => {
        setTimeout(() => {
            if (selectedSet.size !== 0) {
                if (tryTodelete) {

                    setRules(p => {
                        let tp = [...p].filter(itm => !(selectedSet.has(itm.entity_name.toLocaleLowerCase())))
                        return tp
                    })
                    setselectedSet(new Set())
                    settryTodelete(false)
                    settablesCheck(false)
                    setfieldsCheck(false)
                }
            }
            else {
                settryTodelete(false)
            }

        }, 2000);



    }, [selectedSet, tryTodelete])

    




    return (
        <>
            <section ref={secRef} className='pt-6'>
                <div className='lg:flex gap-4 '>
                    <div className='lg:w-1/2'>
                        <h6 className='text-xs mb-3'>Single Valued Fields</h6>

                        <table className={`${styles.fldsBoxTable} w-full border-collapse border-spacing-0 border-0`}>
                            <tr>
                                <td className='w-8'>
                                    &nbsp;
                                </td>
                                <td className='w-1/2 font-bold'>Entity</td>
                                <td className=' font-bold'>Rule</td>
                            </tr>
                            <tr>
                                <td className='w-8'>
                                <Checkbox onChange={(e) => {
                                        setfieldsCheck(e.target.checked)
                                    }} {...label} checked={fieldsCheck} size="small" />
                                </td>
                                <td className='w-1/2 font-bold'>Select all</td>
                            </tr>

                        </table>

                        <div className={`${styles.fldsBoxWrap} overflow-auto`}>
                            <table ref={t1ref} className={`${styles.fldsBoxTable} w-full border-collapse border-spacing-0 border-0`}>

                                {memoRiseList}
                            </table>

                        </div>
                        {AddField && <div className={``}>
                            <table className={`${styles.fldsBoxTable} w-full border-collapse border-spacing-0 border-0`}>

                                <tr>
                                    <td className='w-[37px]'>
                                        {AddField ? <button onClick={() => {
                                            setnewEnt({
                                                entity_name: "",
                                                entity_rule: "",
                                                entity_type: "single",
                                                error: ""
                                            })
                                            setAddField(false)
                                        }}><span class="material-icons text-sm mr-1">close</span></button> : '&nbsp;'}
                                    </td>

                                    <td className='w-1/2'>
                                        <label><input onChange={(e) => {
                                            let vl = e.target.value
                                            setnewEnt(p => {
                                                return {
                                                    ...p,
                                                    entity_name: vl.trim().toLocaleUpperCase()
                                                }
                                            })

                                        }} defaultValue={newEnt.entity_name} type='text' className='w-full border border-1 border-gray-300 p-[5px] text-sm rounded-sm' /></label>
                                    </td>
                                    <td className=''>

                                        <div className='flex gap-4 justify-between '>
                                            <div className='w-full validFld' >
                                                <Select
                                                    labelId="outer_lab"
                                                    id='new_field'
                                                    defaultValue=''
                                                    onChange={(event, value) => {
                                                        let vl = event.target.value
                                                        setnewEnt(p => {
                                                            return {
                                                                ...p,
                                                                entity_rule: vl
                                                            }
                                                        })


                                                    }}
                                                >
                                                    <MenuItem value={'Text'}>Text</MenuItem>
                                                    <MenuItem value={'Number'}>Number</MenuItem>
                                                    <MenuItem value={'Integer'}>Integer</MenuItem>
                                                    <MenuItem value={'Decimal'}>Decimal</MenuItem>
                                                    <MenuItem value={'Date'}>Date</MenuItem>
                                                    <MenuItem value={'Currency'}>Currency</MenuItem>
                                                </Select>
                                            </div>
                                            <div className='flex-none'>
                                                <button disabled={load} onClick={() => saveField('single')} className={`disabled:bg-gray-400 bg-blue-800 hover:bg-indigo-700 flex items-center px-4 h-[32px] text-sm font-black rounded-md text-white`}>
                                                    <span class="material-icons text-white text-sm mr-1">check</span>Save
                                                </button>
                                            </div>
                                        </div>


                                    </td>



                                </tr>
                            </table>
                            <p className='text-red-600 pl-9'>{newEnt.error}</p>
                        </div>}
                        {!AddField && <button onClick={() => {
                            setnewEnt({
                                entity_name: "",
                                entity_rule: "",
                                entity_type: "single",
                                error: ""
                            })
                            if (AddField === false) {

                                setload(false)
                                if (t1ref.current) {
                                    t1ref.current.scrollIntoView({ behavior: 'auto', block: 'end' });
                                }
                            }
                            setAddField(p => !p)

                        }} type="button" className="bg-blue-200 hover:bg-indigo-400 px-4 py-1 mt-3 rounded-md text-primary text-sm hover:text-white flex items-center font-black disabled:bg-gray-200 disabled:text-gray-500 disabled:hover:text-gray-500">
                            <span className="material-icons text-sm mr-1">add</span>{AddField ? 'Remove' : 'Add Field'}
                        </button>}
                    </div>

                    <div className='lg:w-1/2'>
                        <h6 className='text-xs mb-3'>Tabular Valued Fields</h6>
                        <table className={`${styles.fldsBoxTable} w-full border-collapse border-spacing-0 border-0`}>
                            <tr>
                                <td className='w-8'>
                                    &nbsp;
                                </td>
                                <td className='w-1/2 font-bold'>Entity</td>
                                <td className=' font-bold'>Rule</td>
                            </tr>
                            <tr>
                                <td className='w-8'>
                                <Checkbox onChange={(e) => {
                                    settablesCheck(e.target.checked)
                                        
                                    }} {...label} checked={tablesCheck} size="small" />
                                </td>
                                <td className='w-1/2 font-bold'>Select all</td>
                            </tr>

                        </table>
                        <div className={`${styles.fldsBoxWrap} overflow-auto`}>

                            <table ref={t2ref} className={`${styles.fldsBoxTable} w-full border-collapse border-spacing-0 border-0`}>
                                {memoRiseTList}

                            </table>
                        </div>
                        {AddFieldT && <div className={``}>
                            <table className={`${styles.fldsBoxTable} w-full border-collapse border-spacing-0 border-0`}>

                                <tr>
                                    <td className='w-[37px]'>
                                        {AddFieldT ? <button onClick={() => {
                                            setnewEntTable({
                                                entity_name: "",
                                                entity_rule: "",
                                                entity_type: "multivalued",
                                                error: ""
                                            })
                                            setAddFieldT(false)
                                        }}><span className="material-icons text-sm mr-1">close</span></button> : '&nbsp;'}
                                    </td>

                                    <td className='w-1/2'>
                                        <label><input onChange={(e) => {
                                            let vl = e.target.value
                                            setnewEntTable(p => {
                                                return {
                                                    ...p,
                                                    entity_name: vl.trim().toLocaleUpperCase()
                                                }
                                            })

                                        }} defaultValue={newEntTable.entity_name} type='text' className='w-full border border-1 border-gray-300 p-[5px] text-sm rounded-sm' /></label>

                                    </td>

                                    <td className=''>

                                        <div className='flex gap-4 justify-between '>
                                            <div className='w-full validFld' >
                                                <Select
                                                    labelId="outer_lab"
                                                    id='new_field'
                                                    defaultValue=''
                                                    onChange={(event, value) => {
                                                        let vl = event.target.value
                                                        setnewEntTable(p => {
                                                            return {
                                                                ...p,
                                                                entity_rule: vl
                                                            }
                                                        })


                                                    }}
                                                >
                                                    <MenuItem value={'Text'}>Text</MenuItem>
                                                    <MenuItem value={'Number'}>Number</MenuItem>
                                                    <MenuItem value={'Integer'}>Integer</MenuItem>
                                                    <MenuItem value={'Decimal'}>Decimal</MenuItem>
                                                    <MenuItem value={'Date'}>Date</MenuItem>
                                                    <MenuItem value={'Currency'}>Currency</MenuItem>
                                                </Select>
                                            </div>
                                            <div className='flex-none'>
                                                <button disabled={load} onClick={() => saveField('multivalued')} class="bg-blue-800 hover:bg-indigo-700 flex items-center px-4 h-[32px] text-sm font-black rounded-md text-white">
                                                    <span class="material-icons text-white text-sm mr-1">check</span>Save
                                                </button>
                                            </div>
                                        </div>


                                    </td>





                                </tr>
                            </table>
                            <p className='text-red-600 pl-9'>{newEntTable.error}</p>
                        </div>}
                        {!AddFieldT && <button onClick={() => {
                            setnewEntTable({
                                entity_name: "",
                                entity_rule: "",
                                entity_type: "multivalued",
                                error: ""
                            })
                            if (AddFieldT === false) {
                                setload(false)
                                if (t2ref.current) {
                                    t2ref.current.scrollIntoView({ behavior: 'auto', block: 'end' });
                                }
                                if (secRef.current) {
                                    secRef.current.scrollIntoView({ behavior: 'auto', block: 'end' });
                                }
                            }
                            setAddFieldT(p => !p)

                        }} type="button" className="bg-blue-200 hover:bg-indigo-400 px-4 py-1 mt-3 rounded-md text-primary text-sm hover:text-white flex items-center font-black disabled:bg-gray-200 disabled:text-gray-500 disabled:hover:text-gray-500">
                            <span className=" material-icons text-sm mr-1">add</span>{AddFieldT ? 'Remove' : 'Add Field'}
                        </button> }
                    </div>
                </div>

            </section>
        </>
    )
}

export default FieldsNTabularFields



const MemoizedRow = React.memo(({GlobalRules, settablesCheck, checked, entity, setRules, tryTodelete, setselectedSet }) => {
    


    const [check, setcheck] = useState(checked)
    const [isglobal, setisglobal] = useState(false)

    useEffect(() => {

        if (tryTodelete && check) {
            setselectedSet(p => {
                p.add(entity.entity_name.toLocaleLowerCase())
                return p
            })
        }
    }, [tryTodelete])

    useEffect(() => {
        setcheck(checked)
        
    }, [checked])

    useEffect(() => {
        if (GlobalRules){
            let match = GlobalRules.filter(gl => gl.entity_name.toLocaleLowerCase() ===  entity.entity_name.toLocaleLowerCase() )            
            if (match.length !==0){
                setisglobal(true) 
            }
            else{
                setisglobal(false)
            }
        }
    }, [entity, GlobalRules])
    
    



    return (
        <>
            <td className='w-5'>
                <Checkbox onChange={(e) => {
                    setcheck(e.target.checked)

                }} {...label} checked={check} size="small" />
            </td>
            

            <td className='w-1/2'>
                <label><input disabled={true} defaultValue={entity.entity_name} type='text' className={`${isglobal ? 'border-green-500 border-2' : '' }  w-full border border-1 border-gray-300 p-[5px] text-sm rounded-sm`} /></label>
            </td>
            <td className=''>
                <div className='w-full validFld'>
                    <Select
                        labelId="demo-simple-select-label"
                        id={`${entity.entity_name}`}
                        defaultValue={entity.entity_rule}
                        onChange={(event) => {
                            if (event.target.value === entity.entity_rule) {
                                return;
                            }
                            setRules(p => {
                                let tp = [...p];
                                let indOf = tp.findIndex(obj => obj.entity_name === entity.entity_name);
                                tp[indOf].entity_rule = event.target.value;
                                return tp;
                            });
                        }}
                    >
                        <MenuItem value={'Text'}>Text</MenuItem>
                        <MenuItem value={'Number'}>Number</MenuItem>
                        <MenuItem value={'Integer'}>Integer</MenuItem>
                        <MenuItem value={'Decimal'}>Decimal</MenuItem>
                        <MenuItem value={'Date'}>Date</MenuItem>
                        <MenuItem value={'Currency'}>Currency</MenuItem>
                    </Select>
                </div>
            </td>

        </>
    )
});