import React, { useEffect, useState } from 'react';
import DateFilter from './date-filter';
import DashCounters from './dash-counters';
import UploadDonuts from './upload-progress';
import AnnotationsChart from './annotations-chart';
import AnnotationsInProgressChart from './annotations-in-progress'
import InvoiceAttentions from './invoice-attention';
import { useSelector } from 'react-redux';
import { toast } from 'react-toastify';
import LoaderFull from '../shared/loader-full-screen';
import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';
import Box from '@mui/material/Box';
import ContractAttentions from './contracts-attention';
import MismatchesdDonuts from './mismatches-progress'
import DataFielddDonuts from './datafield-progress'
import DataValidationsErrorDonuts from './data-validations-error-progress'
import VendorsList from './top-vendors';
import UnmappedPricedDonuts from './unmappedprice-progress'

import { useFetchDashboardData,useFetchInvoiceNeedAttention,useFetchMismatches,useFetchDataField,useFetchUnmappedPriceItems,useFetchDataValidationError } from './hooks';
//import { useFetchDashboardData,useFetchInvoiceNeedAttention,useFetchMismatches,useFetchDataField, } from './hooks';
import dayjs from 'dayjs';

function Dashboard() {
	const [DashboardData, setDashboardData] = useState({});
	const [MismatchesData, setMismatchesData] = useState({});
	const [UnmappedPriceData, setUnmappedPriceData] = useState({});
	const [percentageUnmappedPriceData,setPercentageUnmappedPriceData]= React.useState(null);
	const [InvoiceData, setInvoiceData] = useState({});
	const [value, setValue] = React.useState(0);
	const [percentageCompleted,setPercentageCompleted]= React.useState(null);
	const [ina, setIna] = React.useState(0);
	const [percentageMismatches, setPercentageMismatches] = React.useState(0);
	const [FieldData, setFieldData] = useState({});
	const [ValidationErrorData, setValidationErrorData] = useState({});
	const [percentageFieldData,setPercentageFieldData]= React.useState(null);
	const [percentageDataValidationError,setPercentageDataValidationError]= React.useState(null);
	const [showDataField, setShowDataField] = React.useState(0);
	const [showDataValidationError, setShowDataValidationError] = React.useState(0);
	const get_dashboard_hook = useFetchDashboardData();

	//const get_invoice_need_attention_hook = useFetchInvoiceNeedAttention();

	const get_mismatches_hook = useFetchMismatches();

	const get_datafield_hook = useFetchDataField();

	const get_unmapped_price_hook = useFetchUnmappedPriceItems();

	const get_datavalidationerror_hook = useFetchDataValidationError();
	const Data = useSelector(p=> p.dashSlice)
	useEffect(() => {
		get_dashboard_hook.mutate({analyst: Data.analyst, category: Data.category, client:Data.client, vendor:Data.vendor, invoice:Data.invoice, field:encodeURIComponent(Data.fieldNameId), batch:Data.batch, invoice_status:Data.invoice_status, datefrom: (Data.datefrom) ? dayjs(Data.datefrom.$d).format("MM-DD-YYYY") : null, dateto: (Data.dateto) ? dayjs(Data.dateto.$d).format("MM-DD-YYYY") : null });
		//get_invoice_need_attention_hook.mutate({analyst: null,category: null, datefrom: null, dateto: null});
		get_mismatches_hook.mutate({analyst: Data.analyst, category: Data.category, client:Data.client, vendor:Data.vendor, invoice:Data.invoice, field:encodeURIComponent(Data.fieldNameId), batch:Data.batch, invoice_status:Data.invoice_status, datefrom: (Data.datefrom) ? dayjs(Data.datefrom.$d).format("MM-DD-YYYY") : null, dateto: (Data.dateto) ? dayjs(Data.dateto.$d).format("MM-DD-YYYY") : null });
		//get_datafield_hook.mutate({analyst: null, category: null, client:null, vendor:null, invoice:null, field:null, batch:null, invoice_status:null, datefrom: null, dateto: null});
		get_unmapped_price_hook.mutate({analyst: Data.analyst, category: Data.category, client:Data.client, vendor:Data.vendor, invoice:Data.invoice, field:encodeURIComponent(Data.fieldNameId), batch:Data.batch, invoice_status:Data.invoice_status, datefrom: (Data.datefrom) ? dayjs(Data.datefrom.$d).format("MM-DD-YYYY") : null, dateto: (Data.dateto) ? dayjs(Data.dateto.$d).format("MM-DD-YYYY") : null });
		handleDataFromChild3({ show: true,analyst: Data.analyst, category: Data.category, client:Data.client, vendor:Data.vendor, invoice:Data.invoice, field:encodeURIComponent(Data.fieldNameId), batch:Data.batch, invoice_status:Data.invoice_status, datefrom: (Data.datefrom) ? dayjs(Data.datefrom.$d).format("MM-DD-YYYY") : null, dateto: (Data.dateto) ? dayjs(Data.dateto.$d).format("MM-DD-YYYY") : null  });

		//get_datavalidationerror_hook.mutate({analyst: null, category: null, client:null, vendor:null, invoice:null, field:null, batch:null, invoice_status:null, datefrom: null, dateto: null});
		if(Data.fieldNameId){
			handleDataFromChild({ show: true,analyst: Data.analyst, category: Data.category, client:Data.client, vendor:Data.vendor, invoice:Data.invoice, field:encodeURIComponent(Data.fieldNameId), batch:Data.batch, invoice_status:Data.invoice_status, datefrom: (Data.datefrom) ? dayjs(Data.datefrom.$d).format("MM-DD-YYYY") : null, dateto: (Data.dateto) ? dayjs(Data.dateto.$d).format("MM-DD-YYYY") : null  });
			get_datafield_hook.mutate({analyst: Data.analyst, category: Data.category, client:Data.client, vendor:Data.vendor, invoice:Data.invoice, field:encodeURIComponent(Data.fieldNameId), batch:Data.batch, invoice_status:Data.invoice_status, datefrom: (Data.datefrom) ? dayjs(Data.datefrom.$d).format("MM-DD-YYYY") : null, dateto: (Data.dateto) ? dayjs(Data.dateto.$d).format("MM-DD-YYYY") : null });
		}
		else{
			handleDataFromChild({show: false})
		}
		if(Data.analyst || Data.category || Data.client || Data.vendor ){
			handleDataFromChild2({ show: true,analyst: Data.analyst, category: Data.category, client:Data.client, vendor:Data.vendor, invoice:Data.invoice, field:encodeURIComponent(Data.fieldNameId), batch:Data.batch, invoice_status:Data.invoice_status, datefrom: (Data.datefrom) ? dayjs(Data.datefrom.$d).format("MM-DD-YYYY") : null, dateto: (Data.dateto) ? dayjs(Data.dateto.$d).format("MM-DD-YYYY") : null  });
			get_datavalidationerror_hook.mutate({analyst: Data.analyst, category: Data.category, client:Data.client, vendor:Data.vendor, invoice:Data.invoice, field:encodeURIComponent(Data.fieldNameId), batch:Data.batch, invoice_status:Data.invoice_status, datefrom: (Data.datefrom) ? dayjs(Data.datefrom.$d).format("MM-DD-YYYY") : null, dateto: (Data.dateto) ? dayjs(Data.dateto.$d).format("MM-DD-YYYY") : null });
		}
		else{
			handleDataFromChild2({show: false})
		}
	}, []);

	useEffect(() => {
		if (get_dashboard_hook.isError) {
			toast.error(get_dashboard_hook.error?.message);
		} else if (get_dashboard_hook.isSuccess || get_dashboard_hook.data) {
			setDashboardData(get_dashboard_hook.data);
			//setShowDatField(1)
			//console.log(get_dashboard_hook.data);
			//setShowDataField(0)
			//setShowDataValidationError(0)
			setPercentageCompleted( ((get_dashboard_hook.data?.total_files_completed/get_dashboard_hook.data?.total_files_uploaded)*100).toFixed(2) )
		}
	}, [get_dashboard_hook.isError, get_dashboard_hook.isSuccess, get_dashboard_hook.data, get_dashboard_hook.error?.message]);


	useEffect(() => {
		
		if (get_mismatches_hook.isError) {
			toast.error(get_mismatches_hook.error?.message);
		} else if (get_mismatches_hook.isSuccess || get_mismatches_hook.data) {
			setMismatchesData(get_mismatches_hook.data);
			
			setPercentageMismatches( ((get_mismatches_hook.data?.pending_mismatch/get_mismatches_hook.data?.total_mismatch)*100).toFixed(2) )
		}
	}, [get_mismatches_hook.isError, get_mismatches_hook.isSuccess, get_mismatches_hook.data, get_mismatches_hook.error?.message]);


	useEffect(() => {
		
		if (get_datafield_hook.isError) {
			toast.error(get_datafield_hook.error?.message);
		} else if (get_datafield_hook.isSuccess || get_datafield_hook.data) {
			setFieldData(get_datafield_hook.data);
			setShowDataField(1)
			
			setPercentageFieldData( ((get_datafield_hook.data?.total_data_field/get_datafield_hook.data?.total_invoice_uploaded)*100).toFixed(2) )
		}
	}, [get_datafield_hook.isError, get_datafield_hook.isSuccess, get_datafield_hook.data, get_datafield_hook.error?.message]);


	useEffect(() => {
		
		if (get_datavalidationerror_hook.isError) {
			toast.error(get_datavalidationerror_hook.error?.message);
		} else if (get_datavalidationerror_hook.isSuccess || get_datavalidationerror_hook.data) {
			setValidationErrorData(get_datavalidationerror_hook.data);
			setShowDataValidationError(1)
			
			setPercentageDataValidationError( ((get_datavalidationerror_hook.data?.correct/get_datavalidationerror_hook.data?.total)*100).toFixed(2) )
		}
	}, [get_datavalidationerror_hook.isError, get_datavalidationerror_hook.isSuccess, get_datavalidationerror_hook.data, get_datavalidationerror_hook.error?.message]);

	useEffect(() => {
		
		if (get_unmapped_price_hook.isError) {
			toast.error(get_unmapped_price_hook.error?.message);
		} else if (get_unmapped_price_hook.isSuccess || get_unmapped_price_hook.data) {
			console.log(get_unmapped_price_hook.data);
			setUnmappedPriceData(get_unmapped_price_hook.data);
			
			setPercentageUnmappedPriceData( ((get_unmapped_price_hook.data?.total_false_count/get_unmapped_price_hook.data?.total_count)*100).toFixed(2) )
		}
	}, [get_unmapped_price_hook.isError, get_unmapped_price_hook.isSuccess, get_unmapped_price_hook.data, get_unmapped_price_hook.error?.message]);

	/* useEffect(() => {
		
		
		if (get_invoice_need_attention_hook.isError) {
			toast.error(get_invoice_need_attention_hook.error?.message);
		} else if (get_invoice_need_attention_hook.isSuccess || get_invoice_need_attention_hook.data) {
			//console.log(get_invoice_need_attention_hook.data);
			setInvoiceData(get_invoice_need_attention_hook.data);
			//console.log(get_dashboard_hook.data);
			
			//setPercentageCompleted( ((get_dashboard_hook.data?.total_files_completed/get_dashboard_hook.data?.total_files_uploaded)*100).toFixed(2) )
			setIna(get_invoice_need_attention_hook.data.invoice_attention_list?.count)
		}
	}, [get_invoice_need_attention_hook.isError, get_invoice_need_attention_hook.isSuccess, get_invoice_need_attention_hook.data, get_invoice_need_attention_hook.error?.message]);
 */


	/* const handleChange = (event, newValue) => {
		setValue(newValue);
	}; */

	const PrivilagesList = useSelector(
		(state) => state?.privilageSlice?.allowedActions
	);

	const [dataFromChild, setDataFromChild] = useState("");
	const [dataFromChild2, setDataFromChild2] = useState("");
	const [dataFromChild3,setDataFromChild3]= useState("");

	function handleDataFromChild(data) {
		console.log(data);

		if(data.show){
			setShowDataField(1)
			setDataFromChild(data);
		}
		else{
			setShowDataField(0)
		}
	}

	function handleDataFromChild2(data) {
		console.log(data);
		if(data.show){
			setShowDataValidationError(1)
			setDataFromChild2(data);
		}
		else{
			setShowDataValidationError(0)
			setValidationErrorData({})
		}
	}

	function handleDataFromChild3(data) {
		console.log(data);
		if(data.show){
			setDataFromChild3(data);
		}
		else{
			//setShowDataValidationError(0)
			//setValidationErrorData({})
		}
	}

	return (
		<>
			
			<div className="grow fontInter">
				
				<div className="px-6 py-4 mb-4">
					<div className="pb-4">
						<h1 className="text-lg font-black text-primary">Dashboard</h1>
						<p className="text-xs text-gray-500">
							Total data of Clients, Vendors, Invoices, Contracts
						</p>
					</div>
				
					<DateFilter sendDataToParent3={handleDataFromChild3} sendDataToParent={handleDataFromChild} sendDataToParent2={handleDataFromChild2} get_dashboard_hook={get_dashboard_hook} get_mismatches_hook={get_mismatches_hook} get_datafield_hook={get_datafield_hook} get_datavalidationerror_hook={get_datavalidationerror_hook} get_unmapped_price_hook={get_unmapped_price_hook} />
				</div>
				{/* Total data of Clients, Vendors, Invoices, Contracts */}

				{get_dashboard_hook.isPending ? (
					<LoaderFull />
				) : (
					<DashCounters DashboardData={DashboardData} />
				)}
				<div className="md:flex border-b">
					<div className="md:w-1/2 lg:w-1/3 xl:w-1/4 pl-6 pr-4 py-4 border-b md:border-b-0 md:border-r">
						{/* Processing files */}

						{get_dashboard_hook.isPending ? (
							<LoaderFull />
						) : (
							<div className='donCanVas'>
								<UploadDonuts DashboardData={DashboardData} />
								{percentageCompleted >=0 && ( <label className='donCLbl'>{percentageCompleted}% Processed</label> )}
							</div>
						)}
					</div>
					{!PrivilagesList.includes('analyst_dashboard') ? (
						<div className="md:w-1/2 lg:w-2/3 xl:w-3/4">
							<div className="grid gap-4 grid-cols-1 xl:grid-cols-2">
								{PrivilagesList.includes("all_dashboard") ? (
									<div className="p-4">
										{/* Human Annotations */}
										{(get_dashboard_hook.isPending) ? (
											<LoaderFull />
										) : (
											<AnnotationsChart DashboardData={DashboardData} />
										)}
									</div>
								) : null}
								{PrivilagesList.includes("leader_dashboard") ? (
									<div className="p-4">
										{/* Annotations in progress */}
										{(get_dashboard_hook.isPending) ? (
											<LoaderFull />
										) : (
											<AnnotationsInProgressChart DashboardData={DashboardData} />
										)}
									</div>
								) : null}
								<div className="p-4">
								{/* Top Vendors Annotated */}

								{(get_dashboard_hook.isPending) ? (
									<LoaderFull />
								) : (
									<VendorsList DashboardData={DashboardData} />
								)}
								</div>
							</div>
						</div>

					) : null}
				</div>

				<div className="md:flex border-b">
					<div className="md:w-1/2 lg:w-1/3 xl:w-1/4 pl-6 pr-4 py-4 border-b md:border-b-0 md:border-r">
						{/* Processing files */}

						{get_mismatches_hook.isPending ? (
							<LoaderFull />
						) : (
							<div className='donCanVas'>
								<MismatchesdDonuts MismatchesData={MismatchesData} dataFromChild3={dataFromChild3}/>
								{percentageMismatches >=0 && ( <label className='donCLbl'>{percentageMismatches}% Mismatched</label> )}
							</div>
						)}
					</div>
					
					{showDataField ? (
						<div className="md:w-1/2 lg:w-1/3 xl:w-1/4 pl-6 pr-4 py-4 border-b md:border-b-0 md:border-r">
							{/* Processing files */}

							{get_datafield_hook.isPending ? (
								<LoaderFull />
							) : (
								<div className='donCanVas'>
									<DataFielddDonuts FieldData={FieldData} dataFromChild={dataFromChild} />
									{percentageFieldData >=0 && ( <label className='donCLbl'>{percentageFieldData}% Used</label> )}
								</div>
							)}
						</div>
					):null}

					<div className="md:w-1/2 lg:w-1/3 xl:w-1/4 pl-6 pr-4 py-4 border-b md:border-b-0 md:border-r">

						{get_unmapped_price_hook.isPending ? (
							<LoaderFull />
						) : (
							<div className='donCanVas'>
								<UnmappedPricedDonuts UnmappedPriceData={UnmappedPriceData} dataFromChild3={dataFromChild3} />
								{percentageUnmappedPriceData >= 0 && ( <label className='donCLbl'>{percentageUnmappedPriceData}% Unmapped</label> )}
							</div>
						)}
					</div>
					{showDataValidationError ? (
					<div className="md:w-1/2 lg:w-1/3 xl:w-1/4 pl-6 pr-4 py-4 border-b md:border-b-0 md:border-r">
						{/* Processing files */}

						{get_datavalidationerror_hook.isPending ? (
							<LoaderFull />
						) : (
							<div className='donCanVas'>
								<DataValidationsErrorDonuts ValidationErrorData={ValidationErrorData} dataFromChild2={dataFromChild2}/>
								{percentageDataValidationError >=0 && ( <label className='donCLbl'>{percentageDataValidationError}% Validated</label> )}
							</div>
						)}
					</div>):null}
					
				</div>
				

				{/* <div className="p-4">
					<Box sx={{ bgcolor: 'background.paper' }}>
						<Tabs
							value={value}
							onChange={handleChange}
							textColor="primary"
							indicatorColor="primary"
							aria-label="attention tabs"
						>
							{(get_invoice_need_attention_hook.isPending) ? (
									<Tab
									className="text-lg font-black"
									label={"Invoices Need Attention (0)"}
								/>
								) : (
									<Tab
								className="text-lg font-black"
								label={"Invoices Need Attention ("+ina+")"}
							/>
								)}
							
							<Tab
								className="text-lg font-black"
								label="Contracts Need Attention"
							/>
						</Tabs>
					</Box>
					{value === 0 && (
						<div className="px-2 py-4">
							
							{(get_invoice_need_attention_hook.isPending) ? (
								<LoaderFull />
							) : (
								<InvoiceAttentions DashboardData={InvoiceData} />
							)}
						</div>
					)}
					{value === 1 && (
						<div className="px-2 py-4">
							
							<ContractAttentions />
						</div>
					)}
				</div> */}
			</div>
		</>
	);
}

export default Dashboard;