import { useQuery, useMutation, useQueryClient } from '@tanstack/react-query';
import { useDispatch } from 'react-redux';
import { useDebouncedState } from '@mantine/hooks';
import { toast } from 'react-toastify';
import { RECORDS_PER_PAGE } from '../../config';
import { useState } from 'react';
import { handleLock, LogoutCheck } from '../utils';
import { buildAxiosInstance } from '../axiosInstance';
import { useMsal } from '@azure/msal-react';

const fetchinvoicesList = async ( Args, dispatch, signal,instance) => {
	//handleLock(dispatch, 'last_doc_open',instance)
	try {
		const TOAST_ID = 'unmapped-price-item-list'
		const axiosInstance = buildAxiosInstance(TOAST_ID)
		let config = {
			params: {...Args, limit: RECORDS_PER_PAGE},
			signal: signal,
		};
		const responseData = await axiosInstance.get('/unmapped_price_item_list',config)
		return responseData
	} catch (error) {
		LogoutCheck(error, dispatch,instance)	
		
	};
};

export const useInvoiceslistPagewise = (Sort) => {
	let dispatch = useDispatch();
	const { instance } = useMsal();
	const [Args, setArgs] = useState({
		search: '',
		...Sort
	});
	const hook = useQuery({
		queryKey: ['unmapped_price_item_list', Args],
		queryFn: async ({ signal }) =>
			fetchinvoicesList(Args, dispatch, signal,instance),
		retry: false,
	});
	return {
		hook: hook,
		Args: Args,
		setArgs: setArgs,
	};
};
