import React, { useState, useEffect } from 'react';

import { Autocomplete, TextField } from '@mui/material';
import { useGetBatches } from './hooks';
import {Box } from '@mui/material'

function FilterBatchName({
    setBatchName,
    BatchName,
    setBatchNameId,
    BatchNameId,
    CategoryId,
    VendorNameId,
    ClientId,
    AnalystId
}) {

    const [lable] = useState('Batch Name')
    const [open, setOpen] = useState(false);
    const [options, setoptions] = useState([]);
    const batch_list_hook = useGetBatches();
    //const { refetch, isFetching } = useGetBatches();
    const [Focus, setFocus] = useState(false);


    useEffect(() => {
        batch_list_hook.mutate({CategoryId,ClientId,VendorNameId,AnalystId});
    }, [CategoryId,VendorNameId,ClientId,AnalystId]);

    useEffect(() => {
        if (batch_list_hook.data) {
            //console.log(batch_list_hook.data.data);
            
            //setoptions(batch_list_hook.data.data);
            setoptions(batch_list_hook.data.data.map((itm,idx) => {
                
                return {
                    name:itm.name,
                    id:itm._id
                }

            }));
        }
    }, [batch_list_hook.data, batch_list_hook.isSuccess]);

    function changeBatch(val) {
        //console.log(val);
        
        //setBatchName(val);
        let obj = batch_list_hook.data.data?.find((o) => (o?.name === val?.name));
        //console.log(obj);
        
        if (obj) {
            setBatchNameId(obj._id);
            setBatchName(val);
        }
        else {
            setBatchNameId(null)
            setBatchName(null)
        }
    }
    let stop = false;
    return (
        <>
            <div className="grow missDD">
                <div className="lightT" id="sbatch">
                    {batch_list_hook.isPending ? (
                        <p className="px-4 py-2 border border-1 border-slate-300 rounded-md">
                            <span className="text-sm text-slate-400 ps-4 inline-block">
                                Loading...
                            </span>
                        </p>
                    ) : (
                        <Autocomplete
                        disablePortal
                        isOptionEqualToValue={(option, value) => option.id === value.id}
                        id="combo-box-demo"
                        className='expCat'
                        options={options}
                        value={options.some(function(el){ return el.name === BatchName?.name}) ? BatchName : null }
                        getOptionLabel={(option) => option.name}
                        renderInput={(params) => (
                            <TextField {...params} label={lable} />
                        )}
                        onChange={(event, value) => changeBatch(value)}
                        onFocus={() => setFocus(true)}
                        onBlur={() => setFocus(false)}
                        //getOptionLabel={(option) => option.fname}
                        renderOption={(props, option) => {
                            return (
                                <Box component="li" {...props} key={option.id}>
                                    {option.name}
                                </Box>
                            )
                        }}

                        />
                    )}
                </div>
            </div>
        </>
    );
}

export default FilterBatchName;
