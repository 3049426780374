import React  from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import App from './App';
import reportWebVitals from './reportWebVitals';

import { Provider } from 'react-redux';
import store from './store.js';
import { PersistGate } from 'redux-persist/integration/react';
import { persistStore } from 'redux-persist';
import { QueryClient, QueryClientProvider } from '@tanstack/react-query';
import { pca } from './components/axiosInstance.js';
/* import { getKeyVaultSecret } from "./keyVaultService.js";


const App1 = () => {
	const [apiKey, setApiKey] = useState(null);
	
	useEffect(() => {
		const fetchSecret = async () => {
			try {
				const secretValue = await getKeyVaultSecret("test");
				setApiKey(secretValue);
				console.log(apiKey);
			} catch (error) {
				// Handle error
			}
		};
		
		fetchSecret();
	}, []);

} */

const queryClient = new QueryClient({
	defaultOptions: {
		queries: {
			refetchOnWindowFocus: false, // default: true
		},
	},
});
let persistor = persistStore(store);



// Call the initialize function before using other MSAL APIs


const root = ReactDOM.createRoot(document.getElementById('root'));

root.render(
	<Provider store={store}>
		<PersistGate loading={null} persistor={persistor}>
			<QueryClientProvider client={queryClient}>
				<App msalInstance={pca} />
			</QueryClientProvider>
		</PersistGate>
	</Provider>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
