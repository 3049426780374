import React, { useState, useEffect, Fragment } from 'react';

import { useSearchParams, useNavigate } from 'react-router-dom';
import { toast } from 'react-toastify';
import LoaderFull from '../../shared/loader-full-screen';
import 'split-pane-react/esm/themes/default.css';
import { useDispatch } from 'react-redux';
import { useCategoryNameById } from '../../list_invoices/hooks';
import { useGetPricing } from './hooks';
import styles from '../../annotation_contracts/invoice.module.css';
import { useDebouncedState } from '@mantine/hooks';
import { TextHighlight } from './textHighlight';


function ConsolidatedPricingView({contract_id}) {
    //const urlParms = useParams()
    const [searchParams] = useSearchParams();
    const get_tables_hook = useGetPricing(contract_id);
    const [Data, setData] = useState([])
    const [DebounceSearch, setDebounceSearch] = useDebouncedState('', 500);

    useEffect(() => {
        if (get_tables_hook.data && get_tables_hook.isSuccess) {
            setData(get_tables_hook.data.data)
        }
    }, [get_tables_hook.isSuccess, get_tables_hook.data])



    let navigate = useNavigate()
    // const [VersionStatus, setVersionStatus] = useState(0)


    let dispatch = useDispatch();
    const catname_hook = useCategoryNameById(searchParams.get('category_id'))



    return (
        <>
            <div className="grow h-screen overflow-x-hidden overflow-y-auto fontInter">
                {/* Right Side - White workspaces */}
                {get_tables_hook.isPending ? <LoaderFull /> :
                    <div className="lg:flex lg:h-full">
                        <div className='lg:flex-col lg:h-full lg:w-full'>
                           

                            <div className='pr-3 pricing'>
                            
                                <div className="flex flex-col w-full h-full">
                                    <div>
                                        {Data.length !== 0 && <div key='table_section' className={`${styles.invFieldsWrap} ${styles.price} overflow-auto py-4 pl-6`}>
                                            {
                                                Data.map((contract, contractInd) => {
                                                    return (
                                                        <div key={`contract_${contractInd}`}>
                                                            <p classname='mb-2  '><strong>{`${contract.master ? 'Master contract' : 'Amendment contract' }- `}</strong> <strong className='text-blue-600 '>{contract?.name}</strong>  </p>
                                                            {Object.keys(contract?.data).map((page) => {
                                                                let tables = contract.data[page]

                                                                return (
                                                                    <div key={`page_${contractInd}_${page}`}>
                                                                        <p className=' pb-2 border-b border-blue-600 mb-3'>Page {parseInt(page)+1}</p>
                                                                        {tables.map((table, tableInd) => {

                                                                            return (
                                                                                <RenderTable DebounceSearch={DebounceSearch} table={table} tableName={`${contractInd}_${page}_${tableInd}`} />
                                                                            )
                                                                        })}
                                                                    </div>

                                                                )
                                                            })}
                                                        </div>

                                                    )
                                                })
                                            }
                                        </div>}
                                    </div>

                                </div>
                            </div>

                        </div>
                    </div>}
            </div>
        </>
    );
}
export default ConsolidatedPricingView;


function RenderTable({DebounceSearch, table, tableName }) {

    return (
        <div key={`tb_name_${tableName}`}>
            <div className={`${styles.boxTableCtnr} overflow-auto mb-4 py-2`}>
                <table className={styles.bxTable}>
                    <tbody>
                        {Object.keys(table).map((row_name, rowInd) => {
                            let row_lst = table[row_name];
                            return (
                                <Fragment key={`table_row_${rowInd}`} >
                                    {rowInd === 0 && (
                                        <tr key={`${tableName}_${row_name}_lable`}>
                                            {row_lst.length !== 0 &&
                                                <td className={`${styles.stik} w-7 text-right`}>
                                                    <span className='inline-block text-center rounded-md size-6 leading-6 bg-gray-300 border border-gray-400 text-sm'>#</span>
                                                </td>}
                                            {row_lst.map((cell, colInd) => {
                                                return (
                                                    <td key={`${tableName}_${row_name}_${colInd}_lable`}>
                                                        <div className={`${styles.lblBdr} ${styles.und} mb-2 flex gap-2 w-full`}>

                                                            <div className="grow text-blue-600">
                                                                <TextHighlight text={cell.entity} searchString={DebounceSearch} />
                                                                {/* <input
                                                                    title={cell.entity}
                                                                    disabled={true}
                                                                    type="text"
                                                                    // className={styles.invFldInput}
                                                                    className={`${styles.text2Lines} font-bold text-sm outline-none`}
                                                                    value={cell.entity}
                                                                /> */}


                                                            </div>
                                                        </div>
                                                    </td>
                                                );
                                            })}
                                        </tr>
                                    )}


                                    <tr key={`${tableName}_${row_name}`}>
                                        {row_lst.length !== 0 &&
                                            <td className={`${styles.stik}`}>
                                                <div className='flex gap-2'>

                                                    <span className='inline-block text-center rounded-md size-6 leading-6 bg-gray-300 border border-gray-400 text-sm'>
                                                        {rowInd + 1}
                                                    </span>
                                                </div>
                                            </td>}
                                        {row_lst.map((cell, colInd) => {
                                            return (

                                                <td key={`${tableName}_${row_name}_${colInd}`}>
                                                    <div className={`${styles.lblBdr} block w-full h-8`}>
                                                    <TextHighlight text={cell.value} searchString={DebounceSearch} />
                                                        {/* <input
                                                            title={cell.value}
                                                            disabled={true}
                                                            type="text"
                                                            className={styles.invFldInput}
                                                            value={cell.value}

                                                        /> */}
                                                        <div className={styles.fltIco}>
                                                            {cell.entity_valid === 'true' && (
                                                                <span
                                                                    title={
                                                                        cell.entity_validation_explanation
                                                                    }
                                                                    className="material-icons cursor-default text-green-500 text-xs"
                                                                >
                                                                    check_circle
                                                                </span>
                                                            )}
                                                            {cell.entity_valid === 'false' && (
                                                                <span
                                                                    title={
                                                                        cell.entity_validation_explanation
                                                                    }
                                                                    className="material-icons cursor-default text-red-500 text-xs"
                                                                >
                                                                    check_circle
                                                                </span>
                                                            )}
                                                            {cell.entity_valid === 'none' && (
                                                                <span
                                                                    title={
                                                                        cell.entity_validation_explanation
                                                                    }
                                                                    className="material-icons cursor-default text-gray-500 text-xs"
                                                                >
                                                                    check_circle
                                                                </span>
                                                            )}
                                                        </div>
                                                    </div>
                                                </td>
                                            );
                                        })}
                                    </tr>

                                </Fragment>

                            );
                        })}
                    </tbody>

                </table>
            </div>
        </div>
    )
}
