import React from 'react';
/* import Highcharts from 'highcharts'
import HighchartsReact from 'highcharts-react-official' */
import { Doughnut } from 'react-chartjs-2';
import { useNavigate } from "react-router-dom";

import { Chart as ChartJS, ArcElement, Tooltip, Legend } from 'chart.js';
//import { Pie } from 'react-chartjs-2';
ChartJS.register(ArcElement, Tooltip, Legend);
function UploadDonuts({DashboardData}) {
  let navigate = useNavigate(); 
    //console.log(DashboardData?.total_files_completed);
    //console.log(DashboardData?.total_files_uploaded);
    const percentage_processing = ((DashboardData?.total_files_processing/DashboardData?.total_files_uploaded)*100).toFixed(2);
    const percentage_completed = ((DashboardData?.total_files_completed/DashboardData?.total_files_uploaded)*100).toFixed(2);
    const percentage_error = ((DashboardData?.total_files_errors/DashboardData?.total_files_uploaded)*100).toFixed(2);
    const percentage_pending = ((DashboardData?.total_files_pending/DashboardData?.total_files_uploaded)*100).toFixed(2);
    //console.log(percentage_processing,percentage_completed,percentage_error,percentage_pending);
    const data = {
        labels: ['% Processed','% Processing','% Pending','% Failed' ],
        datasets: [
          {
            //label: '# of Votes',
            data: [(percentage_completed),(percentage_processing),(percentage_pending),(percentage_error)],
            backgroundColor: [
                'rgb(2, 197, 68)',
                'rgb(248, 222, 126)',
                'rgb(217, 217, 217)',
                'rgb(235, 96, 96)'

            ],
            borderColor: [
             'rgb(2, 197, 68)',
              'rgb(248, 222, 126)',
              'rgb(217, 217, 217)',
              'rgb(235, 96, 96)'

            ],
            borderWidth: 1,
            //borderRadius: 10
          },
        ],
      };

    const options = {
      cutout: '70%',
      responsive: true,
      maintainAspectRatio: false,
      plugins: {
          legend: {
            "display": true,
            position: "bottom",
            
            "labels": {
                font: {
                  size: 12
              },
            }
          },
          
      },
      onClick: (e, activeEls) => {
        let dataIndex = activeEls[0]?.index;
        let label = e.chart.data.labels[dataIndex];
        navigateMe(label)
      },
      onHover: (event, chartElement) => {
        let dataIndex = chartElement[0]?.index;
        if(event.chart.data.labels[dataIndex] === '% Failed'){
          event.native.target.title = 'Click to view Failed files'
          event.native.target.style.cursor = 'pointer'
        }
        else{
          event.native.target.title = ''
          event.native.target.style.cursor = 'default'
        }
        
      }
    };

    function navigateMe(label){
      //console.log(label);
      if(label === '% Failed'){
        navigate('/invoices-by-status?status=failed&invoice_list_page_no=1&invoice_csv_list_page_no=1');
      }
      
    }

    let plugin = [];

    if(percentage_completed >= 0){
        plugin = [{
          afterDraw: function(chart) {
            //chart.destroy();
            var width = chart.width,
            height = chart.height,
            ctx = chart.ctx;
            ctx.restore();
            var fontSize = (height / 300).toFixed(2);
            ctx.font = fontSize + "em sans-serif";
            ctx.textBaseline = "top";
            //ctx.textColor = "red";
            // var text = (percentage_completed)+"% Processed",
            var text = '',
            textX = Math.round((width - ctx.measureText(text).width) / 2),
            textY = height / 2;
            ctx.fillStyle = 'rgb(2, 197, 68)';
            ctx.fillText(text, textX, textY);
            ctx.save();
          } 
      }]
    }

	return (
		<>
			<h1 className="text-lg font-black text-primary">Processing files</h1>
			<p className="text-xs text-gray-500 mb-2">Total files processing now</p>
			<p className="text-lg font-black text-primary">{DashboardData?.total_files_processing}/{DashboardData?.total_files_uploaded}</p>
			<div className="pt-4">
      {DashboardData?.total_files_uploaded >0 ? (
       
          <Doughnut width={300} height={300} options={options} data={data} plugins={plugin} redraw={true}/>
        ) : (
          <div  className='min-h-[150px]'>
              No Data Found
          </div> 
        )}
			</div>
		</>
	);
}

export default UploadDonuts;