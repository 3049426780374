import { useMutation, useQuery } from '@tanstack/react-query';
import { useDispatch } from 'react-redux';
import { toast } from 'react-toastify';
import { RECORDS_PER_PAGE } from '../../config';
import { useState } from 'react';
import { buildAxiosInstance, getAccessToken } from '../axiosInstance';
import { LogoutCheck } from '../utils';
import { useMsal } from '@azure/msal-react';

export const useAddCategory = () => {
	//const Data = useSelector(state => state.auth);
	const dispatch = useDispatch();
	const { instance } = useMsal();
	return useMutation({
		mutationFn: async (body) => {
			try {

				const TOAST_ID = 'add_category'
				const axiosInstance = buildAxiosInstance(TOAST_ID)
				const responseData = await axiosInstance.post(`/add_category`, JSON.stringify(body));
				let message = responseData?.message
				message && toast.success(message, { toastId: TOAST_ID })
				return responseData

			} catch (error) {
				LogoutCheck(error, dispatch,instance)
				throw new Error(`${error.response.data.message}`);
			}
		},
	});
};

export const useAddProjectType = () => {
	//const Data = useSelector(state => state.auth);
	const dispatch = useDispatch();
	const { instance } = useMsal();
	return useMutation({
		mutationFn: async (body) => {
			try {

				const TOAST_ID = 'add_project_type'
				const axiosInstance = buildAxiosInstance(TOAST_ID)
				const responseData = await axiosInstance.post(`/add_project_type`, JSON.stringify(body));
				let message = responseData?.message
				message && toast.success(message, { toastId: TOAST_ID })
				return responseData

			} catch (error) {
				LogoutCheck(error, dispatch,instance)
				throw new Error(`${error.response.data.message}`);
			}
		},
	});
};

const fetchCategoryList = async (pageParam, Args, dispatch, signal,instance) => {
	try {
		const TOAST_ID = 'category'
		const axiosInstance = buildAxiosInstance(TOAST_ID)
		let config = {
			params:{
				search:Args.search,
				page:pageParam,
				limit:RECORDS_PER_PAGE,
				sort:Args.sort
			},
			signal: signal,
		};
		const responseData = await axiosInstance.get(`category`,config)
		return responseData

	} catch (error) {
		LogoutCheck(error, dispatch,instance)
		throw new Error(`${error.response.data.message}`);
	}
};

export const useCategoryListPagewise = (pageNo) => {
	//const Data = useSelector(state => state.auth)
	let dispatch = useDispatch();
	const { instance } = useMsal();
	const [Args, setArgs] = useState({
		search: '',
		sort: '',
		page: pageNo,
	});
	const hook = useQuery({
		queryKey: ['categorylist', Args],
		queryFn: async ({ signal }) =>
			fetchCategoryList(Args.page, Args, dispatch, signal,instance),
		retry: false,
	});
	return {
		hook: hook,
		Args: Args,
		setArgs: setArgs,
	};
};

const fetchProjectTypeList = async (pageParam, Args, dispatch, signal,instance) => {
	try {

		const TOAST_ID = 'project_type'
		const axiosInstance = buildAxiosInstance(TOAST_ID)
		let config = {
			params:{
				search:Args.search,
				page:pageParam,
				limit:RECORDS_PER_PAGE,
				sort:Args.sort
			},
			signal: signal,
		};
		const responseData = await axiosInstance.get(`project_type`,config)
		return responseData

	} catch (error) {
		LogoutCheck(error, dispatch,instance)
		throw new Error(`${error.response.data.message}`);
	}
};

export const useProjectTypeListPagewise = (pageNo) => {
	// const Data = useSelector(state => state.auth)
	let dispatch = useDispatch();
	const { instance } = useMsal();
	const [Args, setArgs] = useState(
		{
			search: '',
			sort: '',
			page: pageNo,
		},
		2000
	);
	const hook = useQuery({
		queryKey: ['projecttypelist', Args],
		queryFn: async ({ signal }) =>
			fetchProjectTypeList(Args.page, Args, dispatch, signal,instance),
		retry: false,
	});
	return {
		hook: hook,
		Args: Args,
		setArgs: setArgs,
	};
};

export const useDeleteCategory = () => {
	//const Data = useSelector(state => state.auth)
	let dispatch = useDispatch();
	const { instance } = useMsal();
	return useMutation({
		//queryKey: ['get_user_by_id',id],
		retry: false,
		mutationFn: async (id) => {
			try {

				const TOAST_ID = 'delete_category'
				const axiosInstance = buildAxiosInstance(TOAST_ID)
				let config = {
					data: JSON.stringify({ id }),
				};
				const responseData = await axiosInstance.delete(`delete_category`,config)
				return responseData

			} catch (error) {
				LogoutCheck(error, dispatch,instance)
				throw new Error(`${error.response.data.message}`);
			}
		},
	});
};

export const useDeleteProject = () => {
	//const Data = useSelector(state => state.auth)
	let dispatch = useDispatch();
	const { instance } = useMsal();
	return useMutation({
		//queryKey: ['get_user_by_id',id],
		retry: false,
		mutationFn: async (id) => {
			try {

				const TOAST_ID = 'delete_project_type'
				const axiosInstance = buildAxiosInstance(TOAST_ID)
				let config = {
					data: JSON.stringify({ id }),
				};
				const responseData = await axiosInstance.delete(`delete_project_type`,config)
				return responseData

			} catch (error) {
				LogoutCheck(error, dispatch,instance)
				throw new Error(`${error.response.data.message}`);
			}
		},
	});
};

export const useGetEditCategory = (id) => {
	//const Data = useSelector(state => state.auth)
	let dispatch = useDispatch();
	const { instance } = useMsal();
	return useQuery({
		queryKey: ['get_category_by_id', id],
		retry: false,
		queryFn: async () => {
			try {

				const TOAST_ID = 'get_category_by_id'
				const axiosInstance = buildAxiosInstance(TOAST_ID)
				const responseData = await axiosInstance.get(`get_category_by_id?id=${id}`)
				return responseData
			} catch (error) {
				LogoutCheck(error, dispatch,instance)
				throw new Error(`${error.response.data.message}`);
			}
		},
	});
};

export const useUpdateCategory = () => {
	//const Data = useSelector(state => state.auth);
	const dispatch = useDispatch();
	const { instance } = useMsal();
	return useMutation({
		mutationFn: async (body) => {
			try {

				const TOAST_ID = 'update_category'
				const axiosInstance = buildAxiosInstance(TOAST_ID)
				const responseData = await axiosInstance.put(`/update_category`, JSON.stringify(body));
				let message = responseData?.message
				message && toast.success(message, { toastId: TOAST_ID })
				return responseData

			} catch (error) {
				LogoutCheck(error, dispatch,instance)
				throw new Error(`${error.response.data.message}`);
			}
		},
	});
};

export const useUpdateProjectType = () => {
	//const Data = useSelector(state => state.auth);
	const dispatch = useDispatch();
	const { instance } = useMsal();
	return useMutation({
		mutationFn: async (body) => {
			try {

				const TOAST_ID = 'update_project_type'
				const axiosInstance = buildAxiosInstance(TOAST_ID)
				const responseData = await axiosInstance.put(`/update_project_type`, JSON.stringify(body));
				let message = responseData?.message
				message && toast.success(message, { toastId: TOAST_ID })
				return responseData

			} catch (error) {
				LogoutCheck(error, dispatch,instance)
				throw new Error(`${error.response.data.message}`);
			}
		},
	});
};

export const useGetEditProjectType = (id) => {
	//const Data = useSelector(state => state.auth)
	let dispatch = useDispatch();
	const { instance } = useMsal();
	return useQuery({
		queryKey: ['get_project_type_by_id', id],
		retry: false,
		queryFn: async () => {
			try {

				const TOAST_ID = 'get_project_type_by_id'
				const axiosInstance = buildAxiosInstance(TOAST_ID)
				const responseData = await axiosInstance.get(`get_project_type_by_id?id=${id}`)
				return responseData

			} catch (error) {
				LogoutCheck(error, dispatch,instance)
				throw new Error(`${error.response.data.message}`);
			}
		},
	});
};
