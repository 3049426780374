import React, { useState, useEffect, useRef } from 'react';
import styles from '../annotation/invoice.module.css';
import {useSendMessage,useStoreChatData} from '../annotation_contracts/hooks';
import { useLikeDislikeChat } from '../annotation_contracts/hooks';
import { createSearchParams, useNavigate } from 'react-router-dom';


function GlobalChatIndex() {
	const [inputValue, setInputValue] = useState('');
	const [chatThread, setChatThread] = useState([]);
	const [chatHistory, setChatHistory] = useState([]);
	const [likeDislikeState, setLikeDislikeState] = useState({});
	const mutation = useSendMessage();
	const chat_mutation = useStoreChatData();
	const chatContainerRef = useRef(null);
	const textareaRef = useRef(null);
	const navigate = useNavigate();
	// Scroll to the bottom of the chat container whenever a new message is added
	useEffect(() => {
	if (chatContainerRef.current) {
		chatContainerRef.current.scrollTop = chatContainerRef.current.scrollHeight;
	}
	}, [chatThread]);

	

	const handleKeyDown = (event) => {
		// If Enter is pressed without Shift, submit the message
		if (event.key === 'Enter' && !event.shiftKey) {
		  event.preventDefault(); // Prevents adding a new line
		  handleSendClick();
		}
	  };

	// Handle the send action here
	const handleSendClick = () => {

		if(inputValue.trim()){
			const queryObject = {
				query: inputValue,
				document_id: "",
				history: chatHistory // last 3 chats in the history
			};
            queryObject['global'] = true

			const messageId = Date.now();

			setChatThread((prev) => [
				...prev,
				{ id: messageId,type: 'user', message: queryObject.query },
			]);
			setInputValue(''); // Clear the input field
			const textarea = textareaRef.current;
			textarea.style.height = 'auto';
			mutation.mutate(queryObject,{
				onSuccess: (data) => {
					const resultObject = {
						inputQuery: queryObject.query,
						outputResponse: data.response,
						citationRes: data.relevant_pages,
						context: data.context,
						messageId,
					};

					setChatThread((prev) => [
						...prev,
						{ id: messageId,type: 'bot', inputQuery: resultObject.inputQuery,message: resultObject.outputResponse , citation: resultObject.citationRes }

					]);

					// Update chatHistory to keep only the last 3 pairs
					setChatHistory((prev) => {
						const updatedHistory = [
						  ...prev,
						  { user: queryObject.query, bot: resultObject.outputResponse },
						];

						// Keep only the last 3 chats
						if (updatedHistory.length > 3) {
						  return updatedHistory.slice(-3);
						} else {
						  return updatedHistory;
						}
					  });
					  // Store chat data in the database
					  storeDataInDB(resultObject);
					  
				},
				onError: (error) => {
					console.error('Error sending message:', error);
				},
			});
		}
	};

	const storeDataInDB = async (queryData) => {
		try {
			const payload = {
				userEmail:localStorage.getItem('username'),
				document_id: "",
				query: queryData.inputQuery,
				response: queryData.outputResponse,
				citation: queryData.citationRes,
				context: queryData.context
			};
			chat_mutation.mutate(payload,{
				onSuccess: (data) => {
					// console.log('Data stored successfully:', data.inserted_id);	
					setChatThread((prev) =>
						prev.map((message) => {
						  // Match message by type, inputQuery, and the unique ID
						  if (
							message.type === 'bot' &&
							message.id === queryData.messageId
						  ) {
							return {
							  ...message,
							  inserted_id: data.inserted_id, // Add the inserted_id
							};
						  }
						  return message;
						})
					);
				},
				onError: (error) => {
					console.error('Error storing data:', error);
				},
			});
		} catch (error) {
			throw error;
		}
	};

	function handleNavigation(cName, obj) {
		const encodedClientName = obj.client_name
		const encodedVendorName = obj.vendor_name
		const encodeContractName = cName

		const queryParams = createSearchParams({
			client_id: obj.client_id,
			client_name: encodedClientName,
			client_list_page_no: 1,
			client_list_sort_key: '',
			client_list_sort_order: '',
		
			vendor_id: obj.vendor_id,
			vendor_name: encodedVendorName,
			vendor_list_page_no: 1,
			vendor_list_sort_key: '',
			vendor_list_sort_order: '',
			category_name: obj.category_name,
			category_id: obj.category_id,
		
			contract_id: obj.contract_id,
			contract_name: encodeContractName,
			contract_list_page_no: 1,
			contract_list_sort_key: '',
			contract_list_sort_order: '',
		  }).toString();
		
		  const url = `/my-contract-details?${queryParams}`;
		  window.open(url, '_blank');
	}
	const handleLikeDislikeChange = (inserted_id, value) => {
		setLikeDislikeState((prevState) => ({
		  ...prevState,
		  [inserted_id]: value,
		}));
	  };
	  return (
		<>
		<div className={`${styles.chatWrapOuter} flex flex-col`}>
			<div className={`${styles.chatWrap} grow flex flex-col overflow-x-hidden overflow-y-auto px-6 py-4`} ref={chatContainerRef}>
				{chatThread.length ===0 &&
				<div className='p-0'>
					<div className='flex gap-3'>
						<div className='flex-none'>
							<span className="material-icons-outlined text-md text-slate-700">description</span>
						</div>
						<div className='grow pt-[2px]'>
							<h6 className='text-primary'>How can I get help?</h6>
						</div>
					</div>
					<p className='text-gray-600 text-md'>Welcome! You can ask any questions related to the contract by typing them in the chat box below. </p>
					<p className='text-gray-600 text-xs pt-2'><span className='font-bold'>Please note: </span>The first response might take a little longer as we're preparing the necessary resources and infrastructure to assist you. Subsequent responses will be much quicker.</p>
				</div> }
			{chatThread.map((chat, index) => (
				<div key={index} className="flex gap-2 mb-3">
					{chat.type === 'user' ? (
					<>
						<div className="grow text-sm text-right">
							<div className="mt-2">
								<label className="inline-block bg-gray-100 rounded-lg p-3 text-primary text-wrap whitespace-normal break-words text-left">
								{chat.message}
								</label>
							</div>
						</div>
						</>

						) : (
						<>
						<div className="flex-none pt-2">
							<span className="proIco small">B</span>
						</div>
						<div className="grow text-sm">
							<div className="mt-2">
								<label className="inline-block border border-gray-200 rounded-lg p-3 text-primary">
								<span dangerouslySetInnerHTML={{ __html: chat.message }} />
								</label>
							</div>
							<LikeDislike 
								inserted_id={chat.inserted_id} 
								selectedOption={likeDislikeState[chat.inserted_id]}
								onLikeDislikeChange={handleLikeDislikeChange}
								/>
							{Object.keys(chat?.citation).length ? <div className='border rounded-lg max-w-80 my-2'>
								<div className='border-t px-3 py-2 text-xs'>
								{Object.keys(chat?.citation)?.map((key) => (
									<ul key={`${key}-ul`} className='list-disc pl-3'>
										{chat.citation[key].page_no.map(page => {
											return (
												<li onClick={()=> handleNavigation(key, chat.citation[key])} key={`${page}-${key}`} className='hover:cursor-pointer relative text-primary h-5'>
													<div className='absolute w-full left-0 top-0 text-ellipsis text-nowrap overflow-hidden'>Referred from Page {page} of <span title={key}>{key}</span></div>
												</li>

											)
										})}
									</ul>
									)
								)}
								</div>
							</div> : null }
						</div>
						</>
					)}
				</div>
			))}
			</div>
			{/* Below Buttons */}
			{mutation.isPending &&
				<div className='mx-12 py-0'>
					<div className='dot-pulse'></div>
				</div>
			}
			<div className="flex-none flex gap-2 px-6 py-4 items-end">
				<div className="grow">
						<textarea
							ref={textareaRef}
							className="block border relative border-slate-300 rounded-md w-full py-2 resize-none px-3 m-0 focus:outline-blue-400 text-sm"
							placeholder="Type your question here..." value={inputValue}
							onChange={(e) => {
								setInputValue(e.target.value)
								const textarea = textareaRef.current;
								textarea.style.height = 'auto'; // Reset height
								textarea.style.height = `${textarea.scrollHeight}px`; 
								}}
							onKeyDown={handleKeyDown}
							style={{
								// maxHeight: '290px',
								overflow: 'auto',
								resize: 'none',
							}}
						></textarea>
			  </div>
			  <div className="flex-none h-full">
					<button
						type="submit"
						className="bg-blue-800 hover:bg-indigo-700 block px-4 py-2 h-full rounded-md text-white disabled:bg-gray-300 disabled:text-gray-600"
						onClick={handleSendClick}
						disabled={mutation.isPending}
					>
						Send
					</button>
				</div>
			</div>
		</div>
		</>
	);
}


function LikeDislike({inserted_id, selectedOption, onLikeDislikeChange}) {

    const mutation = useLikeDislikeChat();
    const handleLikeDislike = (value) => {
		onLikeDislikeChange(inserted_id, value);
		// Call the update like/dislike API
		updateLikeDislikeAPI(value)
	};

    const updateLikeDislikeAPI = async (value) => {
		try {
			const payload = {
				userEmail:localStorage.getItem('username'),
				isLike: value,
                inserted_id:inserted_id   
			};
			mutation.mutate(payload,{
				onSuccess: (data) => {
					// console.log('Data updated successfully:', data);
				},
				onError: (error) => {
					console.error('Error updating data:', error);
				},
			});
		} catch (error) {
			throw error;
		}
	};

	return (
		<>

            {/* ==== Like-Dislike Box ======== */}
            <div className="flex gap-2 items-end">
                <div className="flex-none pt-2">
                    <label className={`${styles.ldBtn} align-middle`}>
                        <input type='radio' name={`likeDLike-${inserted_id}`} value="true" checked={selectedOption === true} onChange={() => handleLikeDislike(true)}/>
                        <span className={styles.ld}></span>
                    </label>
                    <label className={`${styles.ldBtn} align-middle`}>
                        <input type='radio' name={`likeDLike-${inserted_id}`} value="false" checked={selectedOption === false} onChange={() => handleLikeDislike(false)}/>
                        <span className={`${styles.ld} ${styles.dis}`}></span>
                    </label>
                    <label className='align-middle'>Confirmed this as correct</label>
                </div>                
            </div>
		</>
	);
}



export default GlobalChatIndex;

