import React from 'react';
/* import Highcharts from 'highcharts'
import HighchartsReact from 'highcharts-react-official' */
import { Doughnut } from 'react-chartjs-2';
import { useNavigate } from "react-router-dom";

import { Chart as ChartJS, ArcElement, Tooltip, Legend } from 'chart.js';
//import { Pie } from 'react-chartjs-2';
ChartJS.register(ArcElement, Tooltip, Legend);
function DataFielddDonuts({FieldData,dataFromChild}) {
  let navigate = useNavigate(); 
    //console.log(dataFromChild);
    
    const percentage_completed = ((FieldData?.total_data_field/FieldData?.total_invoice_uploaded)*100).toFixed(2);
    const percentage_pending = (100-percentage_completed).toFixed(2);
    const data = {
        labels: ['% Used','% Pending' ],
        datasets: [
          {
            data: [(percentage_completed),(percentage_pending)],
            backgroundColor: [
              'rgb(26, 156, 244)',
              'rgb(217, 217, 217)',
              

            ],
            borderColor: [
              'rgb(26, 156, 244)',
              'rgb(217, 217, 217)',

            ],
            borderWidth: 1,
          },
        ],
      };

    const options = {
      cutout: '65%',
      responsive: true,
      maintainAspectRatio: false,
      plugins: {
          legend: {
              display: false
          },
          
      },
      onClick: (e, activeEls) => {
        let dataIndex = activeEls[0]?.index;
        let label = e.chart.data.labels[dataIndex];
        navigateMe(label)
      },
      onHover: (event, chartElement) => {
        let dataIndex = chartElement[0]?.index;
        if(event.chart.data.labels[dataIndex] === '% Pending'){
          event.native.target.title = 'Click to view'
          event.native.target.style.cursor = 'pointer'
        }
        else{
          event.native.target.title = ''
          event.native.target.style.cursor = 'default'
        }
        
      }
    };

    function navigateMe(label){
      //console.log(label);
      if(label === '% Pending'){
        navigate('/data-field-listing?analyst='+dataFromChild.analyst+'&batch='+dataFromChild.batch+'&category='+dataFromChild.category+'&client='+dataFromChild.client+'&datefrom='+dataFromChild.datefrom+'&dateto='+dataFromChild.dateto+'&field='+dataFromChild.field+'&invoice='+dataFromChild.invoice+'&invoice_status='+dataFromChild.invoice_status+'&vendor='+dataFromChild.vendor+'');
      }
      
    }

    let plugin = [];

    if(percentage_completed >= 0){
        plugin = [{
          afterDraw: function(chart) {
            //chart.destroy();
            var width = chart.width,
            height = chart.height,
            ctx = chart.ctx;
            ctx.restore();
            var fontSize = (height / 300).toFixed(2);
            ctx.font = fontSize + "em sans-serif";
            ctx.textBaseline = "top";
            var text = '',
            textX = Math.round((width - ctx.measureText(text).width) / 2),
            textY = height / 2;
            ctx.fillStyle = 'rgb(26, 156, 244)';
            ctx.fillText(text, textX, textY);
            ctx.save();
          } 
      }]
    }

	return (
		<>
			<h1 className="text-lg font-black text-primary">Data field frequency</h1>
			<p className="text-xs text-gray-500 mb-2">Total fields used across all invoices</p>
			<div className="pt-4">
      {FieldData?.total_invoice_uploaded > 0 ? (
        <Doughnut width={300} height={300} options={options} data={data} plugins={plugin} redraw={true}/>
      ) : (
        <div  className='min-h-[150px]'>
            No Data Found
        </div> 
      )}
        
			</div>
		</>
	);
}

export default DataFielddDonuts;