import { Link, Outlet, useSearchParams } from 'react-router-dom';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import MenuItem from '@mui/material/MenuItem';
import Menu from '@mui/material/Menu';
import { DateTime } from "luxon"
import React, { useState, useEffect, useCallback, useRef } from 'react';
import Checkbox from '@mui/material/Checkbox';
import { useContractlistPagewise, useDeleteContract  } from './hooks';
import Pagination from '@mui/material/Pagination';
import Stack from '@mui/material/Stack';
import { RECORDS_PER_PAGE } from '../../config';
import LoaderFull from '../shared/loader-full-screen';
import { useDebouncedState } from '@mantine/hooks';
import { decodeName, encodeName, handleSearchKeys, handleSortUtil ,  } from '../utils';
import { useCategoryNameById } from '../list_invoices/hooks';
const SORT_KEY_CONST = 'contract_list_sort_key'
const SORT_ORDER_CONST = 'contract_list_sort_order'
const PAGE_NO_CONST = 'contract_list_page_no'
const CLIENT_ID = 'client_id'
const VENDOR_ID = 'vendor_id'
const CATEGORY_NAME = 'category_name'
const CATEGORY_ID = 'category_id'

function ListContractsIndex() {
	const [searchParams, setSearchParams] = useSearchParams();

	const [anchorEl, setAnchorEl] = React.useState(null);
	const open = Boolean(anchorEl);
	const handleClickListItem = (event) => {
		setAnchorEl(event.currentTarget);
	};
	const delete_contract_hook = useDeleteContract()
	const [selectedContracts, setselectedContracts] = useState(new Set([]))
	const handleClose = () => {setAnchorEl(null);};

	const [DebounceSearch, setDebounceSearch] = useDebouncedState('', 500);

	const isFirstRender = useRef(true)
	const [uatHeader, setuatHeader] = useState(false)
	
	const [Sort, setSort] = useState(handleSearchKeys(searchParams,SORT_KEY_CONST,SORT_ORDER_CONST,PAGE_NO_CONST,CLIENT_ID, VENDOR_ID ,CATEGORY_NAME, CATEGORY_ID ));	
	
	const {hook:contracts_list_hook , setArgs } = useContractlistPagewise(Sort)
	const catname_hook = useCategoryNameById(searchParams.get(CATEGORY_ID))

	function handleSort(typer) {
		let {sort_key ,sort_order} = handleSortUtil(typer , Sort)
		searchParams.set(SORT_KEY_CONST, sort_key)
		searchParams.set(SORT_ORDER_CONST, sort_order)
		setSearchParams(searchParams)

	}

	useEffect(() => {
		if (!isFirstRender.current) {
			let newSort = handleSearchKeys(searchParams, SORT_KEY_CONST, SORT_ORDER_CONST, PAGE_NO_CONST)
			setSort(newSort)
			setArgs((p) => {
				return {
					...p,
					...newSort
				}
			})
		}
		isFirstRender.current = false


	}, [searchParams, setArgs])




	const renderList = useCallback(() => {
		if (contracts_list_hook.isFetching || delete_contract_hook.isPending ) {
			return <LoaderFull />;
		} else if (contracts_list_hook.data?.data?.length === 0) {
			return <p className="text-center">No data found</p>;
		} else {
			return (
				<Outlet
					context={{
						contractList: contracts_list_hook.data,
						Search: DebounceSearch,
						selectedContracts: selectedContracts,
						setselectedContracts: setselectedContracts,
						setuatHeader: setuatHeader
					}}
				/>
			);
		}
	}, [contracts_list_hook, DebounceSearch, selectedContracts, delete_contract_hook.isPending]);



	

	function handleCheckAll(e) {
		
		
		if (e.target.checked) {
			let lp = contracts_list_hook?.data?.data.map(itm => {
				if (itm.master.csv_upload ) {
					return []
				}
				let ids =[ itm.master._id ]
				ids.push(itm.child.map(childItms => childItms._id))
				return [...ids]
			}).flat(2)
			
			setselectedContracts(new Set(lp))
		}
		else{
			setselectedContracts(new Set([]))
		}
	}

	function deleteInvClick() {
		let body = { 
			document_ids :Array.from(selectedContracts)
		}
		delete_contract_hook.mutate(body)
	}

	useEffect(() => {
	  if (delete_contract_hook.isSuccess) { 
		setselectedContracts(new Set([]))
	  }
	}, [delete_contract_hook.isSuccess])

	
	
	
	return (
		<>
			<div className="grow h-screen overflow-x-hidden overflow-y-auto fontInter">
				<>
					<div className="lg:flex gap-4 justify-between items-center vLBG py-4 px-6">
						<div className="flex-none">
							<h1 className="text-lg font-normal text-primary">
								List of <strong className="font-black">Contracts</strong>
							</h1>
							<p className="text-xs text-gray-500 mb-2">
							<Link to={`/my-clients?client_list_page_no=${searchParams.get('client_list_page_no')}&client_list_sort_key=${searchParams.get('client_list_sort_key')}&client_list_sort_order=${searchParams.get('client_list_sort_order')}`}>My Clients</Link> &nbsp; {'>'} &nbsp;
							<Link
									to={`/my-vendors?client_id=${searchParams.get('client_id')}&client_name=${encodeName(decodeName((searchParams.get('client_name'))))}&client_list_page_no=${searchParams.get('client_list_page_no')}&client_list_sort_key=${searchParams.get('client_list_sort_key')}&client_list_sort_order=${searchParams.get('client_list_sort_order')}&vendor_list_page_no=${searchParams.get('vendor_list_page_no')}&vendor_list_sort_key=${searchParams.get('vendor_list_sort_key')}&vendor_list_sort_order=${searchParams.get('vendor_list_sort_order')}`}
								>
									Client - <span title={decodeName(searchParams.get('client_name'))}>{decodeName(searchParams.get('client_name'))}</span>
								</Link>{' '}
								&nbsp; {'>'} &nbsp;
								
								Vendor - <span title={decodeName(searchParams.get('vendor_name'))}>{decodeName(searchParams.get('vendor_name'))}</span> ( { catname_hook.data ? catname_hook.data?.name: '..' } )
							</p>
						</div>
						<div className="flex gap-3 flex-wrap">
							<div className="flex gap-2">
								<div className="relative">
									<span className="material-icons absolute top-2 left-2 z-10 text-slate-400 text-md">
										search
									</span>
									<input
										type="text"
										onChange={(e) => {
											setDebounceSearch(()=> {
												setArgs((p) => {
													return { ...p, search: e.target.value, page: 1, sort: '' };
												});
												return e.target.value
											});
										}}
										className="border relative border-slate-300 rounded-md w-full h-10 py-2 pr-2 pl-10 focus:outline-blue-400 text-sm"
										placeholder="Search..."
									/>
								</div>
							</div>
							
							<div>
								<List component="nav" aria-label="Export">
									<ListItem
										id="export-button"
										aria-haspopup="listbox"
										aria-controls="export-menu"
										aria-expanded={open ? 'true' : undefined}
										onClick={handleClickListItem}
										className='text-white font-bold text-sm h-[40px]'
									>
										Actions 
										<span className="material-icons text-white text-sm ml-1">
											expand_more
										</span>
									</ListItem>
								</List>
								<Menu
									id="export-menu"
									className='expMenu'
									anchorEl={anchorEl}
									open={open}
									onClose={handleClose}
									MenuListProps={{
										'aria-labelledby': 'export-button',
										role: 'listbox',
									}}
								>
									
									<MenuItem disabled={delete_contract_hook.isPending || selectedContracts.size ===0} onClick={deleteInvClick}>
										<span className="material-icons text-sm mr-3">
											delete
										</span>
										Delete
									</MenuItem>
								</Menu>

							</div>
						</div>
					</div>
				</>

				<>
					<div className="p-6">
						<div className="listDHeight larger overflow-auto">
							<div className="flex gap-2 mb-2 items-center pr-4">
								<div className="flex-none w-[40px]">
									<Checkbox disabled={contracts_list_hook.isFetching} checked={selectedContracts.size === contracts_list_hook?.data?.total_contracts && selectedContracts.size !==0 } onChange={(e)=> handleCheckAll(e)} size="small" />
									<div className="text-[9px] text-center">Select All</div>
								</div>
								<div className="flex-none w-[55px]">
									<div className="flex-none text-center relative w-[55px]">
									<span title='default sort' onClick={() => handleSort('')} className={`${Sort.type === '' ? 'text-primary' : 'cursor-pointer'}   material-icons w-4 text-sm mt-1`}>											swap_vert
										</span>
									</div>
								</div>
								<div className="grow px-2 py-1 flex items-center justify-between text-xs ml-5">
									<div className="grow pr-4">
										<label
											onClick={() => handleSort('fname')}
											className={`${Sort.type === 'fname' ? 'text-primary' : ''} block font-black relative w-[150px] cursor-pointer`}
										>
											Name of Contract
											<span className="material-icons absolute w-4 top-0 right-0 text-sm ml-1 mt-[-2px]">
												swap_vert
											</span>
										</label>
									</div>
									<div className="flex-none px-4 text-center w-[160px] ml-2">
										<label
											className={`font-black block relative w-[120px] cursor-default`}
										>
											Uploaded By
										</label>
									</div>
									<div className="flex-none px-4 text-center w-[140px]">
										<label
											className={`font-black block relative w-[148px] cursor-default`}
										>
											Status
										</label>
									</div>
									<div className="flex-none px-4 text-center w-[160px]">
										<label
											onClick={() => handleSort('uploaded_at')}
											className={`${Sort.type === 'uploaded_at' ? 'text-primary' : ''} font-black block relative w-[120px] cursor-pointer`}
										>
											Date Uploaded {DateTime.now().toFormat("ZZZZ")}
											<span className="material-icons absolute w-4 top-0 right-0 text-sm ml-1 mt-[-2px]">
												swap_vert
											</span>
										</label>
									</div>
									<div className="flex-none px-4 text-center w-[160px]">
										<label
											onClick={() => handleSort('updated_at')}
											className={`${Sort.type === 'updated_at' ? 'text-primary' : ''} font-black block relative w-[120px] cursor-pointer`}
										>
											Date Updated {DateTime.now().toFormat("ZZZZ")}
											<span className="material-icons absolute w-4 top-0 right-0 text-sm ml-1 mt-[-2px]">
												swap_vert
											</span>
										</label>
									</div>
									<div className="flex-none px-4 text-center w-[160px]">
										<label
											onClick={() => handleSort('start_date')}
											className={`${Sort.type === 'start_date' ? 'text-primary' : ''} font-black block relative w-[120px] cursor-pointer`}
										>
											Contract Generated Date {DateTime.now().toFormat("ZZZZ")}
											<span className="material-icons absolute w-4 top-0 right-0 text-sm ml-1 mt-[5px]">
												swap_vert
											</span>
										</label>
									</div>
								
									<div className="flex-none px-4 text-center w-[150px]">
									<label
											onClick={() => handleSort('percentage_complete')}
											className={`${Sort.type === 'percentage_complete' ? 'text-primary' : ''} font-black block relative w-[120px] cursor-pointer`}
										>
											% Processing Complete
											<span className="material-icons absolute w-4 top-0 right-0 text-sm ml-1 mt-[5px]">
												swap_vert
											</span>
										</label>
									</div>
									
								</div>
								<div className="flex-none w-[170px] pr-2">&nbsp;</div>
							</div>

							{renderList()}
						</div>
						<div className="flex justify-center gap-3 pt-2">
							{!contracts_list_hook.isLoading && (
								<Stack spacing={2}>
									<Pagination
										page={Sort.page}
										onChange={(e, value) => {
											searchParams.set(PAGE_NO_CONST, value)
											setSearchParams(searchParams)
										}}
										count={Math.ceil(
											contracts_list_hook.data?.total_contracts /
												RECORDS_PER_PAGE
										)}
										color="primary"
										variant="outlined"
										shape="rounded"
									/>
								</Stack>
							)}
						</div>
					</div>
				</>
			</div>
		</>
	);
}

export default ListContractsIndex;
