import React, { useEffect, useState } from 'react';
import TopComboNButtons from './topComboNButtons';
import FieldsNTabularFields from './fieldsNTabularFields';
import { useGetRules, useUpdateRules } from './hooks';
import LoaderFull from '../shared/loader-full-screen';
import swal from 'sweetalert';



const CategoryFieldsManageIndex = () => {  
    
    const [query, setquery] = useState({})
    const get_rules_hook = useGetRules()
    const update_rules = useUpdateRules()
    const [Rules, setRules] = useState(null)
    const [loadingData, setloadingData] = useState(false)
    const [isGlobalFetch, setisGlobalFetch] = useState(null)
    const [selectedSet, setselectedSet] = useState(new Set())
    const [tryTodelete, settryTodelete] = useState(false)
    const [GlobalRules, setGlobalRules] = useState(null)

    useEffect(() => {
      console.log(get_rules_hook.data);
      if (get_rules_hook.data?.data) { 
        setloadingData(true)
        setisGlobalFetch(get_rules_hook.data?.data.category_id ==="0000" && get_rules_hook.data?.data.vendor_id ==="0000" ? true : false  )
        let arr = get_rules_hook.data?.data.rules
        arr.sort((a, b) => a.entity_name.localeCompare(b.entity_name));
        setRules(arr.map(itm => {
            itm.checked = false
            return itm
        }))
        setloadingData(false)
      }
      else{
        setRules(null)
      }

      
    }, [get_rules_hook.data])

    function deleteSelected() {
      swal('Are you sure you want to Delete?', {
          buttons: ['No', 'Yes'],
          disabled: {tryTodelete}
      }).then((value) => {
          if (value) {
              settryTodelete(true)

              // setRules(p => {
              //     return p.filter(itm => !(itm.entity_name.toLocaleLowerCase() in selectedSet))
              // })
          }
      });
  }

      const get_global_rules_hook = useGetRules(true)
     
  
      useEffect(() => {
        console.log(get_global_rules_hook.data);
        if (get_global_rules_hook.data?.data) { 
          let arr = get_global_rules_hook.data?.data.rules
          arr.sort((a, b) => a.entity_name.localeCompare(b.entity_name));
          setGlobalRules(arr.map(itm => {
              return itm
          }))
        }
        else{
          setGlobalRules(null)
        }
  
        
      }, [get_global_rules_hook.data])

      useEffect(() => {
        get_global_rules_hook.mutate({ global : true })
      }, [])
      
    




    



    
    



    return (
        <>
            <TopComboNButtons deleteSelected={deleteSelected} setselectedSet={setselectedSet} tryTodelete={tryTodelete} settryTodelete={settryTodelete} Rules={Rules} update_rules={update_rules} setisGlobalFetch={setisGlobalFetch} isGlobalFetch={isGlobalFetch} setRules={setRules} query={query} setquery={setquery} get_rules_hook={get_rules_hook} />
            {/* {!query?.vendor_alias_id || !query?.category_id ? <p>Select filters to get rules</p> :  */}
            <>
            { update_rules.isPending || get_rules_hook.isPending || loadingData ? <LoaderFull /> : 
            <>
            {Rules && <FieldsNTabularFields GlobalRules={GlobalRules} setselectedSet={setselectedSet} selectedSet={selectedSet} tryTodelete={tryTodelete} settryTodelete={settryTodelete} get_rules_hook={get_rules_hook} setRules={setRules} Rules={Rules} /> }
            </>
            }
            </>
            {/* } */}
        </>
    )
}

export default CategoryFieldsManageIndex
