import React, { useState, useEffect } from 'react';
import { toast } from 'react-toastify';
import swal from 'sweetalert';
import { Autocomplete, TextField } from '@mui/material';
import { SelectTimezone } from "reactjs-timezone-select";
import { useMutation } from '@tanstack/react-query';
import { buildAxiosInstance } from '../axiosInstance';
import { useDispatch, useSelector } from 'react-redux';
import { timezone_set } from '../auth/authSlice';
import { DateTime } from "luxon";


 const TimeZoneConverter = ({ settitleDate, setuatHeader, utcTimestamp }) => {
  const [timeZone, setTimeZone] = useState(null);
  const [localTime, setLocalTime] = useState(null);
  const [error, setError] = useState(null);
  const Data = useSelector(p=> p.auth)


  useEffect(() => {
    const getTimeZone = (utcTimestamp) => {
      let date =  utcTimestamp.charAt(utcTimestamp.length-1) ==='Z' ? utcTimestamp : utcTimestamp + 'Z'
      const localTime = new Date(date).toLocaleString("en-US", {
        timeZone: DateTime.now().zoneName,
        month: "2-digit",
        day: "2-digit",
        year: "numeric",
        hour: "2-digit",
        minute: "2-digit",
        hour12: true,
      });
      setLocalTime(localTime);
      if (settitleDate) {
        settitleDate(localTime)
      }
  
    };
    getTimeZone(utcTimestamp)
  }, [utcTimestamp])
  

  

  return (
    <div>

      <div>
        {/* <p>Your time zone: {timeZone}</p>
          <p>UTC Timestamp: {utcTimestamp}</p> */}
        {localTime &&  <p>{localTime}</p> }
      </div>
    </div>
  );
};

// export default TimeZoneConverter;




export const TimeZonePopup = ({settz}) => {
  const Data = useSelector(p=> p.auth)
  console.log(Data);
  
  
  const [value, setValue] = useState(Data?.timezone);
  const dispatch = useDispatch()

  const hook = useMutation({
    mutationKey : 'timezone_save', 
    mutationFn: async (body) => {
          try {
            const TOAST_ID = 'timezone_save'
            const axiosInstance = buildAxiosInstance(TOAST_ID)
            const responseData = await axiosInstance.post('/save_timezone',body)
            let message = responseData?.message
            message && toast.success(message , {toastId: TOAST_ID})
            return responseData
          } catch (error) {
            throw error
          }
        },
        onSuccess: (data, body) => {
          console.log(body, "here");
          
          dispatch(timezone_set({timezone : body.timezone}));
          if(settz) { 
            settz(false)
          }
        },
  })


  const customStyles = {
    option: (provided, state) => ({
      ...provided,
      borderBottom: "1px dotted pink",
      color: state.isSelected ? "red" : "blue",
      padding: 20,
    }),
  };

  function saveTimezone(tz) {
    //save timezone to redux and to db 
    hook.mutate({timezone : tz})

  }
  useEffect(() => {
    if (!value) {
      setValue(document.querySelector("[name='Custom timezone']")?.value || "");  
    }
  }, [value]);

  if (Data.timezone && !settz) return 


  return (
    <div className="justify-center items-center flex overflow-x-hidden overflow-y-auto fixed inset-0 z-50 outline-none focus:outline-none">
      <div className="relative my-6 mx-auto w-auto lg:w-[800px]">
        {/*content*/}
        <div className="border-0 rounded-lg shadow-lg relative flex flex-col w-full bg-white outline-none focus:outline-none z-[999]">
          {/*header*/}
          <div className="p-5 rounded-t">
            <h3 className="text-2xl m-0 font-semibold text-primary text-center">
              Select Timezone
            </h3>
            {settz && <button
                className="bg-transparent border-0 text-slate-400 leading-none font-semibold outline-none focus:outline-none absolute top-4 right-5"
                onClick={() => settz(false)}
                title="Close"
            >
                <span className="material-icons-outlined modalClose">
                    cancel
                </span>
            </button> }

          </div>
          {/*body*/}
          <div className="relative pb-8 px-6 lg:w-[400px] mx-auto">
            <div className="py-2">

              <div className='mb-2'>
                <div className='flex'>

                  <div className='grow'>

                    <SelectTimezone
                      name="Custom timezone"
                      // label="Select Timezone"
                      value={value}
                      onChange={({ label, value }) => setValue(value)}
                      containerStyles={{ width: 300 }}
                      labelStyles={{ color: "red" }}
                      selectStyles={customStyles}
                      optionLabelFormat={(timezone) =>
                        `${timezone.name} - ${timezone.abbreviation}`
                      }
                      defaultToSystemTimezone
                    />


                  </div>

                </div>

              </div>

              <div className="pt-2 flex gap-4">
                <button
                  type="button"
                  disabled={hook.isPending}
                  onClick={() => saveTimezone(value)}
                  className="bg-blue-800 hover:bg-indigo-700 px-8 py-3 rounded-md text-white text-sm flex w-full items-center justify-center font-black disabled:bg-gray-400"
                >
                  Confirm
                </button>
                {!settz &&<button
                  type="button"
                  disabled={hook.isPending}
                  onClick={() => saveTimezone('UTC')}
                  className="bg-red-800 hover:bg-red-700 px-8 py-3 rounded-md text-white text-sm flex w-full items-center justify-center font-black disabled:bg-gray-400"
                >
                  Cancel
                </button> }
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>


  );
};


export default TimeZoneConverter