import React from 'react';
import Accordion from '@mui/material/Accordion';
import AccordionSummary from '@mui/material/AccordionSummary';
import AccordionDetails from '@mui/material/AccordionDetails';
import Typography from '@mui/material/Typography';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';


import FieldList from './fields/fieldList';
import TableList from './tables/tableList';

function RightSideInvAcc({
	fieldsExtracted,
	setfieldsExtracted,
	tablesExtracted,
	settablesExtracted,
	Boxes,
	setBoxes,
	UploadInvDesc,
	SaveAsDraft,
	totalTablesExtracted,
	settotalTablesExtracted,
	totalFieldsExtracted,
	settotalFieldsExtracted,
	setPageNo,
	type,
	settype,
	FlatBoxes,
	setFlatBoxes,
	PageNo,
	upload_inv_desc,
	save_as_draft,
	errorPages,
	seterrorSelected,
	errorSelected,
	Lables,
	setchangeDetect,
	setchangeAdd,
	DocLockedForMe,
	MismatchIds,
	gotoMismatch,
	CSVSaveAsDraft,
	CSVUploadInvDesc,
	IsCSV,
	setchangePage
}) {


	const handleChange = (panel) => (event, isExpanded) => {
		settype(isExpanded ? panel : false);

	};


	
	




	return (
		<>
			{fieldsExtracted == null ? (
				<p>...</p>
			) : (
				<>

					<div className="invoiceAcc grow flex flex-col">
						<Accordion
							expanded={type === 'extracted_entities'}
							onChange={handleChange('extracted_entities')}
						>
							<AccordionSummary expandIcon={<ExpandMoreIcon />}>
								<Typography className="flex items-center">
									<span className="material-icons text-black text-sm mr-3">
										notes
									</span>
									Fields
								</Typography>
							</AccordionSummary>
							<AccordionDetails>
								<FieldList
									fieldsExtracted={fieldsExtracted}
									setfieldsExtracted={setfieldsExtracted}
									setBoxes={setBoxes}
									Lables={Lables}
									setchangeDetect={setchangeDetect}
									setchangeAdd={setchangeAdd}
									DocLockedForMe={DocLockedForMe}
									MismatchIds={MismatchIds}
									PageNo={PageNo}
									gotoMismatch={gotoMismatch}
									IsCSV={IsCSV}
									setchangePage={setchangePage}
								/>
							</AccordionDetails>
						</Accordion>
						<Accordion
							expanded={type === 'tables'}
							onChange={handleChange('tables')}
						>
							<AccordionSummary expandIcon={<ExpandMoreIcon />}>
								<Typography className="flex items-center">
									<span className="material-icons text-black text-sm mr-3">
										grid_4x4
									</span>
									Tables
								</Typography>
							</AccordionSummary>
							<AccordionDetails>
								<TableList
									tablesExtracted={tablesExtracted}
									settablesExtracted={settablesExtracted}
									setBoxes={setBoxes}
									Lables={Lables}
									setchangeDetect={setchangeDetect}
									setchangeAdd={setchangeAdd}
									DocLockedForMe={DocLockedForMe}
									MismatchIds={MismatchIds}
									PageNo={PageNo}
									gotoMismatch={gotoMismatch}
									IsCSV={IsCSV}
									setchangePage={setchangePage}
								/>
							</AccordionDetails>
						</Accordion>
					
					</div>
				</>
			)}
		</>
	);
}

export default RightSideInvAcc;
