import './App.css';
import {
	BrowserRouter as Router,
	Routes,
	Route,
	Navigate,
} from 'react-router-dom';
import LogIn from './components/auth/login';
//import SignUp from './components/auth/signup'
//import ForgotPassword from './components/auth/forgot-password'
import Dashboard from './components/dashboard/dashIndex';

import UploadDocsIndex from './components/upload-docs/uploadIndex';
import NotificationIndex from './components/notifications/notificationIndex';
import InvoicesIndex from './components/annotation/invoiceIndex';
// import { useSelector } from "react-redux"
import { TopLayout } from './components/shared/topLayout';
import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import ListClientsIndex from './components/list_clients/listclientIndex';
import ListVendorsIndex from './components/list_vendors/listvendorsIndex';
import ListInvoicesIndex from './components/list_invoices/listinvoicesIndex';
import ListInvoicesIndexByStatus from './components/list_invoices_by_status/listinvoicesIndex';
import ListDataFieldIndex from './components/list_data_field/listdatafieldIndex'
import {FieldsListingData} from './components/list_data_field/listingData'
import ProfileIndex from './components/profile/profileIndex';
//import { useSelector } from 'react-redux';
import { HelpIndex } from './components/help/helpIndex';
import { ClientListingData } from './components/list_clients/listingData';
import { VendorListingData } from './components/list_vendors/listingData';
import { MsalProvider, useIsAuthenticated } from '@azure/msal-react';
import { InvoicesListingData } from './components/list_invoices/listingData';
import { InvoicesListingDataByStatus } from './components/list_invoices_by_status/listingData';
import { APP_ROUTE_ALLOWED } from './config';
import ManageUsersIndex from './components/manage-users';
import AddUser from './components/manage-users/add-user';
import EditUser from './components/manage-users/edit-user';
import ManageFieldsIndex from './components/manage-fields';
import AddCategory from './components/manage-fields/add-category';
import AddProjectType from './components/manage-fields/add-project-type';
import EditCategory from './components/manage-fields/edit-category';
import EditProjectType from './components/manage-fields/edit-project-type';
import { useSelector } from 'react-redux';
import ListContractsIndex from './components/list_contracts/listcontractsIndex';
import { ContractsListingData } from './components/list_contracts/listingData';
import ContractIndex from './components/annotation_contracts/contractIndex';
import ComparisonIndex from './components/comparison/compIndex';
import ListMismatchsIndex from './components/list_mismatches/listmismatchIndex';
import { MismatchListingData } from './components/list_mismatches/listingData';

import ListFinalMismatchsIndex from './components/list_final_mismatches/listmismatchIndex';
import { MismatchFinalListingData } from './components/list_final_mismatches/listingData';
import ListMismatchesIndexByStatus from './components/list_mismatched_by_status/listmismatchesIndex'
import{ MismatchesListingDataByStatus } from './components/list_mismatched_by_status/listingData'

import ListDataValidationIndex from './components/list_data_validation/listdatavalidationIndex'
import{ DataValidationListingData } from './components/list_data_validation/listingData'

import ManageValidateIndex from './components/manage-validations/manageValidateIndex';

import Health from './components/health/healthindex';
import ConsolidatedPricingView from './components/pricing_tables/pricingIndex';
import { useEffect } from 'react';

import ListUnmappedPriceIndex from './components/list_unmapped_price/listunmappedpriceIndex'
import {UnmappedPriceListingData} from './components/list_unmapped_price/listingData'

function App({ msalInstance }) {
	// const Data = useSelector(state => state.auth)
	// const isAuthenticated = useIsAuthenticated();
	const PrivilagesList = useSelector(
		(state) => state?.privilageSlice?.allowedActions
	);


	return (
		<MsalProvider instance={msalInstance}>
			<div className="App">
				<Router>
					<Routes>
						<Route
							path="/"
							element={
								localStorage.isSignedIn ? (
									<Navigate to="/dashboard" />
								) : (
									<LogIn />
								)
							}
						/>
						<Route path="/healthcheck" element={<Health />} />

						<Route element={<PrivateRoutes />}>
							<Route path="dashboard" element={<Dashboard />} />

							<Route element={<ListClientsIndex />}>
								<Route path="my-clients" element={<ClientListingData />} />
							</Route>

							<Route element={<ListVendorsIndex />}>
								<Route path="my-vendors" element={<VendorListingData />} />
							</Route>

							<Route element={<ListInvoicesIndex />}>
								<Route path="my-invoices" element={<InvoicesListingData />} />
							</Route>
							<Route element={<ListInvoicesIndexByStatus />}>
								<Route path="invoices-by-status" element={<InvoicesListingDataByStatus />} />
							</Route>

							<Route element={<ListDataFieldIndex />}>
								<Route path="data-field-listing" element={<FieldsListingData />} />
							</Route>

							<Route element={<ListUnmappedPriceIndex />}>
								<Route path="unmapped-price-item-listing" element={<UnmappedPriceListingData />} />
							</Route>

							<Route element={<ListMismatchesIndexByStatus />}>
								<Route path="mismatches-by-status" element={<MismatchesListingDataByStatus />} />
							</Route>

							<Route element={<ListContractsIndex />}>
								<Route path="my-contracts" element={<ContractsListingData />} />
							</Route>

							<Route element={<ComparisonIndex />}>
								<Route path="comparison" element={<ComparisonIndex />} />
							</Route>


							{PrivilagesList.includes('upload_document') ? (
								<Route path="upload-docs" element={<UploadDocsIndex />} />
							) : (
								<Route
									path="upload-docs"
									element={
										localStorage.isSignedIn ? (
											<Navigate to="/upload-docs" />
										) : (
											<LogIn />
										)
									}
								/>
							)}

							{PrivilagesList.includes('create_user') ? (
								<Route path="manage-users" element={<ManageUsersIndex />} />
							) : <Route
								path="manage-users"
								element={
									localStorage.isSignedIn ? (
										<Navigate to="/manage-users" />
									) : (
										<LogIn />
									)
								}
							/>}

							{PrivilagesList.includes('create_user') ? (
								<Route path="add-user" element={<AddUser />} />
							) : <Route
								path="add-user"
								element={
									localStorage.isSignedIn ? (
										<Navigate to="/add-user" />
									) : (
										<LogIn />
									)
								}
							/>}

							{PrivilagesList.includes('create_user') ? (
								<Route path="edit-user/:id" element={<EditUser />} />
							) : <Route
								path="edit-user/:id"
								element={
									localStorage.isSignedIn ? (
										<Navigate to="/edit-user/:id" />
									) : (
										<LogIn />
									)
								}
							/>}

							{PrivilagesList.includes('manage_expense_category') ||
								PrivilagesList.includes('manage_project_type') ? (
								<Route path="manage-fields" element={<ManageFieldsIndex />} />
							) : <Route
								path="manage-fields"
								element={
									localStorage.isSignedIn ? (
										<Navigate to="/manage-fields" />
									) : (
										<LogIn />
									)
								}
							/>}

							<Route path="add-category" element={<AddCategory />} />

							<Route path="add-project-type" element={<AddProjectType />} />

							<Route path="edit-category/:id" element={<EditCategory />} />

							<Route
								path="edit-project-type/:id"
								element={<EditProjectType />}
							/>

							{APP_ROUTE_ALLOWED['my-annotations'] ? (
								<Route path="my-annotations" element={<InvoicesIndex />} />
							) : null}
							{APP_ROUTE_ALLOWED['my-annotations'] ? (
								<Route path="my-contract-details" element={<ContractIndex />} />
							) : null}


							{APP_ROUTE_ALLOWED['notifications'] ? (
								<Route path="notifications" element={<NotificationIndex />} />
							) : null}

							<Route path="profile" element={<ProfileIndex />} />

							<Route path="help" element={<HelpIndex />} />

							<Route element={<ListMismatchsIndex />}>
								<Route path="mismatch-list" element={<MismatchListingData />} />
							</Route>

							<Route element={<ListFinalMismatchsIndex />}>
								<Route path="final-mismatch-list" element={<MismatchFinalListingData />} />
							</Route>

							<Route element={<ListDataValidationIndex />}>
								<Route path="data-validation-error-listing" element={<DataValidationListingData />} />
							</Route>
							

							{PrivilagesList.includes('change_rules') ? <Route element={<ManageValidateIndex />}>
								<Route path="manage-validations" element={<ManageValidateIndex />} />
							</Route> : <Route
								path="edit-user/:id"
								element={
									localStorage.isSignedIn ? (
										<Navigate to="/edit-user/:id" />
									) : (
										<LogIn />
									)
								}
							/>}
							

							{APP_ROUTE_ALLOWED['my-annotations'] ? (
								<Route path="my-contract-pricing" element={<ConsolidatedPricingView />} />
							) : null}

						</Route>
					</Routes>
				</Router>
				<ToastContainer limit={1} theme="colored" />
			</div>
		</MsalProvider>
	);
}

const PrivateRoutes = () => {
	return localStorage.isSignedIn ? <TopLayout /> : <Navigate to={'/'} />;
};

export default App;