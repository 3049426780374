import { useMutation, useQuery } from '@tanstack/react-query';
import axios from 'axios';
import { useDispatch } from 'react-redux';
import { BACKEND_URL } from '../../config';
import { toast } from 'react-toastify';
import { LogoutCheck } from '../utils';
import { buildAxiosInstance, getAccessToken } from '../axiosInstance';
import { useMsal } from '@azure/msal-react';

export const useGetInvDesc = (doc_id) => {
	const controller = new AbortController();
    const signal = controller.signal;
	let dispatch = useDispatch();
	const { instance } = useMsal();
	let hook=  useQuery({
		queryKey: [`contract_desc${doc_id}`],
		retry: false,
		queryFn: async () => {

			try {
				const TOAST_ID = 'contract_details'
				const axiosInstance = buildAxiosInstance(TOAST_ID)
				let config = {
					params: {document_id: doc_id},
					signal: signal,
				};
				const responseData = await axiosInstance.get('/contract_details',config)
				return responseData
			} catch (error) {
				LogoutCheck(error, dispatch,instance)	
				
			}
		},
	});
	return {
		hook: hook , 
		controller: controller
	}
};

async function callApiRecursive(body, nthChunk, chunkSize , totalPages) {
	
	try {
		let readyPages = Array.from({ length: chunkSize }, (_, index) => nthChunk*chunkSize + index)
		let pageContentObj = Object.keys(body.pages.pages).reduce((acc, key) => {
			if (readyPages.includes(parseInt(key))) {
			  acc[key] = body.pages.pages[key];
			}
			return acc;
		  }, {});

		let newBody = {
			...body,
			pages: {
				...body.pages,
				pages: pageContentObj
			},
			is_last: (nthChunk+1)*chunkSize < totalPages ? false: true
		}
		// newBody.pages = 
		let reqToken = await getAccessToken()
		const response = await axios.put(
			`${BACKEND_URL}/update_contract_entities`,
			newBody,
			{
				headers: {
					'Content-Type': 'application/json',
					Authorization:
						'Bearer ' + reqToken,
				}
		}
		);

		if (response.status >= 200 && response.status < 300) {
			if ((nthChunk+1)*chunkSize < totalPages) { 
				return callApiRecursive(body, nthChunk+1, chunkSize , totalPages)
			}
			else{
				return response.data;
			}
			// Successful response
			// toast.success(response.data.message);
		} else {
			// Server responded with an error status
			throw new Error(`Server responded with status ${response.status}`);
		}
	} catch (error) {
		// if (error.response) {
		// 	if (error.response.status >= 400 && error.response.status < 500) {
		// 		toast.error("Failed to upload")
		// 	} else {
		// 		toast.error(`Error: ${error.response.data.message}`);
		// 	}
		// } else if (error.request) {
		// 	//toast.error("No response received from the server. Please try again later.");
		// } else {
		// 	toast.error(`An error occurred: ${error.message}`);
		// }	
		if (error.response?.data?.message !==undefined) { 
			throw new Error(`${error.response.status}|${error.response?.data?.message}`);
		}
		else{
			throw new Error(`444|Failed to update`);
		}
	}
}



export const useUploadContractDesc = () => {
	//const Data = useSelector(state => state.auth)
	return useMutation({
		retry: 1,
		mutationFn:({body , chunkSize, totalPages})=> callApiRecursive(body,0, chunkSize, totalPages)
	});
};


async function callApiRecursiveDraft(body, nthChunk, chunkSize , totalPages) {
	
	try {
		let readyPages = Array.from({ length: chunkSize }, (_, index) => nthChunk*chunkSize + index)
		let pageContentObj = Object.keys(body.pages.pages).reduce((acc, key) => {
			if (readyPages.includes(parseInt(key))) {
			  acc[key] = body.pages.pages[key];
			}
			return acc;
		  }, {});

		let newBody = {
			...body,
			pages: {
				...body.pages,
				pages: pageContentObj
			},
			is_last: (nthChunk+1)*chunkSize < totalPages ? false: true
		}
		// newBody.pages = 
		let reqToken = await getAccessToken()
		const response = await axios.put(
			`${BACKEND_URL}/save_as_draft_contract_entities`,
			newBody,
			{
				headers: {
					'Content-Type': 'application/json',
					Authorization:
						'Bearer ' + reqToken,
				}
		}
		);

		if (response.status >= 200 && response.status < 300) {
			if ((nthChunk+1)*chunkSize < totalPages) { 
				return callApiRecursiveDraft(body, nthChunk+1, chunkSize , totalPages)
			}
			else{
				return response.data;
			}
			// Successful response
			// toast.success(response.data.message);
		} else {
			// Server responded with an error status
			throw new Error(`Server responded with status ${response.status}`);
		}
	} catch (error) {
		// if (error.response) {
		// 	if (error.response.status >= 400 && error.response.status < 500) {
		// 		toast.error("Failed to upload")
		// 	} else {
		// 		toast.error(`Error: ${error.response.data.message}`);
		// 	}
		// } else if (error.request) {
		// 	//toast.error("No response received from the server. Please try again later.");
		// } else {
		// 	toast.error(`An error occurred: ${error.message}`);
		// }	
		if (error.response?.data?.message !==undefined) { 
			throw new Error(`${error.response.status}|${error.response?.data?.message}`);
		}
		else{
			throw new Error(`444|Failed to update`);
		}
	}
}



export const useUploadAsDraftContractDesc = () => {
	//const Data = useSelector(state => state.auth)
	return useMutation({
		retry: 1,
		mutationFn:({body , chunkSize, totalPages})=> callApiRecursiveDraft(body,0, chunkSize, totalPages)
	});
};


export const useSendMessage = () => {
	// const dispatch = useDispatch();
	return useMutation({
		retry:false,
		mutationFn: async (body) => {
			try {
				let reqToken = await getAccessToken()
				const response = await axios.post(`${BACKEND_URL}/chat`, body, {
					headers: {
						'Content-Type': 'application/json',
						Authorization:
							'Bearer ' + reqToken,
					},
				});
				
				return response.data;
				
			} catch (error) {
				toast.error(`An error occurred: ${error.message}`);
			}
		}
	})
	
  };

  export const useStoreChatData = () => {
	return useMutation({
		retry:false,
		mutationFn: async (body) => {
			try {
				let reqToken = await getAccessToken()
				const response = await axios.post(`${BACKEND_URL}/add_chat`, body, {
					headers: {
						'Content-Type': 'application/json',
						Authorization:
							'Bearer ' + reqToken,
					},
				});
				
				return response.data;
				
			} catch (error) {
				toast.error(`An error occurred: ${error.message}`);
				throw new Error(error.message)
			}
		}
	})
	
  };

  export const useLikeDislikeChat = () => {
	return useMutation({
		retry:false,
		mutationFn: async (body) => {
			try {
				let reqToken = await getAccessToken()
				const response = await axios.post(`${BACKEND_URL}/like_dislike`, body, {
					headers: {
						'Content-Type': 'application/json',
						Authorization:
							'Bearer ' + reqToken,
					},
				});
				
				return response.data;
				
			} catch (error) {
				toast.error(`An error occurred: ${error.message}`);
			}
		}
	})
	
  };


export const useExtractTable = () => {
	const dispatch = useDispatch();
	const { instance } = useMsal();
	return useMutation({
		retry:false,
		mutationFn: async (body) => {
			try {
				const TOAST_ID = 'extract_table'
				const axiosInstance = buildAxiosInstance(TOAST_ID,true)
				const responseData = await axiosInstance.post(`/extract_table`, JSON.stringify(body));
				return responseData
			} catch (error) {
				LogoutCheck(error, dispatch,instance)
			}
		},
	});
};