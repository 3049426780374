import React from 'react';
/* import Highcharts from 'highcharts'
import HighchartsReact from 'highcharts-react-official' */
import { Doughnut } from 'react-chartjs-2';
import { useNavigate } from "react-router-dom";

import { Chart as ChartJS, ArcElement, Tooltip, Legend } from 'chart.js';
//import { Pie } from 'react-chartjs-2';
ChartJS.register(ArcElement, Tooltip, Legend);
function DataValidationsErrorDonuts({ValidationErrorData,dataFromChild2}) {
  let navigate = useNavigate();
  console.log(ValidationErrorData);
  
    //console.log(DashboardData?.total_files_completed);
    //console.log(DashboardData?.total_files_uploaded);
    const percentage_correct = ((ValidationErrorData?.correct/ValidationErrorData?.total)*100).toFixed(2);
    const percentage_incorrect = ((ValidationErrorData?.incorrect/ValidationErrorData?.total)*100).toFixed(2);
    const percentage_no_rule = ((ValidationErrorData?.no_rule/ValidationErrorData?.total)*100).toFixed(2);
    console.log(percentage_correct,percentage_incorrect,percentage_no_rule);
    
    //const percentage_pending = ((DashboardData?.total_files_pending/DashboardData?.total)*100).toFixed(2);
    //console.log(percentage_processing,percentage_completed,percentage_error,percentage_pending);
    const data = {
        labels: ['% Validated','% Not validated','% No validation rules' ],
        datasets: [
          {
            //label: '# of Votes',
            data: [(percentage_correct),(percentage_incorrect),(percentage_no_rule)],
            backgroundColor: [
                'rgb(2, 197, 68)',
                'rgb(235, 96, 96)',
                'rgb(217, 217, 217)',
                
            ],
            borderColor: [
             'rgb(2, 197, 68)',
             'rgb(235, 96, 96)',
              'rgb(217, 217, 217)',
              
            ],
            borderWidth: 1,
            //borderRadius: 10
          },
        ],
      };

    const options = {
      cutout: '65%',
      responsive: true,
      maintainAspectRatio: false,
      plugins: {
          legend: {
              display: false
          },
          
      },
      onClick: (e, activeEls) => {
        let dataIndex = activeEls[0]?.index;
        let label = e.chart.data.labels[dataIndex];
        navigateMe(label)
      },
      onHover: (event, chartElement) => {
        let dataIndex = chartElement[0]?.index;
        if(event.chart.data.labels[dataIndex] === '% Not validated'){
          event.native.target.title = 'Click to view Not validated'
          event.native.target.style.cursor = 'pointer'
        }
        else if(event.chart.data.labels[dataIndex] === '% No validation rules'){
          event.native.target.title = 'Click to view No validation rules'
          event.native.target.style.cursor = 'pointer'
        }
        else{
          event.native.target.title = ''
          event.native.target.style.cursor = 'default'
        }
        
      }
    };

    function navigateMe(label){
      //console.log(label);
      if(label === '% Not validated'){
        navigate('/data-validation-error-listing?false_none_type=false&analyst='+dataFromChild2.analyst+'&batch='+dataFromChild2.batch+'&category='+dataFromChild2.category+'&client='+dataFromChild2.client+'&datefrom='+dataFromChild2.datefrom+'&dateto='+dataFromChild2.dateto+'&field='+dataFromChild2.field+'&invoice='+dataFromChild2.invoice+'&invoice_status='+dataFromChild2.invoice_status+'&vendor='+dataFromChild2.vendor);
      }

      if(label === '% No validation rules'){
        navigate('/data-validation-error-listing?false_none_type=none&analyst='+dataFromChild2.analyst+'&batch='+dataFromChild2.batch+'&category='+dataFromChild2.category+'&client='+dataFromChild2.client+'&datefrom='+dataFromChild2.datefrom+'&dateto='+dataFromChild2.dateto+'&field='+dataFromChild2.field+'&invoice='+dataFromChild2.invoice+'&invoice_status='+dataFromChild2.invoice_status+'&vendor='+dataFromChild2.vendor);
      }
      
    }

    let plugin = [];

    if(percentage_correct >= 0){
        plugin = [{
          afterDraw: function(chart) {
            //chart.destroy();
            var width = chart.width,
            height = chart.height,
            ctx = chart.ctx;
            ctx.restore();
            var fontSize = (height / 300).toFixed(2);
            ctx.font = fontSize + "em sans-serif";
            ctx.textBaseline = "top";
            //ctx.textColor = "red";
            // var text = (percentage_completed)+"% Processed",
            var text = '',
            textX = Math.round((width - ctx.measureText(text).width) / 2),
            textY = height / 2;
            ctx.fillStyle = 'rgb(2, 197, 68)';
            ctx.fillText(text, textX, textY);
            ctx.save();
          } 
      }]
    }

    return (
        <>
            <h1 className="text-lg font-black text-primary">Data Validation Errors</h1>
            <p className="text-xs text-gray-500 mb-2">Total Fields with validation error</p>
            <div className="pt-4">
            {ValidationErrorData?.total >0 ? (
            
                <Doughnut width={300} height={300} options={options} data={data} plugins={plugin} redraw={true}/>
                ) : (
                <div  className='min-h-[150px]'>
                    No Data Found
                </div> 
                )}
            </div>
        </>
    );
}

export default DataValidationsErrorDonuts;