import React, { useState, useEffect } from 'react';
//import { toast } from 'react-toastify'

//import { Autocomplete, TextField } from '@mui/material';
import { Autocomplete, Box, TextField } from '@mui/material'

import { useInvoiceNames } from './hooks';

function FilterInvoiceName({
    setInvoiceName,
    InvoiceName,
    setInvoiceNameId,
    InvoiceNameId,
    ClientId,
    CategoryId,
    VendorNameId,AnalystId
}) {
    //console.log(ClientId, CategoryId, VendorNameId);
    
    const [options, setoptions] = useState([]);
    const client_list_hook = useInvoiceNames();
    const [Focus, setFocus] = useState(false);

    useEffect(() => {
        client_list_hook.mutate({ClientId,CategoryId, VendorNameId,AnalystId});
	}, [ClientId,CategoryId,VendorNameId,AnalystId]);

	useEffect(() => {
        //console.log(client_list_hook.data);
		if (client_list_hook.data) {
			setoptions(client_list_hook.data.data.map((itm) => itm));
		}
	}, [client_list_hook.data,ClientId,CategoryId,VendorNameId]);

    function changeProjectType(val) {
        console.log(val);
        
        setInvoiceName(val);
        let obj = client_list_hook.data.data.find((o) => o.fname === val?.fname);
        if (obj) {
            setInvoiceNameId(obj._id);
        }
        else {
            setInvoiceNameId(null)
            setInvoiceName(null)
        }
    }

    return (
        <>
            <div className="grow missDD">
                <div className="lightT">

                    {client_list_hook.isPending ? (
                        <p className="px-4 py-2 border border-1 border-slate-300 rounded-md">
                            <span className="text-sm text-slate-400 ps-4 inline-block">
                                Loading...
                            </span>
                        </p>
                    ) : (
                        <Autocomplete
                            disablePortal
                            isOptionEqualToValue={(option, value) =>
                                option === value || value === '' || value == null
                            }
                            id="combo-box-demo"
                            className='expCat'
                            options={options}
                            value={options.some(function(el){ return el.fname === InvoiceName?.fname}) ? InvoiceName : null }
                            getOptionLabel={(option) => option.fname}
                            renderOption={(props, option) => {
                                return (
                                    <Box component="li" {...props} key={option._id}>
                                        {option.fname}
                                    </Box>
                                )
                            }}
                            renderInput={(params) => (
                                <TextField {...params} label="Invoice Name" />
                            )}
                            onChange={(event, value) => changeProjectType(value)}
                            onFocus={() => setFocus(true)}
                            onBlur={() => setFocus(false)}
                        />
                    )}
                </div>
            </div>
        </>
    );
}

export default FilterInvoiceName;
