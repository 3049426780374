import { useMutation, useQuery } from '@tanstack/react-query';
//import { useInfiniteQuery } from '@tanstack/react-query';
import { useDispatch } from 'react-redux';
//import { useDebouncedState } from '@mantine/hooks';
//import { toast } from 'react-toastify';
import { RECORDS_PER_PAGE } from '../../config';
import { useState } from 'react';
import { buildAxiosInstance } from '../axiosInstance';
import { LogoutCheck } from '../utils';
import { useMsal } from '@azure/msal-react';

export const useNotificationListPagewise = (pageNo) => {
	let dispatch = useDispatch();
	const { instance } = useMsal();
	const [Args, setArgs] = useState({
		search: '',
		sort: '',
		page: pageNo,
	});
	const hook = useQuery({
		queryKey: ['notificationlist', Args],
		queryFn: async ({ signal }) =>
			fetchNotoficationList(Args.page, Args, dispatch, signal,instance),
		retry: false,
	});
	return {
		hook: hook,
		Args: Args,
		setArgs: setArgs,
	};
};

const fetchNotoficationList = async (pageParam, Args, dispatch, signal,instance) => {
	try {

		const TOAST_ID = 'notification_list'
		const axiosInstance = buildAxiosInstance(TOAST_ID)
		let config = {
			params:{
				search:Args.search,
				page:pageParam,
				limit:RECORDS_PER_PAGE,
				sort:Args.sort
			},
			signal: signal,
		};
		const responseData = await axiosInstance.get(`get_notifications_list`,config)
		return responseData

	} catch (error) {
		LogoutCheck(error, dispatch,instance)
		throw new Error(`${error.response.data.message}`);
	}
};

export const useReadNotification = () => {
	let dispatch = useDispatch();
	const { instance } = useMsal();
	return useMutation({
		//queryKey: ['get_user_by_id',id],
		retry: false,
		mutationFn: async (id) => {
			try {
				const TOAST_ID = 'delete_user'
				const axiosInstance = buildAxiosInstance(TOAST_ID)
				let config = {
					data: JSON.stringify({ id }),
				};
				const responseData = await axiosInstance.delete(`read_notification`,config)
				return responseData
			} catch (error) {
				LogoutCheck(error, dispatch,instance)
				throw new Error(`${error.response.data.message}`);
			}
		},
	});
};