import axios from 'axios';
import { toast } from "react-toastify";
import { AZURE_AUTHORITY_URL, AZURE_CLIENT_ID, BACKEND_URL, loginRequest } from "../config";
import { PublicClientApplication, EventType, InteractionRequiredAuthError } from '@azure/msal-browser';

// MSAL Configuration
const msalConfig = {
	auth: {
		clientId: AZURE_CLIENT_ID,
		authority: AZURE_AUTHORITY_URL,
		redirectUri: '/',
	},
	cache: {
		cacheLocation: 'localStorage',
		storeAuthStateInCookie: false,
	},
	system: {
		loggerOptions: {
			loggerCallback: (level, message, containsPII) => {
				//console.log(message)
			},
			logLevel: 'Info',
		},
	},
}

export const pca = new PublicClientApplication(msalConfig);

pca.addEventCallback((event) => {
	if (event.eventType === EventType.LOGIN_SUCCESS) {
		pca.setActiveAccount(event.payload.account);
		localStorage.setItem('username', event.payload.account.username);
		localStorage.setItem('name', event.payload.account.name);
        localStorage.setItem('interactionInProgress', false);
	}

	if (event.eventType === EventType.LOGOUT_SUCCESS) {
        localStorage.removeItem('interactionInProgress');
		localStorage.clear();
	}

	if (event.eventType === EventType.LOGIN_FAILURE) {
		console.error('Login failed:', event.error);
	}
});


// ✅ Function to Get Access Token
export async function getAccessToken() {
    await pca.initialize();

    const response = await pca.handleRedirectPromise();
    if (response) {
      console.log("Token from redirect:", response.accessToken);
      return response.accessToken;
    }

    const accounts = pca.getAllAccounts();
    if (accounts.length === 0) {
        try {
            await pca.ssoSilent({...loginRequest});
        } catch (error) {
            await pca.loginPopup({...loginRequest});
        }
    }

    const account = pca.getAllAccounts()[0];
    try {
        const response = await pca.acquireTokenSilent({
			...loginRequest,
            account,
        });
        return response.accessToken;
    } catch (error) {
        try {
            let res = await logout();
            if (res == null ){
                return ''
            }

           
        } catch (tokenError) {
            console.log(tokenError);
            
        }
    }
}


// ✅ Dynamic Axios Instance Function (Best Solution)
export function buildAxiosInstance(toastId, silent = false) {
    const instance = axios.create({
        baseURL: BACKEND_URL,
        headers: {
            'Content-Type': 'application/json',
        },
        maxBodyLength: Infinity,
    });

    // ✅ Request Interceptor to Inject Token
    instance.interceptors.request.use(async (config) => {
        const token = await getAccessToken();
        if (token) {
            config.headers['Authorization'] = `Bearer ${token}`;
        }
        return config;
    });

    // ✅ Response Interceptor to Handle Errors
    instance.interceptors.response.use(
        response => response.data,
        async (error) => {
            if (error.response) {
                // ✅ Handle Expired Token (401) or Invalid Token (403)
                if (error.response.status ===401) {
                        let res = await logout();
                        if (res == null ){
                            throw error
                        }

                       
                    
                }

                // ✅ Handle Error Toast Only When Silent Is False
                if (!silent) {
                    const errorMessage = error.response.data.message || 'An error occurred';
                    toast.error(errorMessage, { toastId });
                }
            }

            return Promise.reject(error);
        }
    );

    return instance;
}


// ✅ Function to Logout User
export async function logout() {
    const logoutRequest = {
        account: pca.getActiveAccount(),
        // mainWindowRedirectUri: "/",
        postLogoutRedirectUri: "/",
        // onRedirectNavigate: () => {
        //     // Return false to stop navigation after local logout
        //     return false;
        // }
    };
    if (localStorage.getItem('interactionInProgress') === 'true') {
        return null
    }
    localStorage.setItem('interactionInProgress', true);
    await pca.logoutRedirect(logoutRequest);
    localStorage.setItem('interactionInProgress', false);
    return true
}

// ✅ Function to Get Active Account
export function getActiveAccount() {
    return pca.getAllAccounts()[0];
}

// ✅ Function to Check If User is Authenticated
export async function isAuthenticated() {
    await pca.initialize();
    const accounts = pca.getAllAccounts();
    if (accounts.length === 0) return false;

    try {
        const tokenResponse = await pca.acquireTokenSilent({
            ...loginRequest,
            account: accounts[0],
        });
        return !!tokenResponse.accessToken;
    } catch (error) {
        return false;
    }
}
