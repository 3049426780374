import React, { useEffect, useRef, useState } from 'react';
import dayjs from 'dayjs';
import { DemoContainer } from '@mui/x-date-pickers/internals/demo';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import { Autocomplete, TextField } from '@mui/material';
import { GetCategorylist, GetAnalystlist } from './hooks';
import { useDispatch, useSelector } from 'react-redux';
import FltrClientName from './client-name-flt';
import FilterVendorName from './vendor-name';
import FilterBatchName from './batch-name';
import FilterInvoiceName from './invoice-name'
import FilterFieldName from './field-name'
import FilterInvoiceStatus from './invoice-status'
import { reset_filter, save_filter } from './dashboard-slice';

export default function DateFilter({ get_dashboard_hook, get_mismatches_hook,get_datafield_hook,sendDataToParent,get_datavalidationerror_hook,get_unmapped_price_hook,sendDataToParent2,sendDataToParent3 }) {
	const [fromValue, setFromValue] = React.useState();
	const [ToValue, setToValue] = React.useState();
	
	const category_list_hook = GetCategorylist();
	const analyst_list_hook = GetAnalystlist();

	const [, setFocus] = useState(false);
	const [options, setoptions] = useState([]);
	const [analystOptions, setAnalystOptions] = useState([]);
	
	const [Category, setCategory] = useState('All Categories');
	const [CategoryId, setCategoryId] = useState(null);

	const [Analyst, setAnalyst] = useState('All Users');
	const [AnalystId, setAnalystId] = useState(null);

	const [ClientName, setClientName] = useState('All Clients');
	const [ClientId, setClientId] = useState(null);

	const [VendorName, setVendorName] = useState({name:"All Vendor"});
	const [VendorNameId, setVendorNameId] = useState(null);

	const [InvoiceName, setInvoiceName] = useState({fname:"All Invoices"});
	const [InvoiceNameId, setInvoiceNameId] = useState(null);

	const [FieldName, setFieldName] = useState({entity_name:"All Fields"});
	const [FieldNameId, setFieldNameId] = useState(null);

	const [BatchName, setBatchName] = useState({name:"All Batches"});
	const [BatchNameId, setBatchNameId] = useState(null);

	const [InvoiceStatus, setInvoiceStatus] = useState("All Status");
	const [InvoiceStatusId, setInvoiceStatusId] = useState(null);
	const Data = useSelector(p=> p.dashSlice)
	const firstTime = useRef(true);
	const restoreFromRedux = useRef(false)

	const dispatch = useDispatch()
	function setDebounceSearch() {
		const fieldName = encodeURIComponent(FieldNameId);
		get_dashboard_hook.mutate({ analyst: AnalystId, category: CategoryId,client:ClientId,vendor:VendorNameId,invoice:InvoiceNameId,field:fieldName,batch:BatchNameId,invoice_status:InvoiceStatusId, datefrom: (fromValue) ? dayjs(fromValue.$d).format("MM-DD-YYYY") : null, dateto: (ToValue) ? dayjs(ToValue.$d).format("MM-DD-YYYY") : null });
		//get_invoice_need_attention_hook.mutate({ analyst: AnalystId, category: CategoryId, datefrom: (fromValue) ? dayjs(fromValue.$d).format("MM-DD-YYYY") : null, dateto: (ToValue) ? dayjs(ToValue.$d).format("MM-DD-YYYY") : null });
		get_mismatches_hook.mutate({ analyst: AnalystId, category: CategoryId,client:ClientId,vendor:VendorNameId,invoice:InvoiceNameId,field:fieldName,batch:BatchNameId,invoice_status:InvoiceStatusId, datefrom: (fromValue) ? dayjs(fromValue.$d).format("MM-DD-YYYY") : null, dateto: (ToValue) ? dayjs(ToValue.$d).format("MM-DD-YYYY") : null });
		
		//console.log(FieldNameId);
		if(FieldNameId){

			sendDataToParent({ show: true,analyst: AnalystId, category: CategoryId,client:ClientId,vendor:VendorNameId,invoice:InvoiceNameId,field:fieldName,batch:BatchNameId,invoice_status:InvoiceStatusId, datefrom: (fromValue) ? dayjs(fromValue.$d).format("MM-DD-YYYY") : null, dateto: (ToValue) ? dayjs(ToValue.$d).format("MM-DD-YYYY") : null });
			
			get_datafield_hook.mutate({ analyst: AnalystId, category: CategoryId,client:ClientId,vendor:VendorNameId,invoice:InvoiceNameId,field:fieldName,batch:BatchNameId,invoice_status:InvoiceStatusId, datefrom: (fromValue) ? dayjs(fromValue.$d).format("MM-DD-YYYY") : null, dateto: (ToValue) ? dayjs(ToValue.$d).format("MM-DD-YYYY") : null });
		}else{
			sendDataToParent({ show: false });
		}		
		
		//console.log(InvoiceNameId);
		
		if(AnalystId || CategoryId || ClientId || VendorNameId ){

			sendDataToParent2({ show: true,analyst: AnalystId, category: CategoryId,client:ClientId,vendor:VendorNameId,invoice:InvoiceNameId,field:fieldName,batch:BatchNameId,invoice_status:InvoiceStatusId, datefrom: (fromValue) ? dayjs(fromValue.$d).format("MM-DD-YYYY") : null, dateto: (ToValue) ? dayjs(ToValue.$d).format("MM-DD-YYYY") : null });
			
			get_datavalidationerror_hook.mutate({ analyst: AnalystId, category: CategoryId,client:ClientId,vendor:VendorNameId,invoice:InvoiceNameId,field:fieldName,batch:BatchNameId,invoice_status:InvoiceStatusId, datefrom: (fromValue) ? dayjs(fromValue.$d).format("MM-DD-YYYY") : null, dateto: (ToValue) ? dayjs(ToValue.$d).format("MM-DD-YYYY") : null });
		}
		else{
			sendDataToParent2({ show: false });

		}

		get_unmapped_price_hook.mutate({ analyst: AnalystId, category: CategoryId,client:ClientId,vendor:VendorNameId,invoice:InvoiceNameId,field:fieldName,batch:BatchNameId,invoice_status:InvoiceStatusId, datefrom: (fromValue) ? dayjs(fromValue.$d).format("MM-DD-YYYY") : null, dateto: (ToValue) ? dayjs(ToValue.$d).format("MM-DD-YYYY") : null });

		sendDataToParent3({ show: true,analyst: AnalystId, category: CategoryId,client:ClientId,vendor:VendorNameId,invoice:InvoiceNameId,field:fieldName,batch:BatchNameId,invoice_status:InvoiceStatusId, datefrom: (fromValue) ? dayjs(fromValue.$d).format("MM-DD-YYYY") : null, dateto: (ToValue) ? dayjs(ToValue.$d).format("MM-DD-YYYY") : null });

		saveFilters()
		
	}

	useEffect(() => {
		category_list_hook.mutate({ClientId,VendorNameId,AnalystId});
		//analyst_list_hook.mutate({ClientId,VendorNameId});
	}, [ClientId,VendorNameId,AnalystId]);

	useEffect(() => {
		//category_list_hook.mutate({ClientId,VendorNameId});
		analyst_list_hook.mutate({ClientId,VendorNameId,CategoryId});
	}, [ClientId,VendorNameId,CategoryId]);

	useEffect(() => {
		if (category_list_hook.data) {
			//console.log(category_list_hook.data);
			setoptions(category_list_hook.data.data.map((itm) => itm.name));
			//console.log(options);
			
		}
	}, [category_list_hook.data]);

	useEffect(() => {
		if (analyst_list_hook.data) {
			//console.log(category_list_hook.data);
			setAnalystOptions(analyst_list_hook.data.data.map((itm) => (itm.name) ? itm.name : itm.email));
		}
	}, [analyst_list_hook.data]);

	function changeCategory(val) {
		//console.log(val);
		setCategory(val);
		let obj = category_list_hook.data.data.find((o) => o.name === val);
		//console.log(obj);
		if (obj) {
			setCategoryId(obj._id);
			//console.log(CategoryId);
		}
		else {
			setCategoryId(null);
		}

	}

	function changeAnalyst(val) {
		//console.log(val);
		setAnalyst(val);
		let obj = analyst_list_hook.data.data.find((o) => o.email === val);
		//console.log(obj);
		if (obj) {
			setAnalystId(obj._id);
			//console.log(CategoryId);
		}
		else {
			let obj = analyst_list_hook.data.data.find((o) => o.name === val);
			//console.log(obj);
			if (obj) {
				setAnalystId(obj._id);
				//console.log(CategoryId);
			}
			else {
				setAnalystId(null);
			}
		}

	}

	const PrivilagesList = useSelector(
		(state) => state?.privilageSlice?.allowedActions
	);


	function restoreFilters(filter){
		if (filter) {
			setAnalystId(filter.analyst)
			setAnalyst(filter.analystName)
			setCategoryId(filter.category)
			setCategory(filter.categoryName)
			setClientId(filter.client)
			setClientName(filter.clientName)
			setVendorNameId(filter.vendor)
			setVendorName(filter.vendorName)
			setInvoiceNameId(filter.invoice)
			setInvoiceName(filter.invoiceName)
			setFieldNameId(filter.fieldNameId)
			setFieldName(filter.fieldNameId !==null ? {entity_name:decodeURIComponent(filter.fieldNameId)}   : {entity_name:"All Fields"})
			setBatchNameId(filter.batch)
			setBatchName(filter.batchName)
			setInvoiceStatus(filter.invoice_status)
			setFromValue(filter.datefrom)
			setToValue(filter.dateto)
			restoreFromRedux.current = true
		}
	}
	const resetFilters = () => {
		setCategory('All Categories');
		setCategoryId(null);
	
		setAnalyst('All Users');
		setAnalystId(null);
	
		setClientName('All Clients');
		setClientId(null);
	
		setVendorName({ name: "All Vendor" });
		setVendorNameId(null);
	
		setInvoiceName({ fname: "All Invoices" });
		setInvoiceNameId(null);
	
		setFieldName({ entity_name: "All Fields" });
		setFieldNameId(null);
	
		setBatchName({ name: "All Batches" });
		setBatchNameId(null);
	
		setInvoiceStatus("All Status");
		setInvoiceStatusId(null);
		setFromValue(undefined)
		setToValue(undefined)
		get_mismatches_hook.mutate({analyst: null, category: null, client:null, vendor:null, invoice:null, field:null, batch:null, invoice_status:null, datefrom: null, dateto: null});
		get_dashboard_hook.mutate({analyst: null, category: null, client:null, vendor:null, invoice:null, field:null, batch:null, invoice_status:null, datefrom: null, dateto: null});
		get_unmapped_price_hook.mutate({analyst: null, category: null, client:null, vendor:null, invoice:null, field:null, batch:null, invoice_status:null, datefrom: null, dateto: null});
		//get_datafield_hook.mutate({analyst: null, category: null, client:null, vendor:null, invoice:null, field:null, batch:null, invoice_status:null, datefrom: null, dateto: null});
		//get_datavalidationerror_hook.mutate({analyst: null, category: null, client:null, vendor:null, invoice:null, field:null, batch:null, invoice_status:null, datefrom: null, dateto: null});
		
		sendDataToParent2({ show: false });
		sendDataToParent({ show: false });
		dispatch(reset_filter())
	};
	

	useEffect(() => {
		restoreFilters(Data)
	}, [])

	function saveFilters() {
		let payload = { 
			analyst: AnalystId,
			analystName: Analyst,
			category: CategoryId,
			categoryName: Category,
			client:ClientId,
			clientName: ClientName,
			vendor:VendorNameId,
			vendorName: VendorName,
			invoice:InvoiceNameId,
			invoiceName: InvoiceName,
			fieldNameId:FieldNameId,
			batch:BatchNameId,
			batchName: BatchName,
			invoice_status:InvoiceStatusId,
			datefrom: fromValue,
			dateto: ToValue 
		}
		dispatch(save_filter(payload))

		
	}

	useEffect(() => {
	  if (firstTime.current) {
		firstTime.current = false;
		return;
	  }
	  else if (restoreFromRedux.current) {
		restoreFromRedux.current = false
		return 
	  }
	  
	
	  setInvoiceName({ fname: "All Invoices" });
	  setInvoiceNameId(null);
	
	  setFieldName({ entity_name: "All Fields" });
	  setFieldNameId(null);
	
	  setBatchName({ name: "All Batches" });
	  setBatchNameId(null);
	
	  setInvoiceStatus("All Status");
	  setInvoiceStatusId(null);
	
	}, [ClientId, VendorNameId, AnalystId, CategoryId]);
	
	

	//console.log(PrivilagesList);

	return (
		<>
			<div className="md:flex items-center gap-3 flex-wrap xl:flex-nowrap text-sm multiDashFltr">

				{PrivilagesList.includes('leader_dashboard') ? (
					<div className="grow missDD">
						{analyst_list_hook.isPending ? (
							<p className="px-4 py-2 border border-1 border-slate-300 rounded-md">
								<span className="text-xs text-slate-400 ps-4 inline-block">
									Loading..
								</span>
							</p>
						) : (
							<Autocomplete
								isOptionEqualToValue={(option, value) =>
									option === value || value === '' || value == null
								}
								disablePortal
								id="combo-box-demo"
								className='expCat'
								options={analystOptions}
								value={(analystOptions.includes(Analyst)?Analyst: null) || null }
								renderInput={(params) => (
									<TextField {...params} label="User" />
								)}
								onChange={(event, value) => changeAnalyst(value)}
								onFocus={() => setFocus(true)}
								onBlur={() => setFocus(false)}
							/>
						)}
					</div>
				) : (
					null
				)}

				<div className="grow missDD">

					{category_list_hook.isPending ? (
						<p className="px-4 py-2 border border-1 border-slate-300 rounded-md">
							<span className="text-xs text-slate-400 ps-4 inline-block">
								Loading..
							</span>
						</p>
					) : (
						<Autocomplete
							isOptionEqualToValue={(option, value) =>
								option === value || value === '' || value == null
							}
							disablePortal
							id="combo-box-demo"
							className='expCat'
							options={options}
							value={(options.includes(Category)?Category: null) || null }
							renderInput={(params) => (
								<TextField {...params} label="Expense Area" />
							)}
							onChange={(event, value) => changeCategory(value)}
							onFocus={() => setFocus(true)}
							onBlur={() => setFocus(false)}
						/>
					)}
				</div>

				
					<FltrClientName
						
						setClientName={setClientName}
						ClientName={ClientName}
						setClientId={setClientId}
						
						AnalystId={AnalystId}
						CategoryId={CategoryId}
						ClientId={ClientId}
						VendorNameId={VendorNameId}
					/>
			

					<FilterVendorName
						setVendorName={setVendorName}
						VendorName={VendorName}
						setVendorNameId={setVendorNameId}
						required='false'
						
						AnalystId={AnalystId}
						CategoryId={CategoryId}
						ClientId={ClientId}
						VendorNameId={VendorNameId}
					/>

				

				{(ClientId && VendorNameId && CategoryId) && (
					<FilterInvoiceName
						setInvoiceName={setInvoiceName}
						InvoiceName={InvoiceName}
						setInvoiceNameId={setInvoiceNameId}
						InvoiceNameId={InvoiceNameId}
						
						AnalystId={AnalystId}
						CategoryId={CategoryId}
						ClientId={ClientId}
						VendorNameId={VendorNameId}
					/>
				)}
				
				{(ClientId && CategoryId && VendorNameId) && (
					<div className="grow missDD">
						<FilterFieldName
							setFieldName={setFieldName}
							FieldName={FieldName}
							setFieldNameId={setFieldNameId}
							FieldNameId={FieldNameId}
							
							AnalystId={AnalystId}
							CategoryId={CategoryId}
							ClientId={ClientId}
							VendorNameId={VendorNameId}
						/>
					</div>
				)}

				{(ClientId && CategoryId && VendorNameId) && (
					<FilterBatchName
						setBatchName={setBatchName}
						BatchName={BatchName}
						setBatchNameId={setBatchNameId}
						BatchNameId={BatchNameId}
						
						AnalystId={AnalystId}
						CategoryId={CategoryId}
						ClientId={ClientId}
						VendorNameId={VendorNameId}
						
					/>
				)}

				{(ClientId && CategoryId && VendorNameId) && (
					
					<div className="grow missDD">
						<FilterInvoiceStatus
							setInvoiceStatus={setInvoiceStatus}
							InvoiceStatus={InvoiceStatus}
							InvoiceStatusId={InvoiceStatusId}
							setInvoiceStatusId={setInvoiceStatusId}
							
							AnalystId={AnalystId}
							CategoryId={CategoryId}
							ClientId={ClientId}
							VendorNameId={VendorNameId}
						/>
					</div>
				)}

				<div className="dateHldr flex items-center gap-3">
					<div>From</div>
					<LocalizationProvider dateAdapter={AdapterDayjs}>
						<DemoContainer components={['DatePicker']}>
							<DatePicker
								slotProps={{ field: { clearable: true } }}
								value={fromValue || null}
								onChange={(event) => { setFromValue(event); }}
							/>
						</DemoContainer>
					</LocalizationProvider>
				</div>

				<div className="dateHldr flex items-center gap-3">
					<div>To</div>
					<LocalizationProvider dateAdapter={AdapterDayjs}>
						<DemoContainer components={['DatePicker']}>
							<DatePicker
								slotProps={{ field: { clearable: true } }}
								value={ToValue || null}
								onChange={(newValue) => setToValue(newValue)}
							/>
						</DemoContainer>
					</LocalizationProvider>
				</div>
				<div className='flex gap-1'>
					<button
							type="button"
							disabled={get_dashboard_hook.isPending}
							onClick={resetFilters}
							className="bg-blue-800 hover:bg-indigo-700 w-full px-4 py-2 h-10 rounded-md text-white flex items-center font-black disabled:bg-slate-300 disabled:text-slate-700"
						>
							<span className="material-icons mr-1 text-sm">refresh</span>
							Reset
						</button>
					<button
						type="button"
						disabled={get_dashboard_hook.isPending}
						onClick={setDebounceSearch}
						className="bg-blue-800 hover:bg-indigo-700 w-full px-4 py-2 h-10 rounded-md text-white flex items-center font-black disabled:bg-slate-300 disabled:text-slate-700"
					>
						<span className="material-icons mr-1 text-sm">refresh</span>
						Reload
					</button>
				</div>
			</div>
		</>
	);
}