import { useQuery, useMutation } from '@tanstack/react-query';
import { useDispatch } from 'react-redux';
import { buildAxiosInstance } from '../axiosInstance';
import { LogoutCheck } from '../utils';
import { toast } from 'react-toastify';
import { useMsal } from '@azure/msal-react';
import { useDebouncedState } from '@mantine/hooks';
import {
	BLOB_CONTAINER_NAME,
	RECORDS_PER_PAGE,
} from '../../config';
export const useFetchDashboardData = () => {

	const dispatch = useDispatch();
	const { instance } = useMsal();
	return useMutation({
		mutationFn: async (body) => {
			try {
				const TOAST_ID = 'fetch_dasboard_data'
				const axiosInstance = buildAxiosInstance(TOAST_ID)

				const responseData = await axiosInstance.get(`dashboard?analyst=${body.analyst}&category=${body.category}&client=${body.client}&vendor=${body.vendor}&invoice=${body.invoice}&field=${body.field}&batch=${body.batch}&invoice_status=${body.invoice_status}&datefrom=${body.datefrom}&dateto=${body.dateto}`);
				let message = responseData?.message
				message && toast.success(message, { toastId: TOAST_ID })
				return responseData

			} catch (error) {
				LogoutCheck(error, dispatch,instance)
				throw new Error(`${error.response.data.message}`);
				
			}
		},
	});
	 
};

export const useFetchInvoiceNeedAttention = () => {

	const dispatch = useDispatch();
	const { instance } = useMsal();
	return useMutation({
		mutationFn: async (body) => {
			try {
				const TOAST_ID = 'fetch_invoice_need_attention'
				const axiosInstance = buildAxiosInstance(TOAST_ID)

				const responseData = await axiosInstance.get(`invoices_need_attention?analyst=${body.analyst}&category=${body.category}&datefrom=${body.datefrom}&dateto=${body.dateto}`);
				let message = responseData?.message
				message && toast.success(message, { toastId: TOAST_ID })
				return responseData

			} catch (error) {
				LogoutCheck(error, dispatch,instance)
				throw new Error(`${error.response.data.message}`);
			}
		},
	});
	 
};


export const useFetchMismatches = () => {

	const dispatch = useDispatch();
	const { instance } = useMsal();
	return useMutation({
		mutationFn: async (body) => {
			try {
				const TOAST_ID = 'fetch_mismatches'
				const axiosInstance = buildAxiosInstance(TOAST_ID)

				const responseData = await axiosInstance.get(`fetch_mismatches?analyst=${body.analyst}&category=${body.category}&client=${body.client}&vendor=${body.vendor}&invoice=${body.invoice}&field=${body.field}&batch=${body.batch}&invoice_status=${body.invoice_status}&datefrom=${body.datefrom}&dateto=${body.dateto}`);
				let message = responseData?.message
				message && toast.success(message, { toastId: TOAST_ID })
				return responseData

			} catch (error) {
				LogoutCheck(error, dispatch,instance)
				throw new Error(`${error.response.data.message}`);
			}
		},
	});
	 
};

export const useFetchDataField = () => {

	const dispatch = useDispatch();
	const { instance } = useMsal();
	return useMutation({
		mutationFn: async (body) => {
			try {
				const TOAST_ID = 'fetch_datafields'
				const axiosInstance = buildAxiosInstance(TOAST_ID)

				const responseData = await axiosInstance.get(`fetch_datafields?analyst=${body.analyst}&category=${body.category}&client=${body.client}&vendor=${body.vendor}&invoice=${body.invoice}&field=${body.field}&batch=${body.batch}&invoice_status=${body.invoice_status}&datefrom=${body.datefrom}&dateto=${body.dateto}`);
				let message = responseData?.message
				message && toast.success(message, { toastId: TOAST_ID })
				return responseData

			} catch (error) {
				LogoutCheck(error, dispatch,instance)
				throw new Error(`${error.response.data.message}`);
			}
		},
	});
	 
};


const fetchClientList = async (Args, dispatch, signal,instance) => {

	try {
		const TOAST_ID = 'get_fetch_client_list'
		const axiosInstance = buildAxiosInstance(TOAST_ID)
		let config = {
			params: {...Args},
			signal: signal,
		};
		const responseData = await axiosInstance.get('/client_list',config)
		return responseData
	} catch (error) {
		LogoutCheck(error, dispatch,instance)
		throw new Error(`${error.response.data.message}`);	

	};
};

export const useClientsList_Uploadpage = () => {
	const dispatch = useDispatch();
	const { instance } = useMsal();
	return useMutation({
		mutationFn: async (body) => {
			try {
				const TOAST_ID = 'clientlist_uploadpage'
				const axiosInstance = buildAxiosInstance(TOAST_ID)

				const responseData = await axiosInstance.get(`clients_dashboard?&categoryid=${body.CategoryId}&VendorId=${body.VendorNameId}&AnalystId=${body.AnalystId}`);
				let message = responseData?.message
				message && toast.success(message, { toastId: TOAST_ID })
				return responseData

			} catch (error) {
				LogoutCheck(error, dispatch,instance)
				throw new Error(`${error.response.data.message}`);
			}
		},
	});
};


export const GetVendors = () => {

	const dispatch = useDispatch();
	const { instance } = useMsal();
	return useMutation({
		mutationFn: async (body) => {
			try {
				const TOAST_ID = 'get_vendors'
				const axiosInstance = buildAxiosInstance(TOAST_ID)

				const responseData = await axiosInstance.get(`vendors_dashboard?clientid=${body.ClientId}&categoryid=${body.CategoryId}&AnalystId=${body.AnalystId}`);
				let message = responseData?.message
				message && toast.success(message, { toastId: TOAST_ID })
				return responseData

			} catch (error) {
				LogoutCheck(error, dispatch,instance)
				throw new Error(`${error.response.data.message}`);
			}
		},
	});

};

/** API to fetch batches */
export const useGetBatches = () => {

	const dispatch = useDispatch();
	const { instance } = useMsal();
	return useMutation({
		mutationFn: async (body) => {
			try {
				const TOAST_ID = 'get_batchs'
				const axiosInstance = buildAxiosInstance(TOAST_ID)

				const responseData = await axiosInstance.get(`batch_dashboard?VendorId=${body.VendorNameId}&clientid=${body.ClientId}&categoryid=${body.CategoryId}&AnalystId=${body.AnalystId}`);
				let message = responseData?.message
				message && toast.success(message, { toastId: TOAST_ID })
				return responseData

			} catch (error) {
				LogoutCheck(error, dispatch,instance)
				throw new Error(`${error.response.data.message}`);
			}
		},
	});

};

const fetchInvoiceNamesList = async (Args, dispatch, signal,instance) => {

	try {
		const TOAST_ID = 'get_fetch_invoice_names'
		const axiosInstance = buildAxiosInstance(TOAST_ID)
		let config = {
			params: {...Args, limit: RECORDS_PER_PAGE},
			signal: signal,
		};
		const responseData = await axiosInstance.get('/invoice_names',config)
		return responseData
	} catch (error) {
		LogoutCheck(error, dispatch,instance)
		throw new Error(`${error.response.data.message}`);	

	};
};

export const useInvoiceNames = () => {
	const dispatch = useDispatch();
	const { instance } = useMsal();
	return useMutation({
		mutationFn: async (body) => {
			try {
				const TOAST_ID = 'invoice_names'
				const axiosInstance = buildAxiosInstance(TOAST_ID)

				const responseData = await axiosInstance.get(`invoice_names?clientid=${body.ClientId}&categoryid=${body.CategoryId}&VendorId=${body.VendorNameId}&AnalystId=${body.AnalystId}`);
				let message = responseData?.message
				message && toast.success(message, { toastId: TOAST_ID })
				return responseData

			} catch (error) {
				LogoutCheck(error, dispatch,instance)
				throw new Error(`${error.response.data.message}`);
			}
		},
	});
};

export const useFieldNames = () => {
	const dispatch = useDispatch();
	const { instance } = useMsal();
	return useMutation({
		mutationFn: async (body) => {
			try {
				const TOAST_ID = 'field_names'
				const axiosInstance = buildAxiosInstance(TOAST_ID)

				const responseData = await axiosInstance.get(`get_field_names?categoryid=${body.CategoryId}&VendorId=${body.VendorNameId}&AnalystId=${body.AnalystId}`);
				let message = responseData?.message
				message && toast.success(message, { toastId: TOAST_ID })
				return responseData

			} catch (error) {
				LogoutCheck(error, dispatch,instance)
				throw new Error(`${error.response.data.message}`);
			}
		},
	});
};

export const GetCategorylist = () => {

	const dispatch = useDispatch();
	const { instance } = useMsal();
	return useMutation({
		mutationFn: async (body) => {
			try {
				const TOAST_ID = 'get_category_list'
				const axiosInstance = buildAxiosInstance(TOAST_ID)

				const responseData = await axiosInstance.get(`category_dashboard?clientid=${body.ClientId}&VendorId=${body.VendorNameId}&AnalystId=${body.AnalystId}`);
				let message = responseData?.message
				message && toast.success(message, { toastId: TOAST_ID })
				return responseData

			} catch (error) {
				LogoutCheck(error, dispatch,instance)
				throw new Error(`${error.response.data.message}`);
			}
		},
	});
	 
};

export const GetAnalystlist = () => {

	const dispatch = useDispatch();
	const { instance } = useMsal();
	return useMutation({
		mutationFn: async (body) => {
			try {
				const TOAST_ID = 'get_analyst_list'
				const axiosInstance = buildAxiosInstance(TOAST_ID)

				const responseData = await axiosInstance.get(`analyst_dashboard?clientid=${body.ClientId}&VendorId=${body.VendorNameId}&categoryid=${body.CategoryId}`);
				let message = responseData?.message
				message && toast.success(message, { toastId: TOAST_ID })
				return responseData

			} catch (error) {
				LogoutCheck(error, dispatch,instance)
				throw new Error(`${error.response.data.message}`);
			}
		},
	});
};

export const useFetchDataValidationError = () => {

	const dispatch = useDispatch();
	const { instance } = useMsal();
	return useMutation({
		mutationFn: async (body) => {
			try {
				const TOAST_ID = 'fetch_datavalidationerror'
				const axiosInstance = buildAxiosInstance(TOAST_ID)

				const responseData = await axiosInstance.get(`fetch_datavalidationerror?analyst=${body.analyst}&category=${body.category}&client=${body.client}&vendor=${body.vendor}&invoice=${body.invoice}&field=${body.field}&batch=${body.batch}&invoice_status=${body.invoice_status}&datefrom=${body.datefrom}&dateto=${body.dateto}`);
				let message = responseData?.message
				message && toast.success(message, { toastId: TOAST_ID })
				return responseData

			} catch (error) {
				LogoutCheck(error, dispatch,instance)
				throw new Error(`${error.response.data.message}`);
			}
		},
	});

}


export const useFetchUnmappedPriceItems = () => {

	const dispatch = useDispatch();
	const { instance } = useMsal();
	return useMutation({
		mutationFn: async (body) => {
			try {
				const TOAST_ID = 'fetch_unmapped_price_items'
				const axiosInstance = buildAxiosInstance(TOAST_ID)

				const responseData = await axiosInstance.get(`fetch_unmapped_price_items?analyst=${body.analyst}&category=${body.category}&client=${body.client}&vendor=${body.vendor}&invoice=${body.invoice}&field=${body.field}&batch=${body.batch}&invoice_status=${body.invoice_status}&datefrom=${body.datefrom}&dateto=${body.dateto}`);
				let message = responseData?.message
				message && toast.success(message, { toastId: TOAST_ID })
				return responseData

			} catch (error) {
				LogoutCheck(error, dispatch,instance)
				throw new Error(`${error.response.data.message}`);
			}
		},
	});
		
};
