export const APP_ENV = process.env.REACT_APP_ENV;
export const BACKEND_URL = process.env.REACT_APP_BACKEND_URL;
export const BLOB_CONTAINER_NAME = process.env.REACT_APP_CONTAINER_NAME;
export const BLOB_URL = process.env.REACT_APP_BLOB_URL;
// export const BACKEND_URL = 'http://20.55.24.192:5000' ;
export const RECORDS_PER_PAGE = 20;
export const AZURE_CLIENT_ID = process.env.REACT_APP_AZURE_CLIENT_ID;
export const AZURE_AUTHORITY_URL = process.env.REACT_APP_AZURE_AUTHORITY_URL;
export const APP_ROUTE_ALLOWED = JSON.parse(
	process.env.REACT_APP_ROUTE_ALLOWED.replace(/'/g, '"')
);
export const loginRequest = {
    scopes: [process.env.REACT_APP_AD_SCOPE]
};