//import React from 'react';
import React, { useState } from 'react';
import Checkbox from '@mui/material/Checkbox';
//import { useGetSasToken } from './hooks';
import { useOutletContext, useNavigate,createSearchParams } from 'react-router-dom';
import TimeZoneConverter from '../shared/timezone';

export const MismatchesListingDataByStatus = () => {
	//const { mismatchList } = useOutletContext();
		const { mismatchList, selectedInvoices , setselectedInvoices,selectedMismatches , setselectedMismatches, setuatHeader } = useOutletContext();
		//let dispatch = useDispatch()
		const [titleDate, settitleDate] = useState('')
		const navigate = useNavigate();
		function gotoMismatch(mismatch_id, client_name,client_id,  vendor_name,vendor_id, invoice_name ,invoice_id,category_id, category_name){
			navigate({
				pathname : '/comparison' ,
				search: createSearchParams({ 
	
					mismatch_id,
					client_id,
					client_name,
					client_list_page_no: 1,
					client_list_sort_key: '',
					client_list_sort_order: '',
	
					vendor_id,
					vendor_name,
					vendor_list_page_no : 1,
					vendor_list_sort_key: '',
					vendor_list_sort_order: '',
					category_name,
					category_id,
	
					invoice_id,
					invoice_name,
					invoice_list_page_no:1,
					invoice_list_sort_key:'',
					invoice_list_sort_order:''
				}
				).toString() 
			} )
		}
		
		return (
			<>
				{mismatchList?.data?.map((itm, ind) => {
					//console.log(itm);
					
					return (
						<div key={`itm` + ind} className="flex gap-2 mb-2 tblLoop">
							{/* <div className="flex-none w-[40px] flex items-center">
							<Checkbox onChange={(e)=> handleCheckBoxRowwise(e.target.checked, itm._id,itm.invoice_id)} checked={selectedInvoices.has(itm._id)} size="small" />
								</div> */}
							<div className="flex-none">
								<label className="vLBG flex items-center px-3 h-[53px] rounded-md text-center text-white relative text-sm">
									<img className="h-8" src="/svg/ri_building-fill.svg" alt="" />
								</label>
							</div>
							<div className="grow border rounded-md px-5 py-3 text-sm flex items-center justify-between">
	
								<div className="w-[180px] relative flex grow border-r pr-4 items-center text-left">
									<span title={itm.client_name} className="absolute w-full block text-ellipsis whitespace-nowrap overflow-hidden">{itm.client_name}</span>
								</div>
								<div className="w-[140px] relative flex grow border-r pr-4 items-center text-center">
									<span title={itm.vendor_name} className="absolute w-full block text-ellipsis whitespace-nowrap overflow-hidden text-center">{itm.vendor_name}</span>
								</div>
								<div className="w-[180px] relative flex grow border-r pr-4 items-center text-center">
									<span title={itm.expense_area} className="absolute w-full block text-ellipsis whitespace-nowrap overflow-hidden text-center">{itm.expense_area}</span>
								</div>
								<div className="w-[180px] relative flex grow border-r pr-4 items-center text-center">
									<span title={itm.invoice_name} className="absolute w-full block text-ellipsis whitespace-nowrap overflow-hidden text-center">{itm.invoice_name}</span>
								</div>
								<div className="w-[140px] relative flex grow border-r pr-4 items-center text-center">
									<span title={titleDate} className="absolute w-[90%] block text-ellipsis whitespace-nowrap overflow-hidden text-center"><TimeZoneConverter settitleDate={settitleDate} setuatHeader={setuatHeader} utcTimestamp={itm.invoice_date} /></span>
								</div>
								<div className="w-[180px] relative flex grow border-r pr-4 items-center">
									<span title={itm.description} className="absolute w-[90%] block text-ellipsis whitespace-nowrap overflow-hidden text-center">{itm.description}</span>
								</div>
								<div className="w-[180px] relative flex grow border-r pr-4 items-center">
									<span title={itm.mismatch_explanation} className="absolute w-[90%] block text-ellipsis whitespace-nowrap overflow-hidden text-center">{itm.mismatch_explanation}</span>
								</div>	
								
							</div>
							
	
							<div className="flex-none w-[100px] flex gap-2 pr-2">
								<button
									
									onClick={() => gotoMismatch(itm._id , itm.client_name,itm.client_id,  itm.vendor_name,itm.vendor_id, itm.invoice_name ,itm.invoice_id,itm.category_id, itm.expense_area )}
									type="button"
									className="vLBG block px-4 py-4 text-center text-primary text-sm rounded-md font-black disabled:bg-gray-200 disabled:text-gray-500"
								>
									Mismatch
								</button>
							</div>
	
						</div>
					);
				})}
			</>
		);
	};
	
	
	function formatDate(inputDateString) {
		if (!inputDateString) return '-'
		// Create a Date object from the input date string
		var date = new Date(inputDateString);
	
		// Extract day, month, and year
		var day = date.getDate();
		var month = date.getMonth() + 1; // Note: getMonth() returns 0-indexed month, so we add 1
		var year = date.getFullYear();
	
		// Format day and month to have leading zeros if necessary
		day = day < 10 ? '0' + day : day;
		month = month < 10 ? '0' + month : month;
	
		// Construct the formatted date string
		var formattedDateString = month + '/' + day + '/' + year;
	
		// Return the formatted date string
		return formattedDateString;
	}