import React, { useEffect, useRef, useState } from 'react';
import styles from './common.module.css';
import { Outlet, useNavigate, useSearchParams } from 'react-router-dom';
import { Link } from 'react-router-dom';
//import { useState } from 'react'
import { useLocation } from 'react-router-dom';
import { useLogout, useReleaseLock } from '../auth/hooks';
// import Alert from 'react-popup-alert'
import swal from 'sweetalert';
import { toast } from 'react-toastify';
import LoaderFull from './loader-full-screen';
import { useDispatch, useSelector } from 'react-redux';
//import { useSearchParams } from "react-router-dom"
import { useMsal } from '@azure/msal-react';
import { dispClientId, dispClientPageNo } from '../navigateSlice';
//import { APP_ROUTE_ALLOWED } from '../../config';
import { privilage_remove } from '../auth/privilegesSlice';
import { LicencePopup } from './licencePopup';
import { redux_logout, remove_lock } from '../auth/authSlice';
import { APP_ROUTE_ALLOWED } from '../../config';
import { BACKEND_URL } from '../../config';
import axios from 'axios';

import ChatIndex from '../annotation_contracts/chats/chatIndex';
import GlobalChatIndex from './globalChat';
import {TimeZonePopup} from './timezone';
import { reset_filter } from '../dashboard/dashboard-slice';
import { getAccessToken, logout } from '../axiosInstance';
import { useGetProfile } from '../profile/hooks';

export const TopLayout = () => {
	//const Data = useSelector(state => state.auth)
	const [open] = React.useState(true);
	const [notificationCount, setNotificationCount] = useState(0);
	const navigate = useNavigate();
	let dispatch = useDispatch();
	const logout_hook = useLogout();
	const PrivilagesList = useSelector(
		(state) => state?.privilageSlice?.allowedActions
	);
	const [searchParams] = useSearchParams()
	const [padding, setpadding] = useState(false)
	const release_lock = useReleaseLock()
	const chatRef = useRef(null)
	function handleLogout() {
		swal('Are you sure you want to Sign out?', {
			buttons: ['No', 'Yes'],
		}).then((value) => {
			if (value) {
				// User clicked "Yes" button
				handleLock(sessionStorage.getItem("last_doc_open"), 'last_doc_open')
				handleLock(sessionStorage.getItem("last_contract_open"), 'last_contract_open')

				dispatch(privilage_remove());
				dispatch(redux_logout())
				dispatch(reset_filter())
				handleSignOut();
				// Add your logout logic here
			} else {
				// User clicked "No" button or closed the dialog
				// Add any additional logic you want when the user clicks "No" or closes the dialog
			}
		});
	}
	const get_profile_hook = useGetProfile()
		useEffect(() => {
			if (get_profile_hook.isSuccess) {
				localStorage.setItem('username', get_profile_hook.data?.email);
			}
		}, [get_profile_hook.isSuccess]);

	useEffect(() => {
		if (logout_hook.isSuccess) {
			navigate('/');
		} else if (logout_hook.isError) {
			toast.error(logout_hook.error?.message);
		}
	}, [logout_hook.isSuccess, logout_hook.isError, logout_hook.error, navigate]);

	//const [searchParams, setSearchParams] = useSearchParams()
	//console.log(searchParams.get('pageNo')  , "TOP")
	//const [active, setactive] = useState('dashboard')
	let location = useLocation();
	//const pathNameArray = location.pathname.split('/')
	//const pathName = "/" + pathNameArray[1]

	const { instance } = useMsal();

	const handleSignOut = async () => {
		const logoutRequest = {
			account: instance.getActiveAccount(),
			// mainWindowRedirectUri: "/",
			postLogoutRedirectUri: "/",
			// onRedirectNavigate: () => {
			//     // Return false to stop navigation after local logout
			//     return false;
			// }
		};
		await instance.logoutRedirect(logoutRequest);
		navigate('/')
		// instance.logoutPopup({
		//     postLogoutRedirectUri: '/',
		//     mainWindowRedirectUri: '/',
		// });
	};
	useEffect(() => {
		if (window.location.pathname === '/my-annotations') {

			sessionStorage.removeItem("contract_history")
			sessionStorage.removeItem("contract_change")
			sessionStorage.removeItem("contract_disableSaveBtn")
			sessionStorage.removeItem("contract_disableSaveBtnTitle")
			handleLock(sessionStorage.getItem("last_contract_open"), 'last_contract_open')
		}
		else if ('/my-contract-details' === window.location.pathname) {
			sessionStorage.removeItem("history")
			sessionStorage.removeItem("change")
			sessionStorage.removeItem("disableSaveBtn")
			sessionStorage.removeItem("disableSaveBtnTitle")
			handleLock(sessionStorage.getItem("last_doc_open"), 'last_doc_open')
		}
		else {
			sessionStorage.removeItem("history")
			sessionStorage.removeItem("change")
			sessionStorage.removeItem("disableSaveBtn")
			sessionStorage.removeItem("disableSaveBtnTitle")

			sessionStorage.removeItem("contract_history")
			sessionStorage.removeItem("contract_change")
			sessionStorage.removeItem("contract_disableSaveBtn")
			sessionStorage.removeItem("contract_disableSaveBtnTitle")
			handleLock(sessionStorage.getItem("last_doc_open"), 'last_doc_open')
			handleLock(sessionStorage.getItem("last_contract_open"), 'last_contract_open')
		}

	}, [window.location.pathname])

	async function handleLock(doc_id, rm_itm) {

		if (doc_id) {
			let res = await release_lock.mutateAsync({ "document_id": doc_id })
			if (res?.message === "updated") {
				sessionStorage.removeItem(rm_itm)
				dispatch(remove_lock())

			}
		}
	}

	// Global Chatbot toggle
	const [toggle, setToggle] = useState(false);
	const handleChatToggle = () => {
		
		if (chatRef.current) { 
			if (chatRef.current.style.display ==="block" || chatRef.current.style.display ==="" ) {
				setToggle(!toggle);
			}
			else{
				chatRef.current.style.display = "block"

			}

			
		}
		else{
			setToggle(!toggle);
		}
	};
	function handleMinimize() {
		if (chatRef.current) { 
			chatRef.current.style.display = "none" 
		}
		
	}

	  const getNotifications = async () => {
		let reqToken = await getAccessToken()
		let config = {
			method: 'get',
				maxBodyLength: Infinity,
				url: `${BACKEND_URL}/get_notifications_count?search=&page=1&limit=20&sort=`,
				headers: {
					'Content-Type': 'application/json',
					Authorization: 'Bearer ' + reqToken,
				},
		};
		try {
			const response = await axios.request(config);
			//console.log(response.data.unread_total);
			setNotificationCount(response.data?.unread_total)
			if(response.data.new>0){
				if(response.data.new > 1){
					toast.success("You have "+response.data.new+" new notifications.");
				}
				else{
					toast.success("You have "+response.data.new+" new notification.");
				}
				
			}
			

		} catch (error) {
			toast.error('Unable to Connect to Server, Try again');
		}

	  };

	  useEffect(() => {
		getNotifications();
		const intervalCall = setInterval(() => {
		  getNotifications();
		}, 900000);
		return () => {
		  // clean up
		  clearInterval(intervalCall);
		};
	  }, []);
	  

	  return (
		<>
			<LicencePopup setpadding={setpadding} />
			{/* <TimeZonePopup /> */}
			{logout_hook.isPending ? (
				<LoaderFull />
			) : (
				<div className={`md:flex relative`}>
					{open ? (
						<div
							className={`${styles.sidebar} ${open ? 'openedSidebar' : ''} flex-none md:w-64 h-screen md:sticky md:left-0 md:top-0 px-0 py-5 overflow-x-hidden overflow-y-auto`}
						>
							<>
								<div className="flex flex-col justify-evenly h-full">
									<div className="flex-none mb-5 text-center">
										<Link to="/dashboard">
											<img
												className="inline-block"
												src="/svg/logo-innerp.svg"
												alt=""
											/>
										</Link>
									</div>
									<div className={`${styles.borderTLB} grow flex-none py-5`}>
										<ul className={styles.mainNav}>
											{/* <li>
                                        <Link to='/'>
                                            <span className='material-icons-outlined absolute text-md'>home</span>
                                            Home
                                        </Link>
                                    </li> */}
											<li
												className={`${location.pathname === '/dashboard' ? styles.active : ''}`}
											>
												<Link to="/dashboard">
													<span className="material-icons-outlined absolute text-md">
														dashboard
													</span>
													Dashboard
												</Link>
											</li>
											{PrivilagesList.includes('upload_document') ? (
												<li
													className={`${location.pathname === '/upload-docs' ? styles.active : ''}`}
												>
													<Link to="/upload-docs">
														<span className="material-icons-outlined absolute text-md">
															cloud_upload
														</span>
														Upload Documents
													</Link>
												</li>
											) : null}
											<li
												className={
													[
														'/my-clients',
														'/my-vendors',
														'/my-invoices',
														'/my-annotations',
													].includes(location.pathname)
														? styles.active
														: ''
												}
											>
												<Link
													to={`/my-clients?client_list_page_no=${searchParams.get('client_list_page_no') == null ? 1 : searchParams.get('client_list_page_no')}&client_list_sort_key=${searchParams.get('client_list_sort_key') == null ? '' : searchParams.get('client_list_sort_key')}&client_list_sort_order=${searchParams.get('client_list_sort_order') == null ? '' : searchParams.get('client_list_sort_order')}`}
													onClick={() => {
														dispatch(dispClientPageNo({ clientListPageNo: 1 }));
														dispatch(
															dispClientId({ clientListSelectedItem: null })
														);
													}}
												>
													<span className="material-icons-outlined absolute text-md">
														groups
													</span>
													My Clients
												</Link>
											</li>
											{PrivilagesList.includes('create_user') ? (
												<li
													className={
														['/manage-users', '/add-user'].includes(
															location.pathname
														)
															? styles.active
															: ''
													}
												>
													<Link to="/manage-users">
														<span className="material-icons-outlined absolute text-md">
															person
														</span>
														Manage Users
													</Link>
												</li>
											) : null}

											{PrivilagesList.includes('manage_expense_category') ||
												PrivilagesList.includes('manage_project_type') ? (
												<li
													className={
														['/manage-fields', '/add-field'].includes(
															location.pathname
														)
															? styles.active
															: ''
													}
												>
													<Link to="/manage-fields">
														<span className="material-icons-outlined absolute text-md">
															dashboard
														</span>
														Manage Fields
													</Link>
												</li>
											) : null}



											{/* {APP_ROUTE_ALLOWED['contract_feature_remove'] ? null : <li
												className={`${location.pathname === '/mismatch-list' ? styles.active : ''}`}
											>
												<Link to={`/mismatch-list?mismatch_list_page_no=${searchParams.get('mismatch_list_page_no') == null ? 1 : searchParams.get('mismatch_list_page_no')}&mismatch_list_sort_key=${searchParams.get('mismatch_list_sort_key') == null ? '' : searchParams.get('mismatch_list_sort_key')}&mismatch_list_sort_order=${searchParams.get('mismatch_list_sort_order') == null ? '' : searchParams.get('mismatch_list_sort_order')}`}>
													<span className="material-icons-outlined absolute text-md">
														dataset
													</span>
													Mismatches
												</Link>
											</li> } */}

											{PrivilagesList.includes('view_notifications') ? (
												<li
												className={`${location.pathname === '/notifications' ? styles.active : ''}`}
											>
												<Link to="/notifications">
													<span className="material-icons-outlined absolute text-md">
														notifications
													</span>
													Notifications
													<i className={styles.notiCount}>{notificationCount}</i>
												</Link>
											</li> ) : null}

											{PrivilagesList.includes('change_rules') ? ( <li
													className={
														['/manage-validations'].includes(
															location.pathname
														)
															? styles.active
															: ''
													}
												>
													<Link to="/manage-validations">
														<span className="material-icons-outlined absolute text-md">
														library_add_check
														</span>
														Manage Validations
													</Link>
												</li> ) : null }


											{APP_ROUTE_ALLOWED['contract_feature_remove'] ? null : <li
												className={`${(location.pathname === '/mismatch-list' || location.pathname === '/final-mismatch-list') ? styles.active : ''}`}
											>
												<Link to={`/mismatch-list?mismatch_list_page_no=${searchParams.get('mismatch_list_page_no') == null ? 1 : searchParams.get('mismatch_list_page_no') }&mismatch_list_sort_key=${searchParams.get('mismatch_list_sort_key') == null ? '' : searchParams.get('mismatch_list_sort_key') }&mismatch_list_sort_order=${searchParams.get('mismatch_list_sort_order')== null ? '' : searchParams.get('mismatch_list_sort_order') }`}>
												<span className="material-icons-outlined absolute text-md">
													dataset
                                               </span>
													Mismatches												
												</Link>
											</li> }

											

											{/* <li
												className={`${location.pathname === '/notifications' ? styles.active : ''}`}
											>
												<Link to="/notifications">
													<span className="material-icons-outlined absolute text-md">
														notifications
													</span>
													Notifications
												</Link>
											</li> */}
										</ul>
									</div>
									{/* <div className={`${styles.borderYLB} grow flex flex-col justify-center py-5`}>
                                <ul className={styles.setNav}>
                                    <li>
                                        <button onClick={handleLogout} >
                                            <span className='material-icons-outlined absolute text-md'>logout</span>
                                            Sign out
                                        </button>
                                    </li>
                                </ul>
                            </div> */}
									<div
										className={`${styles.borderTLB} flex-none flex flex-col justify-end py-4`}
									>
										<ul className={styles.setNav}>
											<li>
												<button onClick={handleLogout}>
													<span className="material-icons-outlined absolute text-md">
														logout
													</span>
													Sign out
												</button>
											</li>
										</ul>
										<Link
											to="/profile"
											className={`p-4 flex gap-4 items-center ${location.pathname === '/profile' ? styles.proActive : ''}`}
										>
											<div className="flex-none">
												<span className="proIco">
													{localStorage.getItem('username') !== null &&
														localStorage.getItem('username')?.[0].toUpperCase()}
												</span>
											</div>
											<div className="grow">
												{/* <p className='text-primary'>{localStorage.getItem('username')}</p> */}
												<p className="text-md text-primary break-words capitalize mb-1">
													{localStorage.getItem('name')}
												</p>
												<p
													className="text-slate-400 text-xs overflow-hidden whitespace-nowrap text-ellipsis max-w-[150px]"
													title={localStorage.getItem('username')}
												>
													{localStorage.getItem('username')}
												</p>
											</div>
										</Link>
									</div>
								</div>
							</>
						</div>
					) : null}

					{/* <button onClick={handleClick}
                className={`${styles.shHandle} text-white w-[26px] text-center px-1 rounded-md`}
                title={open ? 'Hide Sidebar' : 'Show Sidebar'}
            >
                <span className='material-icons-outlined text-md text-blue-800'>
                    {open ? 'arrow_circle_left' : 'arrow_circle_right'}                    
                </span>
            </button> */}
					<div className={`${padding ? 'pt-14' : ''} grow flex md:flex-none ${styles.outLetWd}`}>
						
						<Outlet context={{getNotifications}}/>
					</div>
				</div>
			)}

			{/* Global Chatbot */}
			<div className={styles.botIconBG}>
				<button onClick={handleChatToggle} type='button' className={styles.botIcon}></button>
			</div>
			{toggle ?
				<div ref={chatRef} className='overflow-hidden bg-white rounded-lg shadow-lg fixed bottom-5 right-2 w-[560px] z-[999]'>
					<div className='p-4 relative'>
					<button onClick={()=> handleMinimize()} type='button' className='absolute z-20 top-3 right-12'>
							<span class="material-icons-outlined text-md text-gray-400">minimize</span>
						</button>
						<button onClick={handleChatToggle} type='button' className='absolute z-20 top-3 right-3'>
							<span class="material-icons-outlined text-md text-gray-400">cancel</span>
						</button>
						<div className='mt-[-4px] mb-2'>
							<img className='h-10' src='/svg/ask-bot.svg' alt='' />
						</div>
						<p className='text-[11px] text-gray-400'>This bot is designed to answer contract-related questions only.</p>
					</div>

					<GlobalChatIndex/>
					
				</div>
				:
				null
			}
		</>
	);
};