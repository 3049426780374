import React, { useState, useEffect } from 'react';
//import { toast } from 'react-toastify'

import { Autocomplete, TextField } from '@mui/material';
import { GetVendors } from './hooks';
import {Box } from '@mui/material'
function FilterVendorName({
    setVendorName,
    VendorName,
    setVendorNameId,
    VendorNameId,
    required,
    CategoryId,
    ClientId,
    AnalystId
}) {

    //console.log(required);
    const [lable, setLable] = useState('Vendor Name')

    useEffect(() => {
        if (required === 'true') {
            setLable('Vendor Name*')
        }
    }, [required]);


    const [options, setoptions] = useState([]);
    const client_list_hook = GetVendors();
    const [Focus, setFocus] = useState(false);

    //const [lable,setLable] = useState(required)

    useEffect(() => {
        client_list_hook.mutate({CategoryId,ClientId,AnalystId});
    }, [CategoryId,ClientId,AnalystId]);

    useEffect(() => {
        if (client_list_hook.data) {
            setoptions(client_list_hook.data.data.map((itm,idx) => {
                
                return {
                    name:itm.vendor_name,
                    id:itm.vendor_id
                }

            }));
        }
    }, [client_list_hook.data,CategoryId,ClientId]);

    function changeProjectType(val) {
        console.log(val);
        setVendorName(val);
        
        if (val) {
            let obj = client_list_hook.data.data.find((o) => (o.vendor_id === val.id));
            console.log(obj);
            
            if (obj) {
                setVendorNameId(obj.vendor_id);
            } else {
                setVendorNameId(null)
                setVendorName(null)
            }
            
        }
        else {
            setVendorNameId(null)
            setVendorName(null)
        }
    }

    function checkExist(VendorName){
        let isPresent = false;
        if(VendorName){
            isPresent = options.some(function(el){ return el.id === VendorName.id});
            //console.log(isPresent);
        }
        
        return isPresent;
    }

    return (
        <>
            <div className="grow missDD">
           
                <div className="lightT">
                    
                    {client_list_hook.isPending ? (
                        <p className="px-4 py-2 border border-1 border-slate-300 rounded-md">
                            <span className="text-sm text-slate-400 ps-4 inline-block">
                                Loading...
                            </span>
                        </p>
                    ) : (
                        <Autocomplete
                            disablePortal
                            isOptionEqualToValue={(option, value) => option.id === value.id}
                            id="combo-box-demo"
                            className='expCat'
                            options={options}
                            value={(checkExist(VendorName)?VendorName:null) || null}
                            getOptionLabel={(option) => option.name}
                            renderInput={(params) => (
                                <TextField {...params} label={lable} />
                            )}
                            onChange={(event, value) => changeProjectType(value)}
                            onFocus={() => setFocus(true)}
                            onBlur={() => setFocus(false)}
                            //getOptionLabel={(option) => option.fname}
                            renderOption={(props, option) => {
                                return (
                                    <Box component="li" {...props} key={option.id}>
                                        {option.name}
                                    </Box>
                                )
                            }}
                        />
                    )}

                </div>
            </div>
        </>
    );
}

export default FilterVendorName;
