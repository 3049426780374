import {useQuery } from '@tanstack/react-query';
import { useDispatch } from 'react-redux';
import { LogoutCheck } from '../../utils';
import { buildAxiosInstance } from '../../axiosInstance';
import { useMsal } from '@azure/msal-react';

export const useGetPricing = (doc_id) => {
	let dispatch = useDispatch();
	const { instance } = useMsal();
	return useQuery({
		queryKey: [`pricing_tables${doc_id}`],
		retry: false,
		queryFn: async ({signal}) => {

			try {
				const TOAST_ID = 'consolidated_contrat_view'
				const axiosInstance = buildAxiosInstance(TOAST_ID)
				let config = {
					params: {document_id: doc_id},
					signal: signal,
				};
				const responseData = await axiosInstance.get('/consolidated_contrat_view',config)
				return responseData
			} catch (error) {
				LogoutCheck(error, dispatch,instance)	
				
			}
		},
	});
};