import React, { useState, useEffect } from 'react';
import { Autocomplete, Box, TextField } from '@mui/material'

import { useFieldNames } from './hooks';

function FilterInvoiceStatus({
    setInvoiceStatus,
    InvoiceStatus,
    InvoiceStatusId,
	setInvoiceStatusId,
    ClientId,
    CategoryId,
    VendorNameId
}) {
    
    const [options, setoptions] = useState([]);
    const client_list_hook = ['Pending','Completed','Draft'];
    
    const [Focus, setFocus] = useState(false);

	useEffect(() => {

		setoptions(client_list_hook.map((itm) => itm));
	}, []);

    function changeProjectType(val) {
        setInvoiceStatus(val);
        setInvoiceStatusId(val)
    }

    return (
        <>
            <div className="grow missDD">
                <div className="lightT">

                    {client_list_hook.fetchStatus === 'fetching' ? (
                        <p className="px-4 py-2 border border-1 border-slate-300 rounded-md">
                            <span className="text-sm text-slate-400 ps-4 inline-block">
                                Loading...
                            </span>
                        </p>
                    ) : (
                        <Autocomplete
                            disablePortal
                            isOptionEqualToValue={(option, value) =>
                                option === value || value === '' || value == null
                            }
                            id="combo-box-demo"
                            className='expCat'
                            options={options}
                            value={InvoiceStatus}
                            getOptionLabel={(option) => option}
                            renderOption={(props, option) => {
                                return (
                                    <Box component="li" {...props} key={option}>
                                        {option}
                                    </Box>
                                )
                            }}
                            renderInput={(params) => (
                                <TextField {...params} label="Invoice Status" />
                            )}
                            onChange={(event, value) => changeProjectType(value)}
                            onFocus={() => setFocus(true)}
                            onBlur={() => setFocus(false)}
                        />
                    )}
                   
                </div>
            </div>
        </>
    );
}

export default FilterInvoiceStatus;
