import React, { useEffect, useReducer, useRef, useState } from 'react';
import styles from '../annotation/invoice.module.css';
import { useGetImgUrlByPage } from './hooks';
import LoaderFull from '../shared/loader-full-screen';
import { AreaSelector } from '@bmunozg/react-image-area';
import CsvDataDisplay from './csv-display';
import ConsolidatedPricingView from './pricing_tables/pricingIndex';

function isCSV(Data) {
	return Data?.linked_contracts[0]?.contract_url?.split('.').at(-1) === 'csv'
}

function ContractPane({ Data , showPricing }) {
	
	const [imgSize, setimgSize] = useState('768')
	const [imgsData, setimgData] = useState(() => {
		if (isCSV(Data)){
			let ret = {}
			Data.linked_contracts.map(contract => {
				ret[contract.contract_url] = {
					image_url: null
				}
			})
			return ret 
		}
		let ret = {};
		Data.linked_contracts.map(contract => {
			contract.contract_data_pagewise.map(page_wise_contract => {
				ret[page_wise_contract.image_path] = {
					image_url: null,
					image_height: page_wise_contract.image_height,
					image_width: page_wise_contract.image_width,
					contract_id: contract.contract_id,
					page_no: page_wise_contract.contract_page_no
				}
			})
		})
		return ret;
	});
	const [Margin] = useState(20);
	const [, render] = useReducer((p) => !p, false);
	const [PageNo, setPageNo] = useState(0)
	
	const [PagetoContract, setPagetoContract] = useState(() => {

		if(isCSV(Data)) {
			return { "0": { contract_page_no : 1 , contract_id : Data.linked_contracts[0].contract_id , image_path: Data.linked_contracts[0].contract_url  } }
		}

		var pgNo = 0
		let ret = {}

		Data.linked_contracts.map(contract => {
			contract.contract_data_pagewise.map(page_wise_contract => {
				ret[pgNo] = {
					contract_page_no: page_wise_contract.contract_page_no,
					contract_id: contract.contract_id,
					image_path: page_wise_contract.image_path
				}
				pgNo += 1
				return null
			})
			return null
		})
		return ret
	})


	const [Boxes, setBoxes] = useState(() => {
		if (isCSV(Data)) { 
			return [0,0,0,0]
		}
		return GetBoxes(PagetoContract[PageNo].contract_id, PagetoContract[PageNo].contract_page_no, parseInt(imgSize))
	})

	useEffect(() => {
		let ret = {};
		if (isCSV(Data)){
			Data.linked_contracts.map(contract => {
				ret[contract.contract_url] = {
					image_url: null
				}
				return null
			})
		}
		else{
			Data.linked_contracts.map(contract => {
				contract.contract_data_pagewise.map(page_wise_contract => {
					ret[page_wise_contract.image_path] = {
						image_url: null,
						image_height: page_wise_contract.image_height,
						image_width: page_wise_contract.image_width,
						contract_id: contract.contract_id,
						page_no: page_wise_contract.contract_page_no
					}
					return null
				})
				return null
			})

		}

		setimgData(ret)


		var pgNo = 0
		let sp = {}

		if(isCSV(Data)) {
			sp = { "0": { contract_page_no : 1 , contract_id : Data.linked_contracts[0].contract_id , image_path: Data.linked_contracts[0].contract_url  } }
		}
		else {
			Data.linked_contracts.map(contract => {
				contract.contract_data_pagewise.map(page_wise_contract => {
					sp[pgNo] = {
						contract_page_no: page_wise_contract.contract_page_no,
						contract_id: contract.contract_id,
						image_path: page_wise_contract.image_path
					}
					pgNo += 1
					return null
				})
				return null
			})
		}

		setPagetoContract(sp)

	}, [Data])
	



	const get_url_hook = useGetImgUrlByPage(imgsData)


	function coordinateToBox(cords, scaleFactor) {

		// return [x(top) , y(left), ,height , width]
		return [scaleFactor*cords[0], scaleFactor*cords[1], scaleFactor*(cords[3] - cords[1]), scaleFactor*(cords[2] - cords[0])];
	}

	function GetBoxes(contract_id, page_no, newWidth) {
		let contract = Data.linked_contracts.filter(itm => itm.contract_id === contract_id)[0]

		let page_wise_data = contract?.contract_data_pagewise.filter(itm => itm.contract_page_no === page_no)[0]
		var bxs1 = []
		var bxs2 = []
		if (page_wise_data && 'price_entities' in page_wise_data) { 

			bxs1= page_wise_data.price_entities.map(itm => {
				if ('entity_box' in itm && 'entity_key' in itm && 'entity_value' in itm) {
					var scaleFactor = newWidth/page_wise_data.image_width
					var [x1, y1, h1, w1] = coordinateToBox(itm.entity_box, scaleFactor);
					return {
						x: x1,
						y: y1,
						height: h1,
						width: w1,
						unit: 'px',
						isNew: false,
						isChanging: false,
						entity: itm.entity_key,
						value: itm.entity_value
					}
				}
				return null
			})
		}
		if (page_wise_data && 'clause_entities' in page_wise_data) { 
			bxs2 = page_wise_data.clause_entities.map(itm => {
				if ('clause_box' in itm && 'clause_text' in itm) {
					var scaleFactor = newWidth/page_wise_data.image_width
					let [x1, y1, h1, w1] = coordinateToBox(itm.clause_box, scaleFactor);
					return {
						x: x1,
						y: y1,
						height: h1,
						width: w1,
						unit: 'px',
						isNew: false,
						isChanging: false,
						entity: itm.clause_text,
						value: itm.clause_text
					}
				}
				return null
			})
		
		}
		
		let final_boxes = [...bxs1, ...bxs2]




		return final_boxes
	}

	const cumulativeSumRef = useRef([]);

	useEffect(() => {
		if (isCSV(Data)) { 
			return 
		}
	  let cumulativeSum = [0];
	  let lastSum = 0;
	  
	  Object.values(imgsData).forEach(obj => {
		const imageHeight = parseInt(obj.image_height)
		const imageWidth = parseInt(obj.image_width)
		
		const sum = lastSum + (parseInt(imgSize) * imageHeight / imageWidth) + Margin
		cumulativeSum.push(sum);
		lastSum = sum;
	  });
  
	  cumulativeSumRef.current = cumulativeSum
	//   let scaleFactor= imgSize/prevWidth.current
	  setBoxes(()=> {
		return GetBoxes(PagetoContract[PageNo].contract_id, PagetoContract[PageNo].contract_page_no, parseInt(imgSize))
	  })
	}, [imgSize])

	useEffect(() => {
		if (isCSV(Data)) { 
			return 
		}
		setBoxes(()=> {
			return GetBoxes(PagetoContract[PageNo].contract_id, PagetoContract[PageNo].contract_page_no, parseInt(imgSize))
		  })
	}, [PageNo])
	


	function scrollToPageNo(scrollOffset) {
		let low = 0;
		let high = cumulativeSumRef.current.length - 1;
		while (low <= high) {
			let mid = Math.floor((low + high) / 2);
			if (cumulativeSumRef.current[mid] === scrollOffset) {
				return mid;
			} else if (cumulativeSumRef.current[mid] < scrollOffset) {
				low = mid + 1;
			} else {
				high = mid - 1;
			}
		}
		return low - 1;
	}

	const divRef = useRef(null);
	const primaryPageNo = useRef(0);
	const secondaryPageNo = useRef(0);

	useEffect(() => {
		function debounce(fn, delay) {
			let context = this;
			let setTimeoutId;

			return function () {
				if (setTimeoutId) {
					window.clearTimeout(setTimeoutId);
				}
				setTimeoutId = window.setTimeout(() => {
					fn.apply(context, arguments);
					setTimeoutId = null;
				}, delay);
			};
		}
		const handleScroll = () => {
			if (divRef.current) {
				const scrollTop = divRef.current.scrollTop;
				let pgNo = scrollToPageNo(scrollTop);
				let h1 = cumulativeSumRef.current[pgNo + 1] - scrollTop;

				let h2 = divRef.current.offsetHeight - h1;
				if (h1 > h2 || pgNo === Object.keys(imgsData).length - 1) {
					primaryPageNo.current = pgNo;
					secondaryPageNo.current = pgNo + 1;
				} else {
					primaryPageNo.current = pgNo + 1;
					secondaryPageNo.current = pgNo;
				}
				render();
			}
		};

		// Attach the scroll event listener
		if (divRef.current) {
			divRef.current.addEventListener('scroll', debounce(handleScroll, 100));
		}
		let observerRefValue = divRef?.current;

		// Cleanup the event listener
		return () => {
			if (observerRefValue) {
				observerRefValue.removeEventListener('scroll', handleScroll);
			}
		};
	}, []);


	useEffect(() => {
		if (primaryPageNo.current < 0 || primaryPageNo.current >= Object.keys(imgsData).length) {
			return;
		}
		
		get_url_hook.hook.mutate(PagetoContract[primaryPageNo.current]?.image_path);
		if (!isCSV(Data)){
			get_url_hook.hook.mutate(PagetoContract[secondaryPageNo.current]?.image_path);
		}
		

		setPageNo(primaryPageNo.current);
	}, [primaryPageNo.current]);



	function imgLoaded(id) {
		let id2 = 'dummy_' + id;
		document.getElementById(id2).style.display = 'none';
		document.getElementById(id).style.display = 'block';
	}

	function handleError(pg) {
		get_url_hook.setimgObj((prev) => {
			return {
				...prev,
				[pg]: {
					...prev[pg],
					image_url: null,
				},
			};
		});
		// setTimeout(() => {
		//     get_url_hook.hook.mutate(pg)
		// }, 500);
	}





	const customRender = (props)=> {
		return (
			<div className="areaBoxOuter">
	
				</div>
			
		)
	}	

	return (
		<>
			<div className={`h-full ${styles.compHldr} overflow-auto bg-gray-100 rounded-md`}>
				
			{showPricing ? <div><ConsolidatedPricingView contract_id={PagetoContract[PageNo].contract_id} /></div> : 
				<div id="parent" ref={divRef} className="h-full w-full overflow-scroll">
				{!isCSV(Data)&&<div className='bg-white shadow-md border rounded-full px-3 py-2 absolute top-0 right-5 z-20 flex items-center'>

						<button className='mx-2 border-0 leading-3' onClick={() => setimgSize(p => (parseInt(p) + 40).toString())}>
							<span
								title={'increase size'}
								className="material-icons text-sm leading-none  cursor-pointer text-blue-800"
							>
								add
							</span>
						</button>
						<button className='mx-2 border-0 leading-3' onClick={() => setimgSize(p => (parseInt(p) - 40).toString())}>
							<span
								title={'increase size'}
								className="material-icons text-sm leading-none  cursor-pointer text-blue-800"
							>
								remove
							</span>
						</button>
					</div> }
					{isCSV(Data) ?  <div>
												<CsvDataDisplay csvUrl={get_url_hook.imgObj[Data.linked_contracts[0].contract_url]?.image_url} />
											</div> : 
											<>
											

					{Object.keys(imgsData).map((image_path, ind) => {
						return (
							<div key={`main_page_contract_${ind}`} className='relative' >

								<div
									key={`dummy_contract_box_${ind}`}
									id={`dummy_contract_box_id_${ind}`}
									style={{
										height: (parseInt(imgSize)*imgsData[image_path].image_height/imgsData[image_path].image_width).toString()+'px',
										width: imgSize + 'px' ,
										margin: Margin
									}}
									className=" bg-gray-400 flex flex-col justify-center items-center"
								>
									<LoaderFull />
								</div>
								<div style={{
									height: (parseInt(imgSize)*imgsData[image_path].image_height/imgsData[image_path].image_width).toString()+'px',
									width: imgSize + 'px' ,

								}} className='absolute left-0 top-0 z-10' ></div>
								<div
									style={{
										display: 'none',
										height: (parseInt(imgSize)*imgsData[image_path].image_height/imgsData[image_path].image_width).toString()+'px',
										width: imgSize + 'px' ,
										margin: Margin
									}}
									id={`contract_box_id_${ind}`}
									key={`contract_box_${ind}`}
								>

									{secondaryPageNo.current === PageNo - 1 &&
										secondaryPageNo.current === ind &&
										get_url_hook.imgObj[image_path]?.image_url !==
										null && (
											<img
												alt='pg-1'
												src={
													get_url_hook.imgObj[image_path]
														?.image_url
												}
												className="max-w-none"
												style={{
													width: imgSize + 'px'
												}}
											></img>
										)}
									{Boxes &&
										PageNo === ind &&
										get_url_hook.imgObj[image_path]?.image_url !==
										null && (




											<AreaSelector
												areas={Boxes}
												minHeight={5}
												minWidth={5}
												wrapperStyle={{}}
												globalAreaStyle={{
													border: '2px solid #47a6ff',
													backgroundColor: 'rgba(255,255,255,0.1)',
													opacity: '1',
												}}

												customAreaRenderer={customRender}
											>
												<img
													alt='pg'
													onError={() => handleError(image_path)}
													onLoad={() => imgLoaded(`contract_box_id_${ind}`)}
													src={
														get_url_hook.imgObj[image_path]?.image_url
													}
													className="max-w-none"
													style={{
														width: imgSize + 'px'
													}}
												></img>
											</AreaSelector>


										)}
									{secondaryPageNo.current === PageNo + 1 &&
										secondaryPageNo.current === ind &&
										get_url_hook.imgObj[image_path]?.image_url !==
										null && (
											<img
												alt='pg-2'
												src={
													get_url_hook.imgObj[image_path]?.image_url
												}
												className="max-w-none"
												style={{
													width: imgSize + 'px'
												}}
											></img>
										)}
								</div>
							</div>
						);
					})}</> }
				</div> }
			</div>
		</>
	);
}

export default ContractPane;
