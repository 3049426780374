import React, { useState } from 'react';
import Checkbox from '@mui/material/Checkbox';
import {
	createSearchParams,
	useNavigate,
	useOutletContext,
	useSearchParams,
} from 'react-router-dom';
import { TextHighlighter } from '../shared/textBold';
import { decodeName, encodeName } from '../utils';
import { useSelector } from 'react-redux';
import TimeZoneConverter from '../shared/timezone';

export const InvoicesListingData = () => {

	const Data = useSelector(state => state.auth)
	const { invoiceList, Search, selectedInvoices , setselectedInvoices, setuatHeader } = useOutletContext();
	const navigate = useNavigate();
	const [searchParams] = useSearchParams();
	function handleNavigate(obj) {
		const encodedClientName = encodeName(decodeName(searchParams.get('client_name')))
		const encodedVendorName = encodeName(decodeName(searchParams.get('vendor_name')))
		const encodeInvoiceName = encodeName(obj.fname);
		
		
		navigate({
			pathname : '/my-annotations' ,
			search: createSearchParams({ 
				client_id: searchParams.get('client_id'),
				client_name: encodedClientName,
				client_list_page_no: searchParams.get('client_list_page_no'),
				client_list_sort_key: searchParams.get('client_list_sort_key'),
				client_list_sort_order: searchParams.get('client_list_sort_order'),

				vendor_id: searchParams.get('vendor_id'),
				vendor_name: encodedVendorName,
				vendor_list_page_no : searchParams.get('vendor_list_page_no'),
				vendor_list_sort_key: searchParams.get('vendor_list_sort_key'),
				vendor_list_sort_order: searchParams.get('vendor_list_sort_order'),
				category_name: encodeName(decodeName(searchParams.get('category_name'))),
				category_id: encodeName(decodeName(searchParams.get('category_id'))),

				invoice_id: obj._id,
				invoice_name: encodeInvoiceName,
				invoice_list_page_no : searchParams.get('invoice_list_page_no'),
				invoice_list_sort_key: searchParams.get('invoice_list_sort_key'),
				invoice_list_sort_order: searchParams.get('invoice_list_sort_order'),


				
			}
			).toString() 
		} )
	}

	function handleCheckBoxRowwise(checked, doc_id){
		if (checked) { 
			let tp = new Set(selectedInvoices)
			tp.add(doc_id)
			setselectedInvoices(tp)
		}
		else { 
			let tp = new Set(selectedInvoices)
			tp.delete(doc_id)
			setselectedInvoices(tp)
		}
	}
	return (
		<>
			{invoiceList?.data?.map((itm, ind) => {
				return (
					<div key={`vendor` + ind} className="flex gap-2 mb-2 tblLoop">
						<div className="flex-none w-[40px] flex items-center">
							<Checkbox disabled={itm?.csv_upload} onChange={(e)=> handleCheckBoxRowwise(e.target.checked, itm._id)} checked={selectedInvoices.has(itm._id)} size="small" />
						</div>
						<div className="flex-none w-[50px]">
							<label className="vLBG flex items-center px-3 h-[53px] rounded-md text-center text-white relative text-sm">
							   	<img className="h-8" src={itm?.csv_upload ? "/svg/folder_icon.svg" : "/svg/ri_building-fill.svg"} alt="" />
							</label>
						</div>
						<div className="grow border rounded-md px-5 py-3 text-sm flex items-center justify-between">
							<div className="min-w-[120px] relative flex grow border-r pr-4 items-center">
								<span
									title={itm.fname}
									className="absolute w-full block text-ellipsis whitespace-nowrap overflow-hidden"
								>
									{/* {itm.fname} */}
									<TextHighlighter text={itm.fname} searchString={Search} />
								</span>
							</div>
							<div className="flex-none border-r px-4 text-center w-[150px] relative flex items-center">
								<span
									title={itm.uploaded_by}
									className="absolute w-full block text-ellipsis whitespace-nowrap overflow-hidden"
								>
									{itm?.uploaded_by}
								</span>
							</div>
							<div className="flex-none border-r px-4 text-center w-[140px] relative flex items-center">
								<span
									title={itm.disp_status}
									className="absolute w-full block text-ellipsis whitespace-nowrap overflow-hidden"
								>
									{itm?.locked_by && Data.is_locked ? 'Annotating':itm?.disp_status}
								</span>
							</div>
							<div className="flex-none border-r px-4 text-center w-[180px]">
								{/* {formatDate(itm.uploaded_at)} */}
								<TimeZoneConverter setuatHeader={setuatHeader} utcTimestamp={itm.uploaded_at} />
							</div>
							<div className="flex-none border-r px-4 text-center w-[190px]">
								{/* {formatDateUpdatedAt(itm.updated_at)} */}
								<TimeZoneConverter setuatHeader={setuatHeader} utcTimestamp={itm.updated_at} />
							</div>
							<div className="flex-none border-r px-4 text-center w-[190px]">
								{/* {formatDate(itm.published_on)} */}
								<TimeZoneConverter setuatHeader={setuatHeader} utcTimestamp={itm.published_on} />
							</div>
							{(function validCol() {
								if (itm?.total_correct_entities === null || itm?.total_entities_captured === null) {
									return (<div className="flex-none border-r px-4  text-center w-[160px] text-xs">
										<span className="inline-block w-2 h-2  rounded-full mr-2"></span>
										
									</div>)
								}
								else if (itm?.total_correct_entities === itm?.total_entities_captured && itm?.total_entities_captured > 0) {
									return (
										<div className="flex-none border-r px-4  text-green-500 text-center w-[160px] text-xs">
											<span className="inline-block w-2 h-2 bg-green-500 rounded-full mr-2"></span>
											Validated:{itm?.total_correct_entities + '/' + itm?.total_entities_captured}
										</div>
									)
								}
								else {
									return (
										<div className="flex-none border-r px-4  text-red-500 text-center w-[160px] text-xs">
											<span className="inline-block w-2 h-2 bg-red-500 rounded-full mr-2"></span>
											Validated:{itm?.total_correct_entities + '/' + itm?.total_entities_captured}
										</div>
									)
								}

							})()}

							{itm.percentage_complete === 100 ? <div className="flex-none px-4 text-center w-[150px] text-xs">
								<span className="inline-block w-2 h-2 rounded-full bg-gray-600 mx-1"></span>
								<span className="inline-block w-2 h-2 rounded-full bg-gray-600 mx-1"></span>
								<span className="inline-block w-2 h-2 rounded-full bg-gray-600 mx-1"></span>
								{itm.percentage_complete}%
							</div> :
								<div className="flex-none px-4 text-center w-[150px] text-xs">
									<span className="inline-block w-2 h-2 rounded-full bg-gray-600 mx-1"></span>
									<span className="inline-block w-2 h-2 rounded-full bg-gray-400 mx-1"></span>
									<span className="inline-block w-2 h-2 rounded-full bg-gray-200 mx-1"></span>
									{itm.percentage_complete}%
								</div>}
							<div className="flex-none border-r px-4 text-center w-[150px] text-sm">
								{itm?.null_value}
							</div>
							<div className="flex-none border-r px-4 text-center w-[150px] text-sm">
								{itm?.total_mismatch_captured}
							</div>
						</div>
						<div className="flex-none flex w-[106px] pr-2">
							<button
								disabled={itm.percentage_complete<100}
								onClick={() => handleNavigate(itm)}
								type="button"
								title={itm.percentage_complete<100 ? 'Processing Invoice' : ''}
								className="vLBG block px-0 py-3 w-full text-center text-primary text-sm rounded-md font-black mx-1 disabled:bg-gray-200 disabled:text-gray-500"
							>
								{itm?.csv_upload ? 'View CSV' : 'View' }
							</button>
						</div>
					</div>
				);
			})}
		</>
	);
};

function formatDate(inputDateString) {
	if (!inputDateString) return '-'
	// Create a Date object from the input date string
	var date = new Date(inputDateString);

	// Extract day, month, and year
	var day = date.getDate();
	var month = date.getMonth() + 1; // Note: getMonth() returns 0-indexed month, so we add 1
	var year = date.getFullYear();

	// Format day and month to have leading zeros if necessary
	day = day < 10 ? '0' + day : day;
	month = month < 10 ? '0' + month : month;

	// Construct the formatted date string
	var formattedDateString = month + '/' + day + '/' + year;

	// Return the formatted date string
	return formattedDateString;
}

function formatDateUpdatedAt(inputDateString) {
	if (inputDateString === undefined || inputDateString === null ) return '-'
	// Create a Date object from the input date string
	var date = new Date(inputDateString);
	// Extract day, month, and year
	var day = date.getDate();
	var month = date.getMonth() + 1; // Note: getMonth() returns 0-indexed month, so we add 1
	var year = date.getFullYear();
	let HH = date.getHours() ;
	let mm = date.getMinutes()
	let ss = date.getSeconds();

	// Format day and month to have leading zeros if necessary
	day = day < 10 ? '0' + day : day;
	month = month < 10 ? '0' + month : month;

	if(HH<10)
    {
		HH=`0${HH}`;
	}
	if(mm<10)
	{
		mm=`0${mm}`;
	}
	if(ss<10)
	{
		ss=`0${ss}`;
	}
	
	let period = HH >= 12 ? 'PM' : 'AM';

    // Convert hours from 24-hour format to 12-hour format
    let hh = HH % 12;
    hh = hh ? hh : 12; // Adjust if hours is 0

	// Construct the formatted date string
	var formattedDateString = month + '/' + day + '/' + year +" "+ hh + ':' + mm + ':' + ss + ' ' + period

	// Return the formatted date string
	return formattedDateString;
}
