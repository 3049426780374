import React, { useState } from 'react';
import { useOutletContext, useNavigate } from 'react-router-dom';
import { createSearchParams } from "react-router-dom";
import TimeZoneConverter from '../shared/timezone';

export const MismatchFinalListingData = () => {
	//const { mismatchList } = useOutletContext();
	const { mismatchList, setuatHeader } = useOutletContext();
	const [titleDate, settitleDate] = useState('')
	//let dispatch = useDispatch()
	const navigate = useNavigate();
	function gotoMismatch(mismatch_id, client_name,client_id,  vendor_name,vendor_id, invoice_name ,invoice_id,category_id, category_name){
		navigate({
			pathname : '/comparison',
			search: createSearchParams({ 

				mismatch_id,
				client_id,
				client_name,
				client_list_page_no: 1,
				client_list_sort_key: '',
				client_list_sort_order: '',

				vendor_id,
				vendor_name,
				vendor_list_page_no : 1,
				vendor_list_sort_key: '',
				vendor_list_sort_order: '',
				category_name,
				category_id,

				invoice_id,
				invoice_name,
				invoice_list_page_no:1,
				invoice_list_sort_key:'',
				invoice_list_sort_order:''
			}
			).toString() 
		} )
	}

	function gotoInvoice(client_name,client_id,  vendor_name,vendor_id, invoice_name ,invoice_id,category_id, category_name){
		navigate({
			pathname : '/my-annotations',
			search: createSearchParams({ 

				
				client_id,
				client_name,
				client_list_page_no: 1,
				client_list_sort_key: '',
				client_list_sort_order: '',

				vendor_id,
				vendor_name,
				vendor_list_page_no : 1,
				vendor_list_sort_key: '',
				vendor_list_sort_order: '',
				category_name,
				category_id,

				invoice_id,
				invoice_name,
				invoice_list_page_no:1,
				invoice_list_sort_key:'',
				invoice_list_sort_order:''
			}
			).toString() 
		} )
	}

	function gotoContract(contract_id,contract_name,client_name,client_id,  vendor_name,vendor_id,category_id, category_name){
		navigate({
			pathname: '/my-contract-details',
			search: createSearchParams({
				client_id,
				client_name,
				client_list_page_no: 1,
				client_list_sort_key: '',
				client_list_sort_order: '',

				vendor_id,
				vendor_name,
				vendor_list_page_no : 1,
				vendor_list_sort_key: '',
				vendor_list_sort_order: '',
				category_name,
				category_id,

				contract_id: contract_id,
				contract_name: contract_name,
				contract_list_page_no:1,
				contract_list_sort_key:'',
				contract_list_sort_order:''



			}
			).toString()
		})
	}
	
	return (
		<>
			{mismatchList?.data?.mismatches?.map((itm, ind) => {
				//console.log(itm);
				
				return (
					<div key={`itm` + ind} className="flex gap-2 mb-2 tblLoop">
						{/* <div className="flex-none w-[40px] flex items-center">
						<Checkbox onChange={(e)=> handleCheckBoxRowwise(e.target.checked, itm._id,itm.invoice_id)} checked={selectedInvoices.has(itm._id)} size="small" />
							</div> */}
						<div className="flex-none">
							<label className="vLBG flex items-center px-3 h-[53px] rounded-md text-center text-white relative text-sm">
								<img className="h-8" src="/svg/ri_building-fill.svg" alt="" />
							</label>
						</div>
						<div className="grow border rounded-md px-5 py-3 text-sm flex items-center justify-between">

							<div className="min-w-[140px] relative flex grow border-r pr-4 items-center text-left">
								<span title={itm.client_name} className="absolute w-full block text-ellipsis whitespace-nowrap overflow-hidden">{itm.client_name}</span>
							</div>
							<div className="min-w-[140px] relative flex grow border-r pr-4 items-center text-center">
								<span title={itm.vendor_name} className="absolute w-full block text-ellipsis whitespace-nowrap overflow-hidden text-center">{itm.vendor_name}</span>
							</div>
							<div className="min-w-[155px] relative flex grow border-r pr-4 items-center text-center">
								<span title={itm.expense_area} className="absolute w-full block text-ellipsis whitespace-nowrap overflow-hidden text-center">{itm.expense_area}</span>
							</div>
							<div className="min-w-[140px] relative flex grow border-r pr-4 items-center text-center">
								<span title={itm.invoice_name} className="absolute w-full block text-ellipsis whitespace-nowrap overflow-hidden text-center">{itm.invoice_name}</span>
							</div>
							<div className="min-w-[140px] relative flex grow border-r pr-4 items-center text-center">
								<span title={titleDate} className="absolute w-full block text-ellipsis whitespace-nowrap overflow-hidden text-center"><TimeZoneConverter settitleDate={settitleDate} setuatHeader={setuatHeader} utcTimestamp={itm.invoice_date.$date} /> </span>
							</div>
							<div className="min-w-[180px] relative flex grow border-r pr-4 items-center">
								<span title={itm.contract_name} className="absolute w-full block text-ellipsis whitespace-nowrap overflow-hidden text-center">{itm.contract_name}</span>
							</div>
							<div className="min-w-[180px] relative flex grow border-r pr-4 items-center">
								<span title={itm.contract_unit_price} className="absolute w-full block text-ellipsis whitespace-nowrap overflow-hidden text-center">{itm.contract_unit_price}</span>
							</div>
							<div className="min-w-[180px] relative flex grow border-r pr-4 items-center">
								<span title={itm.contract_total_amount} className="absolute w-full block text-ellipsis whitespace-nowrap overflow- text-center">{itm.contract_total_amount}</span>
							</div>
							<div className="min-w-[140px] relative flex grow border-r pr-4 items-center">
								<span title={itm.item_number} className="absolute w-full block text-ellipsis whitespace-nowrap overflow-hidden text-center">{itm.item_number}</span>
							</div>
							<div className="min-w-[140px] relative flex grow border-r pr-4 items-center">
								<span title={itm.unit_cost} className="absolute w-full block text-ellipsis whitespace-nowrap overflow-hidden text-center">{itm.unit_cost}</span>
							</div>
							<div className="min-w-[140px] relative flex grow border-r pr-4 items-center">
								<span title={itm.total_amount} className="absolute w-full block text-ellipsis whitespace-nowrap overflow-hidden text-center">{itm.total_amount}</span>
							</div>
							<div className="min-w-[140px] relative flex grow border-r pr-4 items-center">
								<span title={itm.volume} className="absolute w-full block text-ellipsis whitespace-nowrap overflow-hidden text-center">{itm.volume}</span>
							</div>
							<div className="min-w-[170px] relative flex grow border-r pr-4 items-center text-left">
								<span title={itm.expense_area} className="absolute w-full block text-ellipsis whitespace-nowrap overflow-hidden">{(itm.reprocess_status)?itm.reprocess_status:'-'}</span>
							</div>
							<div className="min-w-[140px] relative flex grow border-r pr-4 items-center">
								<span title={itm.reprocess_status} className="absolute w-[90%] block text-ellipsis whitespace-nowrap overflow-hidden text-center">{itm.mismatch_status}</span>
							</div>
							<div className="min-w-[140px] relative flex grow border-r pr-4 items-center">
								<span title={itm.description} className="absolute w-[90%] block text-ellipsis whitespace-nowrap overflow-hidden text-center">{itm.description}</span>
							</div>
							<div className="min-w-[140px] relative flex grow border-r pr-4 items-center">
								<span title={itm.mismatch_explanation} className="absolute w-[90%] block text-ellipsis whitespace-nowrap overflow-hidden text-center">{itm.mismatch_explanation}</span>
							</div>	
							
						</div>
						

						<div className="flex-none flex gap-2 pr-2">
							<button
								
								onClick={() => gotoInvoice(itm.client_name,itm.client_id.$oid,itm.vendor_name,itm.vendor_id.$oid, itm.invoice_name ,itm.invoice_id.$oid,itm.category_id.$oid, itm.expense_area )}
								type="button"
								className="vLBG block px-4 py-4 text-center text-primary text-sm rounded-md font-black disabled:bg-gray-200 disabled:text-gray-500"
							>
								Invoice
							</button>
								<button
								
								onClick={() => gotoContract(itm.contract_id.$oid,itm.contract_name,itm.client_name,itm.client_id.$oid,  itm.vendor_name,itm.vendor_id.$oid,itm.category_id.$oid,  itm.expense_area )}
								type="button"
								className="vLBG block px-4 py-4 text-center text-primary text-sm rounded-md font-black disabled:bg-gray-200 disabled:text-gray-500"
							>
								Contract
							</button>
							<button
								
								onClick={() => gotoMismatch(itm._id.$oid, itm.client_name,itm.client_id.$oid,  itm.vendor_name,itm.vendor_id.$oid, itm.invoice_name ,itm.invoice_id.$oid,itm.category_id.$oid, itm.expense_area )}
								type="button"
								className="vLBG block px-4 py-4 text-center text-primary text-sm rounded-md font-black disabled:bg-gray-200 disabled:text-gray-500"
							>
								Mismatch
							</button>
						</div>

					</div>
				);
			})}
		</>
	);
};


function formatDate(inputDateString) {
	//console.log(inputDateString);
	
	if (!inputDateString) return '-'
	// Create a Date object from the input date string
	var date = new Date(inputDateString);

	// Extract day, month, and year
	var day = date.getDate();
	var month = date.getMonth() + 1; // Note: getMonth() returns 0-indexed month, so we add 1
	var year = date.getFullYear();

	// Format day and month to have leading zeros if necessary
	day = day < 10 ? '0' + day : day;
	month = month < 10 ? '0' + month : month;

	// Construct the formatted date string
	var formattedDateString = month + '/' + day + '/' + year;

	// Return the formatted date string
	return formattedDateString;
}